import { getColorVariant, getErrorMessage, renderTernary } from "./utils";
import MuiTagItem from "../components/Table/MuiDataTable/MuiTagItem/MuiTagItem";
import { OLD_PASSWORD_ENTERED_MSG, OTP_EXPIRED_MSG, SOMETHING_WENT_WRONG } from "../constants/messages";
import { getInvalidInputMessage } from "../constants/templates";

/*
* convert json data to csv
*/
export const jsonToCsv = (data) => {
  const items = data;
  // specify how you want to handle null values here
  const replacer = (key, value) => value === null ? '' : value;
  const header = Object.keys(items[0]);
  let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');
  return csv;
}

/**
 *Fucntion to format amount field with comma and round off to 2 decimal
  * @param {*} num
  * @param {*} locale
  * @param {*} minimumFractionDigits
  * @returns
  */
export const amountFormat = (num, locale = 'en-US', minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  if (isNaN(parseInt(num))) {
    return num;
  }
  return num.toLocaleString(locale, { minimumFractionDigits, maximumFractionDigits  });
};

export const getCurrencyAmount = (amount, currency = '$') => {
  if(typeof amount === 'number') {
    if(amount < 0 || Math.sign(amount) === -1) {
      return `${amount}`?.replace('-', `-${currency}`);
    } else {
      return `${currency}${amount}`
    }
  }
  else if(typeof amount === 'string') {
    if(amount?.includes('-')) {
      return amount?.replace('-', `-${currency}`);
    }
  }
  return `${currency}${amount}`
}

//conversion of Color
export const converColorValue = (value) => {
  return value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
}

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) {
    return value;
  }

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) {
    return phoneNumber;
  }

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

/**
 * Function to retrieve Phone number string for backend without -  and ()
 * @param {*} formattedPhoneNumber
 * @returns
 */
export const phoneNumberForBackend = (formattedPhoneNumber='') => {
  return formattedPhoneNumber.replace(/[- )(]/g,'');
}

/*
* Function to eliminate "" and null values while sending payload
*/

export const filterPayload = (obj) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === '' || obj[propName] === undefined) {
      if (Array.isArray(obj[propName])) {
        return obj[propName].filter(Boolean)
      } else {
        delete obj[propName];
      }
    } else if (typeof (obj[propName]) === "object") {
      filterPayload(obj[propName])
    }
  }
  return obj
}

/* Function to display cell with ID */
export const TableCellDisplay = (cellId, value) => {
  return <p id={cellId}>{renderTernary(value, value, '-')}</p>
}
/* Function to display Status Code Cell */
export const renderStatus =(value) => {
  const { color, variant } = getColorVariant(value);
  return <MuiTagItem
	value={value}
	color={color}
	variant={variant} />;
}

export const getIAMErrorMessage = ( errorResponse , dataBag) => {
  let code = "";
  try {
    const parsedErrorResponse = JSON.parse(errorResponse?.response?.data);
    if(parsedErrorResponse){
      if(parsedErrorResponse?.errors && parsedErrorResponse?.errors?.[0]) {
        code = parsedErrorResponse?.errors?.[0]?.code;
      }
    }
  }
  catch(error) {
      console.log("error generating IAM error message",error);
  }
  finally {
    switch(code) {
      case "1052":
        return getInvalidInputMessage({dataBag});
      case "1015": 
        return OLD_PASSWORD_ENTERED_MSG;
      case "1033":
        return OTP_EXPIRED_MSG;
      default:
        return getErrorMessage(errorResponse);
    }
  }
}

export const removeShortId = (value) => {
  return value?.split('(', 1)[0] || '-'
}