import React from "react";
import { useSelector } from "react-redux";
import { EwmmsApp, EwmmsTypes } from "@walmart/wmms-helpcenter-library";
if (process.env.NODE_ENV !== 'test') {
  require('@walmart/wmms-helpcenter-library/dist/index.css');
}
/*
*   QnA component
*/
const QnAPortal = () => {

  const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
  const user = useSelector((state) => state?.user);

  const headers = {
    'X-User-Id': userDetails?.userId || '',
    'X-User-Unique-Id': userDetails?.userUniqueId || '',
    'X-User-First-Name': userDetails?.firstName || '',
    'X-User-Last-Name': userDetails?.lastName || '',
    'X-User-Email-Id': userDetails?.emailId || '',
    'Authorization': user?.staticTk,
    'X-Tenant-ID': user?.tenantId,
    'X-Sub-Tenant-ID': "LNG",
    'WM_CONSUMER_INTIMESTAMP': user?.authSign?.["WM_CONSUMER.INTIMESTAMP"],
    'WM_SEC_AUTH_SIGNATURE': user?.authSign?.["WM_SEC.AUTH_SIGNATURE"],
    'WM_SEC_AUTH_TOKEN': user?.authTk || ''
  };
  

  const userDetailsAre = {
    userId: userDetails?.userId || "",
    userName: userDetails?.firstName || "",
    state: "TX",
    businessUnit: EwmmsTypes?.WMMSBusinessUnit?.ANY,
  };

  return (
    <div>
          <EwmmsApp userDetails={userDetailsAre} headers={headers}/>
    </div>
  );
};

export default QnAPortal;
