import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { TextField, Autocomplete } from "@mui/material";
import { debounce } from '@mui/material/utils';
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { setPeopleDropDownData, setExternalPeopleDropDownData } from "../../../store/actions/masterDataAction";
import { useSnackbar } from "@walmart-web/livingdesign-components";
import { postService } from "../../../axios/axios";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { getErrorMessage, getHighlightedText } from "../../../helpers/utils";
import { CS_IS_ACTIVE_FILTER, CS_SCOPES, CS_TYPEAHEAD_PAGINATION_QUERY, PEOPLE_AUTOCOMPLETE_DEFAULT_SORT_QUERY } from "../../../constants/constants";
import { formCompositeSearchQuery, formCsFilterObject, formCsPeopleAutocompleteQuery  } from "../../../helpers/csQueryUtils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * Component to render People search Autocomplete dropdown
 *
 */
const PeopleMultiAutocomplete = (props) => {
	const { value, setValue, colKey, isExternalUser = false, isInternalExternal = false,
		required, disabled = false, placeholderValue, testid = "people-autocomplete",
		id = "people-multi-autocomplete", isUserAutoComplete = false
	} = props;
	const { addSnack } = useSnackbar();
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false);
	const { peopleDropDownData, externalPeopleDropDownData } = useSelector((state) => state?.masterData);
	const [loading, setLoading] = useState()
	const [inputValue, setInputValue] = useState('');

	const peopleDropDown = [...new Set(peopleDropDownData?.nodeList?.map(val => `${val?.firstName} ${val?.lastName}`))];
	const userDropDown = [...new Set(peopleDropDownData?.nodeList?.map(val => `${val?.firstName} ${val?.lastName} ( ${val?.userId} )`))];
	const externalPeopleDropDown = [...new Set(externalPeopleDropDownData?.nodeList?.map(val => `${val?.firstName} ${val?.lastName}`))];

	/**
	*Function to call search people Service
	*@param {string} query
	*/
	const getPeopleData = (searchQuery = {}) => {
		setLoading(true)
		const userTypeQuery = [];
		if (!isInternalExternal) {
			userTypeQuery.push(formCsFilterObject('isExternalUser', 'match', isExternalUser))
		}
		const defaultQuery = {
			"filters": [CS_IS_ACTIVE_FILTER, ...userTypeQuery],
			"operation": "AND",
			"properties": null
		}
		const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, PEOPLE_AUTOCOMPLETE_DEFAULT_SORT_QUERY, CS_TYPEAHEAD_PAGINATION_QUERY, {}, defaultQuery, searchQuery);
		postService(
			COMPOSITE_SEARCH_SERVICE,
			`/composite-search/v1?returnCsv=false`,
			query
		).then((response) => {
			setLoading(false);
			const peopleData = response?.data?.people;
			if (isExternalUser) {
				dispatch(setExternalPeopleDropDownData({
					nodeList: peopleData?.data,
					count: peopleData?.count
				}))
			} else {
				dispatch(setPeopleDropDownData({
					nodeList: peopleData?.data,
					count: peopleData?.count
				}))
			}
		}).catch((error) => {
			setLoading(false)
			addSnack({
				message: getErrorMessage(error)
			})
		})
	}

	const fetch = useMemo(() =>
		debounce((searchText, callback) => {
			const searchQueryObj = formCsPeopleAutocompleteQuery(searchText);
			if(searchText?.length > 2) {
				getPeopleData(searchQueryObj)
			}
		}, 600),
		[],
	);

	useEffect(() => {
		if(inputValue?.length < 1) {
			dispatch(setPeopleDropDownData([]))
    	dispatch(setExternalPeopleDropDownData([]))
			setLoading(false)
		}
	},[inputValue])

	const renderDropdownOptions = () => {
		if (isUserAutoComplete) {
			return userDropDown;
		} else if (isExternalUser) {
			return externalPeopleDropDown;
		} else {
			return peopleDropDown;
		}
	}

	return (
		<Autocomplete
			id={id}
			multiple={true}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			disableCloseOnSelect={false}
			disablePortal={true}
			autoHighlight
			autoComplete
			loading={loading}
			options={renderDropdownOptions()}
			getOptionLabel={(option) => `${option}`}
			renderOption={(props, option, { selected }) => (
				<Box component="li"  {...props} key={option?.referenceKey}>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						checked={selected}
					/>
					{getHighlightedText(`${option}`, inputValue)}
				</Box>
			)}
			defaultValue={value}
			disabled={disabled}
			fullWidth={true}
			data-testid="people-multi-autocomplete"
			onInputChange={(event, newInputValue) => {
				if(!((!event) || (event?.type === "click" && newInputValue !== ""))) {
					setInputValue(newInputValue);
					setLoading(true)
					fetch(newInputValue)
				}
			}}
			onChange={(e, v) => setValue(e, v, colKey)}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params?.inputProps, required, style: {
							color: '#000000',
							fontFamily: 'Bogle',
							fontSize: 16,
						}
					}}
					margin="dense"
					placeholder={placeholderValue}
					variant="outlined"
					data-testid={testid}
				/>
			)}
		/>
	);
};

export default PeopleMultiAutocomplete;

PeopleMultiAutocomplete.propTypes = {
	/** to display default value on load */
	value: PropTypes.array,
	/** callback function on user input */
	setValue: PropTypes.func,
	/** coloumn name */
	colKey: PropTypes.string,
	/** for checking component is used for filter or not */
	filterCheck: PropTypes.bool,
	/** for differnciate the internal and external user */
	isExternalUser: PropTypes.bool,
	/** for getting the both internal and external user */
	isInternalExternal: PropTypes.bool,
	/** flag to determine if the field is mandatory */
	required: PropTypes.bool,
	/** flag to determine if input is disabled */
	disabled: PropTypes.bool,
	/** placeholder value for the input */
	placeholderValue: PropTypes.string,
	/** testid to identify the autocomplete component */
	testid: PropTypes.string,
	/** id to identify the autocomplete component */
	id: PropTypes.string
};
