import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { TabNavigation,TabNavigationItem} from '@walmart-web/livingdesign-components';
import { HELP_CENTER_TABS } from '../../constants/constants';
import HelpCenterBanner from '../../components/HelpCenterBanner/HelpCenterBanner';
import TrainingGuides from './TrainingGuides';
import QnAPortal from './QnAPortal';
import HelpCenterStyles from './HelpCenter.styles';

/*
*   Help Center component
*/
const HelpCenter= () => {
  const classes = HelpCenterStyles();
  const [isCurrent, setIsCurrent] = useState(0);
  const helpcenterQnA = useSelector((state) => state?.user?.featureMap?.helpcenterQnA);

  /*
  *   tabContent - load content of the tab on click
  *   @{param} - index
  */
  const tabContent = (index) => {
    if(index === 0){
      return <TrainingGuides/>;
    }
    else if(index ===1){
      return <QnAPortal/>
    }
    else{
      return <></>
    }
  }

  return (
    <div className={classes.pageContainer} data-testid="help-center-container" id='content-container'>
      <div className={classes.bannerContainer}>
        <HelpCenterBanner heading='Help Center'/>
      </div>
            <div className={classes.tabContainer} data-testid="help-center-tab-container">
              <TabNavigation>
                {HELP_CENTER_TABS?.map((item,index) => {
                  if(helpcenterQnA || item !== 'QnA') {
                    return (      
                      <TabNavigationItem
                      data-testid={`help-center-tab-${index}`}
                      id={`help-center-tab-${index}`}
                      onClick={() => {
                        setIsCurrent(index);
                      }}
                      isCurrent={isCurrent === index}
                      selected={isCurrent === index}
                      key={"help-center-tab-"+item}
                      >
                        {item}
                      </TabNavigationItem>
                    );
                  }
                return null;
              })}
              </TabNavigation>
            </div>
            <div data-testid="help-center-content" id='tab-content'>
            {tabContent(isCurrent)}
            </div>
    </div>
  );
};

export default HelpCenter;
