import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import { createUseStyles } from "react-jss";
import {useDispatch, useSelector} from "react-redux";
import {
  Caption,
  GridColumn,
  useSnackbar,
} from "@walmart-web/livingdesign-components";

import MuiDataTable from "../../components/Table/MuiDataTable/MuiDataTable";
import {
  DOWNLOAD_NOTE_FILENAME,
  ENABLE_SERVER_SIDE_FEATURES_NOTE,
  ROWS_PER_PAGE_NOTE,
  ROWS_PER_PAGE_OPTIONS_NOTE,
} from "../../constants/tableConfigs";
import {formSearchQuery, getErrorMessage, getExportedData} from "../../helpers/utils";
import {exportClientData} from "../../helpers/tableUtils";
import {Operations} from "../../axios/operations";
import {getService, getServiceWithResponseType} from "../../axios/axios";
import {setMatterNoteData,} from "../../store/actions/noteActions";
import { NOTE_SEARCH_SERVICE } from "../../constants/baseURLs";
import { EXPORT_MAX_LIMIT_CONF, NOTES } from "../../constants/constants";
import { displayLocalDate } from "../../helpers/utcUtils";
import ExportModal from "../../components/Modal/ExportModal/ExportModal";
import { removeShortId } from "../../helpers/commonUtils";

const COLUMNS = [
  {
    name: "title",
    label: "Title",
    options: {
      display: false,
    },
  },
  {
    name: "description",
    label: "Contents",
    colKey: "contents",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({ style: { width: "480px" } }),
      customBodyRender: (value, tableMeta, updateValue) => {
        const cellId = `Mui-note-title-row-${tableMeta?.rowIndex}`;
        return (
          <GridColumn lg={12} key={value + tableMeta} id={cellId}>
            <div>
              <Caption as="p"> {tableMeta?.rowData && tableMeta?.rowData[0]}</Caption>
            </div>
            {/* TODO - when backend change is done we have to show summary here
            <div style={{color: "#74767c"}}>
              <div>{tableMeta?.rowData && tableMeta?.rowData[1]}</div>
            </div> */}
          </GridColumn>
        );
      },
    },
    operation: Operations.LIKEIC,
    type: "notesContent"
  },
  {
    name: "createdBy",
    label: "Creator",
    colKey: "creator",
    options: {
      filterList: [],
      display: true,
      customFilterListOptions: { render: (v) => `Creator: ${v}` },
      customHeadLabelRender: () => {
        return <p data-testid={`headcol-0`}>Creator</p>
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return (<Caption as="p">
							{removeShortId(value)}
						</Caption>)
      },
    },
    operation: Operations.LIKE,
    type: "tag"
  },
  {
    name: "createdTs",
    label: "Created",
    colKey: "created",
    options: {
      display: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Caption as="p">
            {displayLocalDate(value)}
          </Caption>
        );
      },
    },
    operation: Operations.GTE,
    type: "date"
  },
  {
    name: "tags",
    label: "Tagged With",
    colKey: 'tags',
    options: {
      display: false,
      customFilterListOptions: { render: (v) => `Tagged With: ${v}` }
    },
  }
];
const borderTop= "1px solid #f1f1f2";
const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    border: "solid 1px #eee",
    '& table > thead > tr > th.MuiTableCell-head': {
      color: '#000000',
      fontWeight: 'bold',
      fontFamily: 'Bogle'
    },
    '& table > thead > tr > th.MuiTableCell-head button': {
      color: '#000000',
      fontWeight: 'bold',
      fontFamily: 'Bogle'
    },
  },
  contentHeader: {
    fontFamily: "Bogle",
    fontSize: "12px",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#515357",
    borderTop: borderTop,
    borderBottom: borderTop,
    padding: "16px 24px",
  },
  contentBody: {
    borderBottom: borderTop,
    padding: "16px 24px",
  },
  contentVal: {
    borderBottom: borderTop,
    padding: "40px 24px",
    fontFamily: "Bogle",
    fontSize: "12px",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#2e2f32",
  },
  subject: {
    fontFamily: "Bogle",
    fontSize: "12px",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#2e2f32",
  },
  subSubject: {
    fontFamily: "Bogle",
    fontSize: "12px",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#74767c",
  },
  noteTabNavigation: {
    backgroundColor: "#fff",
  },
  exportName: {
    marginBottom: 20
  },
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
    ,
	divider: {
    display: 'none'
  }
  }
});

/**
 * Function to form search query from user entered search string
 *
 * @param {string} searchText
 * @returns search query
 */
 export const getSearchQuery = (
  searchText,
  COLUMNS
) => {
  let query = "";
	COLUMNS.forEach((column, index) => {
    if(column?.options?.display) {
			const colQuery = formSearchQuery(column.name, column.operation, searchText, column.type);
			if (colQuery !== "") {
				query += index > 1 ? " OR " + colQuery : colQuery;
			}
    }
	});
  return query;
};

const NoteList = (props) => {
  const currentMatterTab = props?.currentMatterTab;
  const classes = useStyles();
  const [, setIsCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPageNotes, setRowsPerPageNotes] = useState(ROWS_PER_PAGE_NOTE);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortQuery, setSortQuery] = useState(`&order=createdTs desc`);
  const [exportLoading, setExportLoading] = useState(false);
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const [notesTableColumns, setNotesTableColumns] = useState(COLUMNS);
  const dispatch = useDispatch();
  const history = useHistory();
  const {addSnack} = useSnackbar();
  const {matterNoteData} = useSelector((state) => state?.note);
  const matterDetail = useSelector((state) => state?.matter?.matterDetail);
  const defaultFilterConditionNote = `${formSearchQuery(
    "matterNumber",
    Operations.EQ,
    matterDetail?.matter?.matterNumber
  )}+${Operations.AND}+${formSearchQuery(
    "isPrivate",
    Operations.EQ,
    false
  )}`;
  const [modalOpen, setModalOpen] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const showExportAllOption = matterNoteData?.count < EXPORT_MAX_LIMIT_CONF.NOTE;
  const defaultExportOption = showExportAllOption ? 'allNotes' : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);

  /**********
   @types : [
   'checkbox',
   'dropdown',
   'dropdownAutocomplete',
   'autocompleteMultiSelect',
   ]
   **********/
  const FILTER_CONFIGS = [
    {
      colKey: "tags",
      type: "masterDataAutocomplete",
      label: "Find a tag",
      defaultOpen: true,
      accordian: 'Tagged With',
      dataType: "noteTags",
    },
    {
      colKey: "creator",
      type: "peopleDropdownAutocomplete",
      label: "Find a user",
      defaultOpen: true,
      accordian: 'Creator',
    },
  ];

  /** Keeping for API integration
   * Function to call note list Service
   * @param {string} query
   *
   */
  const getNoteData = (query = "") => {
    setLoading(true);
    const uri=`(${defaultFilterConditionNote})${searchQuery!== '' ? 'and (' : ''}${searchQuery}${searchQuery !== '' ?')': ''}${filterQuery}${sortQuery}${query}`
    const encodedUri=encodeURI(uri)
    getService(
      NOTE_SEARCH_SERVICE,
      `/search/notes/v1?filter=${encodedUri}`
          )
      .then((response) => {
        dispatch(setMatterNoteData(response?.data));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  useEffect(() => {
    let offsetQuery = "";
    if (ENABLE_SERVER_SIDE_FEATURES_NOTE) {
      offsetQuery = `&limit=${rowsPerPageNotes}&offset=0`;
    }
    getNoteData(offsetQuery);
  }, [searchQuery, filterQuery, sortQuery]);

  useEffect(() => {
    const filterOptions = [];
    for (const element of notesTableColumns) {
      if (element?.options?.filterList?.length > 0) {
        const colKey = element?.colKey;
        const filter = element?.options?.filterList;
        filterOptions[colKey] = filter;
      }
    }
    if (Object.keys(filterOptions)) {
      // dispatch(filterEventDataByFilterPanel(filterOptions));TODO: to check if this is needed
    }
  }, [notesTableColumns]);

  /**
   * Gets called when user click on add new event button
   *
   * @param {boolean} term
   * @Public
   */
  const handleAddNew = (term) => {
    history.push("/note/0", { currentMatterTab });
  };

  /**
   * Function to set search query on search action
   * @param {string} searchText
   */
  const handleServerSideSearch = (searchText) => {
    const searchQuery = searchText
      ? getSearchQuery(searchText, COLUMNS)
      : "";
    setIsCurrent(0);
    if (searchQuery !== "SHOW_NO_RESULT") {
      setSearchQuery(searchQuery);
    } else {
      dispatch(setMatterNoteData([]));
    }
  };

  /**
   * Function to set filter query on filter action
   * @param {string} query
   */
  const handleServerSideFilter = (query) => {
    if (query.indexOf("creator") !== -1) {
      query = query.replace("creator", "createdBy");
    }
    setIsCurrent(0);
    setFilterQuery(query);
  };

  /**
   * Function to call api on pagination action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSidePagination = ({rowsPerPage, page}) => {
    setRowsPerPageNotes(rowsPerPage);
    getNoteData(`&limit=${rowsPerPage}&offset=${rowsPerPage * page}`);
  };

  /**
   * Function to call api on sort action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSideSort = ({name, direction}) => {
    setSortQuery(`&order=${name} ${direction}`);
  };

  const handleRowClick = (rowData, rowMeta) =>
    history.push(`/matter/${matterDetail?.matter?.identifier}/note/${matterNoteData?.notes[rowMeta.dataIndex]?.id}`, { currentMatterTab });

  const onCancelBtnClk = () => {
    setExportLoading(false)
    setModalOpen(false)
  }


  /**
   * After click on create button download Actions
   */
  const onClickCreate = () => {
    setCreateExportLoading(true);
    if(ENABLE_SERVER_SIDE_FEATURES_NOTE  === false){
      exportClientData(matterNoteData?.notes, exportSelection, rowsData, 'notes-data');
      setModalOpen(false);
      setExportLoading(false);
      setCreateExportLoading(false);
    }else{
      let exportQuery = `filter=${defaultFilterConditionNote} ${searchQuery} ${filterQuery} ${sortQuery}`;
      if(exportSelection === 'currentSelection'){
        const result = rowsData?.map(a => a?.id);
        exportQuery = `filter=${encodeURIComponent(formSearchQuery('id', Operations.IN, result, 'array'))}`;
      }else if(exportSelection === 'currentPage'){
        const result = matterNoteData?.notes?.map(a => a.id);
        exportQuery = `filter=${encodeURIComponent(formSearchQuery('id', Operations.IN, result, 'array'))}`;
      }
      const csv = "csv=true";
      getServiceWithResponseType(
        NOTE_SEARCH_SERVICE,
        `/search/notes/v1?${csv}&${exportQuery}`,
        "arraybuffer"
      )
      .then((response) => {
        const data = response?.data;
        const contentType = response?.headers["content-type"];
        getExportedData(data, contentType, 'notes-data');
        setModalOpen(false);
        setExportLoading(false);
        setCreateExportLoading(false);
      })
      .catch((error) => {
        setModalOpen(false);
        setExportLoading(false);
        setCreateExportLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
    }
  }

/**
   * function will use when row is select/deselect
   */
 const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
  const bData = matterNoteData?.notes;
  const result = allSelected?.map(item => bData && bData[item?.index]);
  setSelectRowsData(selectRowsData);
  setRowsData(result);
}

  /**
   * function is to open the export modal to show download options
   */
  const handleExportBtnClk = () => {
    setExportSelection(defaultExportOption)
    setModalOpen(true)
    setExportLoading(true)
    setCurrentPageExport(true);
  };
  const notesTableCustomOptions = {
    downloadOptions: {
      filename: DOWNLOAD_NOTE_FILENAME,
    },
    rowsPerPage: rowsPerPageNotes,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_NOTE,
    filterConfigs: FILTER_CONFIGS,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_NOTE,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSort: handleServerSideSort,
    totalCount: matterNoteData?.count,
  };

  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  const renderTableContent = () => {
    return (
      <MuiDataTable
        data={matterNoteData?.notes}
        columns={notesTableColumns}
        customOptions={notesTableCustomOptions}
        setTableColumns={setNotesTableColumns}
        onAddNew={handleAddNew}
        onRowClick={handleRowClick}
        loading={loading}
        exportLoading={exportLoading}
        onExportClick={handleExportBtnClk}
        selectableRowsType={"multiple"}
        selectedRows={selectRowsData}
        showRowsCounter={false}
        onRowSelection={handleRowSelectionChange}
        resetSelectedRows={() => resetSelectedRows()}
      />
    );
  };

  return (
    <>
      <div className={classes.pageContent}>{renderTableContent()}</div>
      <ExportModal
        title={NOTES}
        mode={modalOpen}
        onCancel={onCancelBtnClk}
        onCreate={onClickCreate}
        rowsData={rowsData}
        showExportAllOption={showExportAllOption}
        exportSelection={exportSelection}
        setExportSelection={setExportSelection}
        currentPageExport={currentPageExport}
        setCurrentPageExport={setCurrentPageExport}
        exportLoading={createExportLoading}
      />
    </>
  );
};

export default NoteList;
