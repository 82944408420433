import {
  TabNavigation,
  TabNavigationItem,
  useSnackbar
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getService } from "../../../axios/axios.js";
import { ORGANIZATION_SERVICE } from "../../../constants/baseURLs.js";
import { displayValue, getErrorMessage, getTabList } from "../../../helpers/utils.js";
import { setOrgDetailValues } from "../../../store/actions/organizationAction.js";
import ActivePeople from "./Active/ActivePeople.jsx";
import InActivePeople from "./InActive/InActivePeople.jsx";
import OrganizationPeopleStyles from "./OrganizationPeople.styles.jsx.jsx";
import PendingUserRequests from "./PendingUserRequests/PendingUserRequests.jsx";
import RejectedUserRequests from "./RejectedUserRequests/RejectedUserRequests.jsx";
import { formatPhoneNumber, TableCellDisplay } from "../../../helpers/commonUtils.js";
import { isValueEmptyCheck } from "../../../helpers/validationUtils.js";

/**********
 @types : [
 'dropdownAutocomplete',
 ]
 **********/
export const FILTER_CONFIGS_PEOPLE = [
  {
    colKey: "requestedRole",
    type: "dropdownAutocomplete",
    label: "Role",
    options: [
      "Admin",
      "Timekeeper",
    ],
    defaultOpen: true,
  },
];

export const COLUMNS_PEOPLE = [
  {
    name: "lastName",
    label: "Last Name",
    colKey: "lastName",
    options: {
      sort: true,
      display: true,
      customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-lastName-${tableMeta.rowIndex}`, value)
    },
  },
  {
    name: "firstName",
    label: "First Name",
    colKey: "firstName",
    options: {
      sort: true,
      display: true,
      customBodyRender: (value, tableMeta) => TableCellDisplay(`firstName-${value}`, value)
    },
  },
  {
    name: "phoneNumber",
    label: "Phone #",
    colKey: "phoneNumber",
    options: {
      sort: true,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          color: "#2E2F32",
          fontWeight: "bold",
          fontSize: "12px",
          fontFamily: "Bogle"
        },
      }),
      customBodyRender: (value, tableMeta) => displayValue(formatPhoneNumber(value), tableMeta)
    },
  },
  {
    name: "emailId",
    label: "Email",
    colKey: "emailId",
    options: {
      sort: true,
      display: true,
      setCellHeaderProps: () => ({
        style: {
          color: "#2E2F32",
          fontWeight: "bold",
          fontSize: "12px",
          fontFamily: "Bogle"
        },
      }),
      customBodyRender: (value, tableMeta, updateValue) => TableCellDisplay(`table-row-emailId-${tableMeta.rowIndex}`, value)
    },
  },
  {
    name: "requestedRole",
    label: "Role",
    colKey: "requestedRole",
    options: {
      sort: true,
      customFilterListOptions: { render: (v) => `Role: ${v}` },
      customBodyRender: (value, tableMeta) => {
        const role = isValueEmptyCheck(value)
        return displayValue(role, tableMeta)
      }
    },
  },
];

const OrganizationPeople = (props) => {
  const classes = OrganizationPeopleStyles();
  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const { selectedPeopleTab } = props;
	const [loading, setLoading] = useState(false)
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.people);
  const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);
	const { listOfTabs, tabIndexMap } = getTabList(accessibility?.tabs);
  const [isCurrent, setIsCurrent] = useState(selectedPeopleTab ? selectedPeopleTab : tabIndexMap[listOfTabs[0]]);

  const getOrganizationData = () => {
    setLoading(true)
    getService(ORGANIZATION_SERVICE, `/organizations/v1/${identifier}`)
      .then((res) => {
        setLoading(false)
        const detail = res?.data?.result?.organization;
        dispatch(setOrgDetailValues(detail));
      })
      .catch((error) => {
        setLoading(false)
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }

  /*
  * useEffect Hook to call the get organization service on initial load
  */
  useEffect(() => {
    getOrganizationData()
  }, [])

  const TabContent = (isCurrent, loading, identifier) => {
    switch (isCurrent) {
      case 0:
        return <ActivePeople selectedPeopleTab={isCurrent} organizationId={identifier} />;
      case 1:
        return <InActivePeople selectedPeopleTab={isCurrent} organizationId={identifier} />;
      case 2:
        return <RejectedUserRequests selectedPeopleTab={isCurrent} organizationId={identifier} />;
      case 3:
        return <PendingUserRequests selectedPeopleTab={isCurrent} organizationId={identifier} />;
      default:
        return <ActivePeople selectedPeopleTab={isCurrent} organizationId={identifier} />;
    }
  };



  return (
    <div className={classes.tabContainer}>
      <TabNavigation>
        {listOfTabs.map((item) => (
          <TabNavigationItem
              onClick={() => setIsCurrent(tabIndexMap[item])}
              isCurrent={isCurrent === tabIndexMap[item]}
              key={tabIndexMap[item]}
              data-testid={item?.split(" ").join("-")}
              id={item?.split(" ").join("-")}
              UNSAFE_className={loading ? classes.disableTabs: ""}
          >
            {item}
          </TabNavigationItem>
        ))}
      </TabNavigation>
      {TabContent(isCurrent, loading, identifier)}
    </div>
  );

}

export default OrganizationPeople;
