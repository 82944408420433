import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { TextField, Autocomplete, createFilterOptions, debounce } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { setPeopleDropDownDataWithOptions } from "../../../store/actions/masterDataAction";
import MuiValidationMessage from "../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { getErrorMessage, getHighlightedText } from "../../../helpers/utils";
import { formCompositeSearchQuery, formCsFilterObject, formCsPeopleAutocompleteQuery } from "../../../helpers/csQueryUtils";
import { CS_IS_ACTIVE_FILTER, CS_IS_ATTORNEY_USER_FILTER, CS_IS_NOT_EXTERNAL_USER_FILTER, CS_IS_OC_INTERNAL_FILTER, CS_IS_OC_INTERNAL_USER_ACTIVE, CS_SCOPES, CS_TYPEAHEAD_PAGINATION_QUERY } from "../../../constants/constants";
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { postService } from "../../../axios/axios";
import { useSnackbar } from "@walmart-web/livingdesign-components";


const useStyles = createUseStyles({
	boxLi: {
		overflow: "hidden",
		cursor: "pointer",
		paddingTop: "6px",
		boxSizing: "border-box",
		outline: 0,
		paddingBottom: "6px",
		paddingLeft: "16px",
		paddingRight: "16px",
		'&:hover': {
			background: "rgba(0, 0, 0, 0.04)"
		}
	},
});

/**
 * Component to render People search Autocomplete dropdown
 */
const PeopleAutocompleteWithOptions = (props) => {
	const classes = useStyles();
	const { value, setValue, filterCheck,
		required, disabled = false, placeholderValue, id = "people-autocomplete",
		register, errors, name = "PeopleAutocompleteWithOptions", validationRules,
		testid = "people-autocomplete",additionalQuery = [],
	} = props;

	const { addSnack } = useSnackbar();
	const peopleDropDownDataWithOptions = useSelector((state) => state?.masterData?.peopleDropDownDataWithOptions);
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [loading,setLoading]=useState()

	const filterOptions = createFilterOptions({
		stringify: (option) => `${option?.firstName} ${option?.lastName}`,
	});

	// Fetch typeahead
	const fetch = useMemo(() =>
		debounce((searchText, callback) => {
				const searchQueryObj = formCsPeopleAutocompleteQuery(searchText);
				if(searchText?.length > 2) {
					getPeopleData(searchQueryObj)
				}
		}, 600),
		[],
	);

	/**
	*Function to call search people Service
	*@param {string} query
	*/
	const getPeopleData = (searchQuery = {}) => {
		setLoading(true);
		const defaultQuery = {
			"filters": [
				CS_IS_ACTIVE_FILTER, 
				CS_IS_NOT_EXTERNAL_USER_FILTER,
				CS_IS_OC_INTERNAL_USER_ACTIVE
			],
			"operation": "AND",
			"properties": [{"filters": [
				CS_IS_OC_INTERNAL_FILTER,
				CS_IS_ATTORNEY_USER_FILTER,
			],
			"operation": "OR"}]
		};

		const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, {}, CS_TYPEAHEAD_PAGINATION_QUERY, {}, defaultQuery, searchQuery);
		postService(
			COMPOSITE_SEARCH_SERVICE,
			`/composite-search/v1?returnCsv=false`,
			query
		).then((response) => {
			setLoading(false);
			const peopleData = response?.data?.people;
			dispatch(setPeopleDropDownDataWithOptions({
				nodeList: peopleData?.data?.sort((a, b) => a?.firstName.localeCompare(b?.firstName)),
				count: peopleData?.count
			}))
		}).catch((error) => {
			setLoading(false);
			addSnack({
				message: getErrorMessage(error)
			})
		})
	}

	/**
	 * onChangeValue - method will return value entered by user
	 * @param {*} value
	 * @param {*} from
	 */
	const onChangeValue = (value) => {
		if (!filterCheck) {
			setValue(isPeopleCheck(value));
		} else if (value?.firstName && value?.lastName) {
			setValue({
				displayValue: `${value?.firstName} ${value?.lastName}`,
				obj: isPeopleCheck(value)
			});
		} else {
			setValue(null);
		}
	}

	useEffect(() => {
		if (inputValue?.length < 1) {
			dispatch(setPeopleDropDownDataWithOptions([]))
			setLoading(false)
		}
	}, [inputValue])

	const isPeopleCheck = (v) => {
		if (v?.id) {
			return {
				referenceKey: v?.id,
				firstName: v?.firstName,
				lastName: v?.lastName,
				userId: v?.userId,
				email: v?.emailId
			}
		} else { return null }
	}
	return (
		<Autocomplete
			id={id}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			disableCloseOnSelect={false}
			disablePortal={true}
			autoHighlight
			autoComplete
			loading={loading}
			noOptionsText={inputValue?.length < 1 ? "Please type to search" : "No options"}
			options={peopleDropDownDataWithOptions?.nodeList ?? []}
			getOptionLabel={(option) => (option?.firstName && option?.lastName) ? `${option?.firstName} ${option?.lastName}` : option}
			renderOption={(props, option) => (
				<li {...props} className={classes.boxLi} key={option?.referenceKey}>
					{getHighlightedText(`${option?.firstName} ${option?.lastName}`, inputValue)}
				</li>
			)}
			onInputChange={(event, newInputValue) => {
				if (!((!event) || (event?.type === "click" && newInputValue !== ""))) {
					setInputValue(newInputValue);
					setLoading(true)
					fetch(newInputValue)
				}
			}}
			filterOptions={filterOptions}
			value={value}
			disabled={disabled}
			fullWidth={true}
			onChange={(e, v) => {
				onChangeValue(v)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params?.inputProps, required, style: {
							color: '#000000',
							fontFamily: 'Bogle',
							fontSize: 16,
						}
					}}
					name={name}
					error={errors && errors[name]}
					helperText={(errors && errors[name]) && <MuiValidationMessage message={errors && errors[name]?.message} />}
					{
					...required ? {
						...register(
							name,
							{
								...validationRules[name], onChange: (v) => {
									onChangeValue(v)
								}
							}
						)
					} : null}
					margin="dense"
					placeholder={placeholderValue}
					variant="outlined"
					data-testid={testid}
				/>
			)}
		/>
	);
};

export default PeopleAutocompleteWithOptions;

PeopleAutocompleteWithOptions.propTypes = {
	/** to display default value on load */
	value: PropTypes.string,
	/** callback function on user input */
	setValue: PropTypes.func,
	/** for checking component is used for filter or not */
	filterCheck: PropTypes.bool,
	/** for differnciate the internal and external user */
	isExternalUser: PropTypes.bool,
	/** for getting the both internal and external user */
	isInternalExternal: PropTypes.bool,
	/** flag to determine if the field is mandatory */
	required: PropTypes.bool,
	/** flag to determine if input is disabled */
	disabled: PropTypes.bool,
	/** placeholder value for the input */
	placeholderValue: PropTypes.string,
	/** testid to identify the autocomplete component */
	testid: PropTypes.string
};
