import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {
  Caption,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import {
  LIGHT_RED,
  LIGHT_GREEN,
  LIGHT_YELLOW
} from "../../../constants/colors";
import {
  CS_SCOPES,
  CS_DEFAULT_SORT_QUERY,
  CS_SCORE_SORT_OBJ,
  EXPORT_MAX_LIMIT_CONF,
  BUDGETS
} from "../../../constants/constants";
import {
  ROWS_PER_PAGE_BUDGET,
  ROWS_PER_PAGE_OPTIONS_BUDGET,
  ENABLE_SERVER_SIDE_FEATURES_BUDGET,
} from "../../../constants/tableConfigs";
import { jsonToCsv, getErrorMessage, renderTernary } from "../../../helpers/utils";
import {
  postService,
} from "../../../axios/axios";
import { Operations } from "../../../axios/operations";
import { setBudgetData } from "../../../store/actions/budgetActions";

import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { getAllowedActionsList } from "../../../helpers/tableUtils";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../helpers/csQueryUtils";
import MuiTagItem from '../../../components/Table/MuiDataTable/MuiTagItem/MuiTagItem';
import { TableCellDisplay } from "../../../helpers/commonUtils";
import ExportModal from "../../../components/Modal/ExportModal/ExportModal";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  dataTableWrap: {
    margin: "0",
    '& table > thead > tr > th.MuiTableCell-head': {
      color: '#000000',
      fontWeight: 'bold'
    },
    '& table > thead > tr > th.MuiTableCell-head button': {
      color: '#000000',
      fontWeight: 'bold'
    },
    "& div>table>tbody>tr>td": {
      fontFamily: "Bogle",
      height: "24px",
      fontWeight: "normal",
      lineHeight: "1.33",
    },
    '& table': {
      '& tr': {
        '& th': {
          color: '#2E2F32',
          fontWeight: 'bold',
          fontSize: "12px",
          fontFamily: "Bogle",
        },
      },
      },
  },
  tabContainer: {
    "& nav > ul > li > a": {
      background: "none",
    }
  },
  starColumnHeader: {
    padding: "6px",
  },
  star: {
    padding: "6px",
  },
  handCursor: {
    cursor: "pointer",
  },
  percentChip: {
    width: '60px',
    height: '20px',
    display: 'block',
    margin: '0 auto',
    textAlign: 'center',
    borderRadius: '10px',
    background: '#E3E4E5',
    overflow: 'hidden'
  },
  exportName: {
    marginBottom: 20
  },
  exportNameAs: {
    paddingTop: 20,
    marginBottom: 20
  },
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
  wd80: {
    width: '80px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd100: {
    width: '100px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd120: {
    width: '120px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd140: {
    width: '120px',
    display: "block",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  wd180: {
    width: '180px',
    wordBreak:'break-word'
  },
  organiztionColumn: {
    width: '112px',
    wordBreak:'break-word'
  }
});


/**
 * Gets called when user download the export file
 * server side
 * @public
 */
 export const getExportedData = (payload, contentType) => {
  const url = window.URL.createObjectURL(
    new Blob([payload], { type: contentType })
  );
  let ext = ".csv";
  if (contentType === "application/octet-stream") {
    ext = ".zip";
  }
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `my-budgets${ext}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

/**
 * Gets called when user download the export file
 * client side
 * @public
 */
export const exportClientData = (budgets, exportSelection, rowsData) => {
  let csvData = jsonToCsv(budgets);
  if(exportSelection === 'currentSelection'){
    csvData = jsonToCsv(rowsData);
  }else if(exportSelection === 'currentPage'){
    csvData = jsonToCsv(budgets);
  }
  //Download the file as CSV
  const url = window.URL.createObjectURL(
    new Blob([csvData]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `my-budgets.csv`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export const getYesNoTag = (value) => {
  if(value) {
    return <MuiTagItem value='Yes' color="green" variant='secondary' />;
  }
  return <MuiTagItem value='No' color="gray" variant='secondary' />;
}

/**
 * Budget component to list the budget under financial component
 */
const BudgetList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {currentFinancialTab} = props;
  const renderBudgetName=(value, tableMeta) => {
    const cellId = `Mui-budget-name-row-${tableMeta.rowIndex}`
    return (
      <div id={cellId} className={classes.wd140}>
        {value}
      </div>
    )
  }
  const renderHeaderLabelBudgetName=() => {
    return <p id={`headcol-0`}>Budget Name</p>
  }
  const renderMatterNumber=(value, tableMeta) => {
    const cellId = `Mui-matter-number-row-${tableMeta.rowIndex}`
    return (
      <div id={cellId} className={classes.wd120}>{value}</div>
    )
  }
  const renderMatterName=(value, tableMeta) => {
    const cellId = `Mui-matter-name-row-${tableMeta.rowIndex}`
    return (
      <div id={cellId} className={classes.wd180}>{renderTernary(value, value, '-')}</div>
    )
  }
  const renderOrgName=(value, tableMeta) => {
    const cellId = `Mui-org-name-row-${tableMeta.rowIndex}`
    return (
      <div id={cellId} className={classes.organiztionColumn}>{value}</div>
    )
  }
  const renderSubType=(value, tableMeta) => {
    const cellId = `Mui-sub-type-row-${tableMeta.rowIndex}`
    return (
      <div id={cellId} className={classes.wd120}>
        <Caption as="p">{value?.charAt(0)?.toUpperCase() + value?.toLowerCase()?.slice(1)}</Caption>
      </div>
    )
  }

  const renderIsActive=(value, tableMeta) => {
    return (
      <div>
        {getYesNoTag(value)}
      </div>
    )
  }

  const renderPaidAmount=(value, tableMeta) => {
    return (
      <div className={classes.wd80}>{value}</div>
    )
  }

  const renderPaidPercentage=(value, tableMeta) => {
    const paidAm = tableMeta?.rowData[8];
    const totalAm = tableMeta?.rowData[11];
    let vl = '0';
    if (paidAm !== undefined && totalAm !== undefined && paidAm !== 0 && totalAm !==0) {
      vl = (paidAm*100)/totalAm;
    }
    let barColor = LIGHT_RED;
    if (vl < 30) {
      barColor = LIGHT_GREEN;
    } else if (vl >= 30 && vl < 70) {
      barColor = LIGHT_YELLOW;
    }
    return (
      <div className={`${classes.percentChip}`}>
        <ProgressBar bgColor={barColor} completed={parseInt(value) || parseInt(vl)} />
      </div>
    )
  }

  const renderInternal=(value)=>{
    return (
      <Caption as="p">
        {((value === true) ? 'Yes' : 'No')}
      </Caption>
    )
  }

  const renderCreatedUpdatedBy=(value) => {
    return (
      <div className={classes.wd100}>{value}</div>
    )
  }

  const COLUMNS = [
    {
      name: "budgetName",
      label: "Budget Name",
      colKey: "budgetName",
      options: {
        sort: true,
        display: true,
        customBodyRender: renderBudgetName,
        customHeadLabelRender: renderHeaderLabelBudgetName,
      },
      operation: Operations.LIKE,
      type: "tag"
    },
    {
      name: "matterNumber",
      label: "Matter #",
      colKey: "matterNumber",
      options: {
        sort: true,
        display: true,
        customBodyRender: renderMatterNumber,
        customFilterListOptions: { render: (v) => `Matter Number: ${v}` },
      },
      operation: Operations.LIKE,
      type: "tag"
    },
    {
      name: "matterName",
      label: "Matter Name",
      colKey: "matterName",
      options: {
        sort: true,
        display: true,
        customBodyRender: renderMatterName,
        customFilterListOptions: { render: (v) => `Matter Name: ${v}` },
      },
      operation: Operations.LIKE,
      type: "tag"
    },
    {
      name: "organizationName",
      label: "Organization",
      colKey: "organizationName",
      options: {
        sort: true,
        display: false,
        customBodyRender: renderOrgName,
      },
      operation: Operations.LIKE,
      type: "tag"
    },
    {
      name: "type",
      label: "Sub-Type",
      colKey: "type",
      options: {
        sort: true,
        display: true,
        customBodyRender: renderSubType,
        customFilterListOptions: { render: (v) => `Sub-Type: ${v}` },
      },
      operation: Operations.LIKE,
      type: "tag"
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => {
          const val = value?.charAt(0)?.toUpperCase() + value?.toLowerCase()?.slice(1);
          return TableCellDisplay(`table-row-status-${tableMeta.rowIndex}`,val);
        },
        customFilterListOptions: { render: (v) => `Status: ${v}` },
      },
      operation: Operations.LIKE,
      type: "tag"
    },
    {
      name: "isActive",
      label: "Active",
      colKey: "isActive",
      options: {
        sort: true,
        display: true,
        customBodyRender: renderIsActive,
        customFilterListOptions: { render: (v) => `Active: ${v}` },
      },
      operation: Operations.EQ,
    },
    {
      name: "amountType",
      label: "Amount Type",
      colKey: "amountType",
      options: {
        display: false,
      },
    },
    {
      name: "paidAmount",
      label: "$ Paid",
      colKey: "paidAmount",
      options: {
        sort: true,
        display: true,
        customBodyRender: renderPaidAmount,
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "paidPercent",
      label: "% Paid",
      colKey: "paidPercent",
      options: {
        sort: true,
        customBodyRender: renderPaidPercentage
      },
    },
    {
      name: "balanceAmount",
      label: "$ Balance",
      colKey: "balanceAmount",
      options: {
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-balanceamount-${tableMeta.rowIndex}`,value),
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "budgetAmount",
      label: "$ Total",
      colKey: "budgetAmount",
      options: {
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-budgetamount-${tableMeta.rowIndex}`,value),
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "isInternal",
      label: "Internal",
      colKey: "isInternal",
      options: {
        display: false,
        sort: true,
        customBodyRender: renderInternal
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      colKey: "createdBy",
      options: {
        display: false,
        sort: true,
        customBodyRender: renderCreatedUpdatedBy
      },
    },
    {
      name: "lastUpdatedBy",
      label: "Updated By",
      colKey: "lastUpdatedBy",
      options: {
        display: false,
        sort: true,
        customBodyRender: renderCreatedUpdatedBy
      },
    },
    {
      name: "version",
      label: "Version",
      colKey: "version",
      options: {
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-version-${tableMeta.rowIndex}`,value)
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "leadAttorneyName",
      label: "Case Manager",
      colKey: "leadAttorneyName",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-version-${tableMeta.rowIndex}`,value),
        customFilterListOptions: { render: (v) => `Case Manager: ${v}` },
        display : false
      },
    }
  ];

  const dispatch = useDispatch();
  const budgetAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial?.budget);
  const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const { budgetData } = useSelector((state) => state?.budget);
  const [budgetColumns, setBudgetColumns] = useState(COLUMNS);
  const [loading, setLoading] = useState(false);
  const [rowsPerPageBudget, setRowsPerPageBudget] = useState(ROWS_PER_PAGE_BUDGET);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [sortQuery, setSortQuery] = useState(CS_DEFAULT_SORT_QUERY);
  const [exportLoading, setExportLoading] = useState(false);
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const showExportAllOption = budgetData?.count < EXPORT_MAX_LIMIT_CONF.BUDGET;
  const defaultExportOption = showExportAllOption ? 'allBudgets' : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);


  const defaultQuery = {
    "filters": [formCsFilterObject("isDeleted","match",false),formCsFilterObject("organizationId","match",orgIdentifier)],
    "operation": "AND",
    "properties": null
    }
  const { addSnack } = useSnackbar();

  const FILTER_CONFIGS = [
    {
      colKey: "matterNumber",
      type: "matterDropdownAutocomplete",
      id:"matterNumber",
      label: "Matter Number",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Matter',
      marginBottom : '10px',
      isRestricted: false
    },
    {
      colKey: "matterName",
      type: "matterDropdownAutocomplete",
      id:"matterName",
      label: "Matter Name",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Matter',
      isRestricted: false
    },
    {
      colKey: "status",
      type: "checkbox",
      label: "Status",
      options: ["Approved","Pending", "Rejected"],
      defaultOpen: true,
      displayLabel: false,
      accordian: 'Status'
    },
    {
      colKey: "isActive",
      type: "radio",
      label: "Active",
      options: ["Yes", "No"],
      defaultOpen: true,
      displayLabel: false,
      accordian: 'Active',
    },
    {
      colKey: "leadAttorneyName",
      type: "peopleDropdownAutocomplete",
      label: "Lead Attorney",
      defaultOpen: true,
      displayLabel: false,
      accordian: 'Lead Attorney'
    },
    {
      colKey: "type",
      type: "checkbox",
      label: "Sub-Type",
      options: ["Phase", "Total"],
      defaultOpen: true,
      displayLabel: false,
      accordian: 'Sub-Type'
    },
  ];

  /**
   * Function to call Budget Search Service
   * @param {string} query
   */
  const getBudgetData = (paginationQuery) => {
    setLoading(true);
    const sortQueryBudget = {...sortQuery};
		if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryBudget.sortBy = [CS_SCORE_SORT_OBJ, ...sortQueryBudget?.sortBy];
    }
    const query = formCompositeSearchQuery([CS_SCOPES.BUDGET], filterQuery, searchQuery, sortQueryBudget, paginationQuery, {}, defaultQuery, {});
    let serviceEndPoint = `/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}`;
    // add flag to fetch matter id list for logged in user
    if(!budgetAccessibility?.viewAll) {
      serviceEndPoint += '&fetchMatterIdListForUserId=true';
    }
    postService(
      COMPOSITE_SEARCH_SERVICE,
      serviceEndPoint,
      query
    )
    .then((response) => {
      setLoading(false);
      dispatch(setBudgetData({
        budgets:response?.data?.budget?.data,
        count:response?.data?.budget?.count}));
    })
    .catch((error) => {
      setLoading(false);
      addSnack({
        message: getErrorMessage(error),
      });
    });
  };

  /**
   * Function to handle row click
   * @param {object} rowData: row data
   * @param {object} rowMeta: meta data of selected row
   */
  const handleRowClick = (rowData, rowMeta) => {
    const { type, id } = budgetData.budgets[rowMeta.rowIndex];
    history.push(`/budget/${id}`, { budgetType: type.toLowerCase(), budgetId: "1" ,currentFinancialTab})
  }

  /**
   * Function to validate search query and set query or show no results UI
   * @param {string} searchText
   */
   const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
   * Function to set filter query on filter action
   * @param {string} query
   */
  const handleServerSideFilter = (query) => {
    setFilterQuery(query);
  };

  /**
   * Function to call api on pagination action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPageBudget(rowsPerPage);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getBudgetData(paginationQuery);
  };

  /**
   * Function to call api on sort action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name === "paidPercent" ? "paidAmount" : name,direction);
    setSortQuery(sortQuery);
  };

  const budgetListTableCustomOptions = {
    downloadOptions: {
      filename: "budget",
    },
    responsive: "standard",
    rowsPerPage: rowsPerPageBudget,
    isBudget: true,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_BUDGET,
    filterConfigs: FILTER_CONFIGS,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_BUDGET,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSort: handleServerSideSort,
    totalCount: budgetData.count
  };

  /**
   * Cancel Export Modal
   */
  const onCancelBtnClk = () => {
    setExportLoading(false)
    setModalOpen(false)
  }

  /**
   * function is to open the export modal to show download options
   */
  const handleExportBtnClk = () => {
    setExportSelection(defaultExportOption)
    setModalOpen(true);
    setExportLoading(true);
    setCurrentPageExport(true);
  }

  /**
   * function will use when row is select/deselect
   */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const bData = budgetData.budgets;
    const result = allSelected?.map(item => {
      return bData && bData[item?.index]
    });
    setSelectRowsData(selectRowsData);
    setRowsData(result);
  }

  /**
   * After click on create button download Actions
   */
  const onClickCreate = () => {
    setCreateExportLoading(true);
    if(ENABLE_SERVER_SIDE_FEATURES_BUDGET === false){
      exportClientData(budgetData.budgets, exportSelection, rowsData);
      setModalOpen(false);
      setExportLoading(false);
      setCreateExportLoading(false);
    }else{
      let exportQuery = formCompositeSearchQuery([CS_SCOPES.BUDGET],   filterQuery, searchQuery, sortQuery, {},{getAll: true}, {}, defaultQuery);
      if(exportSelection === 'currentSelection'){
        const result = rowsData?.map(a => a?.id);
        const selectionQuery =
        {
          "filters": [formCsFilterObject("id","in",result)],
          "operation": "AND",
          "properties": null
        }
        exportQuery = formCompositeSearchQuery([CS_SCOPES.BUDGET], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
      }else if(exportSelection === 'currentPage'){
        const result = budgetData?.budgets.map(a => a.id);
        const selectionQuery =
        {
          "filters": [formCsFilterObject("id","in",result)],
          "operation": "AND",
          "properties": null
        }
        exportQuery = formCompositeSearchQuery([CS_SCOPES.BUDGET], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
      }
      let serviceEndPoint = `/composite-search/v1?returnCsv=true&organizationId=${orgIdentifier}`;
      // add flag to fetch matter id list for logged in user
      if (!budgetAccessibility?.viewAll) {
        serviceEndPoint += '&fetchMatterIdListForUserId=true';
      }
      postService(
        COMPOSITE_SEARCH_SERVICE,
        serviceEndPoint,
        exportQuery
      )
        .then((response) => {
          const data = response?.data;
          const contentType = response?.headers["content-type"];
          getExportedData(data, contentType);
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
        })
        .catch((error) => {
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
          addSnack({
            message: getErrorMessage(error),
          });
        });
    }
  }

  /**
   * Function will call when user click and Add New Button
  **/
  const handleAddNew = () => {
    history.push("/budget/0/view", { fromMatter: false });
  }

  /**
   * To load initial data and handle search/filter/sort query change on Budget datatable
   */
  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": rowsPerPageBudget,
    }
    getBudgetData(paginationQuery);
  }, [searchQuery, filterQuery, sortQuery]);

  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  return (
    <>
      <div className={classes.dataTableWrap}>
        <MuiDataTable
          data={budgetData.budgets}
          columns={budgetColumns}
          setTableColumns={setBudgetColumns}
          customOptions={budgetListTableCustomOptions}
          selectableRowsType={"multiple"}
          onRowClick={handleRowClick}
          groupFilter={true}
          exportLoading={exportLoading}
          onExportClick={handleExportBtnClk}
          selectedRows={selectRowsData}
          onRowSelection={handleRowSelectionChange}
          onAddNew={handleAddNew}
          loading={loading}
          showRowsCounter={false}
          allowedActions={getAllowedActionsList(budgetAccessibility)}
          resetSelectedRows={() => resetSelectedRows()}
        />
      </div>
      <ExportModal
          title={BUDGETS}
          mode={modalOpen}
          onCancel={onCancelBtnClk}
          onCreate={onClickCreate}
          rowsData={rowsData}
          showExportAllOption={showExportAllOption}
          exportSelection={exportSelection}
          setExportSelection={setExportSelection}
          currentPageExport={currentPageExport}
          setCurrentPageExport={setCurrentPageExport}
          exportLoading={createExportLoading}
        />
    </>
  );

}

export default BudgetList;
