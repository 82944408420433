import {
	Button,
	Grid,
	GridColumn,
	Heading, Tag, useSnackbar
} from "@walmart-web/livingdesign-components";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getService } from "../../../axios/axios";
import DisplayValue from "../../../components/TextField/DisplayValue/DisplayValue";
import LoadComponent from "../../../components/LoadComponent/LoadComponent";
import { USER_REQUEST_SERVICE } from "../../../constants/baseURLs";
import { practiceAreaRatesStatusColor } from "../../../constants/colors";
import {
	ADD_NEW_RATE,
	APPROVED_RATE, APPROVING_ATTORNEY, CURRENT_STANDARD_RATE, DISCOUNT, END_DATE, JUSTIFICATION_FOR_RATE,
	PRACTICE_AREA_RATES,
	PROPOSED_WALMART_RATE, REJECTION_COMMENT, REJECTION_REASON, REQUESTER_EMAIL, START_DATE,
	STATUS_ACTIVE
} from "../../../constants/constants";
import { convertPACodeToName, getErrorMessage, getRejectionReason } from "../../../helpers/utils";
import { isCurrencyValueEmptyCheck, isValueEmptyCheck } from "../../../helpers/validationUtils";
import { setPracticeAreaRates } from "../../../store/actions/peopleAction";
import PracticeAreaRatesStyles from "./PeoplePracticeAreaRatesList.styles";
import { displayLocalDate } from "../../../helpers/utcUtils";

/*
* This component is for displaying PRACTICE AREA Rates associated with any user
*/

const PeoplePracticeAreaRatesList = (props) => {
	const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.people?.tabs?.active?.details?.tabs?.practiceAreaRates);
	const status = useSelector(state => state?.people?.userDetail?.status);
	const classes = PracticeAreaRatesStyles();
	const userRequestId = useSelector((state) => state?.user?.userRequestId);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { addSnack } = useSnackbar();
  	const { peopleId } = useParams()
	const { practiceAreaRates } = useSelector((state) => state?.people);
	const { userDetail } = useSelector((state) => state?.people)
	const masterDetails = useSelector(state => state?.masterData?.masterDatas);
	const loadComponentProps = {
		loader: loading,
		skeletonHeight: '18px',
		skeletonVariant: 'reactangle',
		skeletonWidth: '250px',
		skeletonLines: 1
	};
  const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);
	const practiceAreaCodeToName = useSelector(state => state?.matter?.practiceAreaCodeToName);

	useEffect(() => {
		getPracticeAreaRates();
	}, []);

	/*
	*Function to call User Request Service to fetch PRACTICE AREA Rates
	*/
	const getPracticeAreaRates = () => {
		setLoading(true);
		getService(USER_REQUEST_SERVICE, `/user-requests/v1/${userRequestId}?organizationId=${identifier}`).then((res) => {
			dispatch(setPracticeAreaRates(res?.data?.practiceAreaSpecificRates));
			setLoading(false);
		}).catch((error) => {
			addSnack({
			message: getErrorMessage(error)
		});
			setLoading(false);
		});
	}

	/**
 * Function will use for add New Rate
 */
	const addNewRate = () => {
		history.push(`/people/${userDetail?.userRequestId}/add-practice-area-rate`, {peopleIdentifier: peopleId, organizationName: userDetail?.organizationName, userName:`${userDetail?.firstName} ${userDetail?.lastName}`, selectedTab:2})
	}

	return (
		<div data-testid="specific-rates-practice-container">
			<div className={classes.whiteContainer}>
				<div className={classes.titleWrap}>
					<Heading as="h1" size="medium">
						{PRACTICE_AREA_RATES}
					</Heading>
					{(accessibility?.addNew && status?.toLowerCase() === STATUS_ACTIVE) &&
						<div>
							<Button
								size="small"
								variant="primary"
								data-testid="add-new-rate"
								id="add-new-rate"
								onClick={addNewRate}
							>
								{ADD_NEW_RATE}
							</Button>
						</div>
					}
				</div>
				<LoadComponent {...{ ...loadComponentProps, skeletonWidth: '100%', styles: { ...{ padding: '24px', display: 'block' } } }}>
					<div className={classes.scrollRates}>
						{practiceAreaRates?.map((item, idx) => {
							return <>
								<div className={classes.rateDetails}>
									<Grid key={item?.matterNumber}>
										<GridColumn sm="11" md="11" lg="11">
											<Heading as="h1" size="small" id="practiceAreaCode">
												{isValueEmptyCheck(practiceAreaCodeToName?.hasOwnProperty(item?.practiceAreaCode) && practiceAreaCodeToName[item?.practiceAreaCode])}
											</Heading>
										</GridColumn>
										<GridColumn sm="1" md="1" lg="1">
											<Tag
												color={item?.status ? practiceAreaRatesStatusColor[item?.status?.toLowerCase()]?.color : 'white'}
												variant={"primary"}
											>
												{item?.status && practiceAreaRatesStatusColor[item?.status?.toLowerCase()]?.label}
											</Tag>
										</GridColumn>
										<GridColumn sm="5" md="5" lg="5">
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={START_DATE}
													value={item?.startDate ? displayLocalDate(item?.startDate) : "-"}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={END_DATE}
													value={item?.endDate ? displayLocalDate(item?.endDate) : "-"}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={CURRENT_STANDARD_RATE}
													value={isCurrencyValueEmptyCheck(item?.currentStandardRate)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={PROPOSED_WALMART_RATE}
													value={isCurrencyValueEmptyCheck(item?.proposedWalmartRate)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={DISCOUNT}
													value={item?.discount ? `${item?.discount}%` : '-'}
												/>
											</div>
											{ item?.status === "Active" && <div className={classes.contentSpacing16}>
												<DisplayValue
													label={APPROVED_RATE}
													value={isCurrencyValueEmptyCheck(item?.currentWalmartRate)}
												/>
											</div> }
										</GridColumn>
										<GridColumn sm="7" md="7" lg="7">
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={APPROVING_ATTORNEY}
													value={isValueEmptyCheck(item?.walmartApprovingAttorneyFullName)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={REQUESTER_EMAIL}
													value={isValueEmptyCheck(item?.requesterEmailId)}
												/>
											</div>
											<div className={classes.contentSpacing16}>
												<DisplayValue
													label={JUSTIFICATION_FOR_RATE}
													value={isValueEmptyCheck(item?.outsideOrgJustification)}
												/>
											</div>
										</GridColumn>
									</Grid>
								</div>
								{item?.status === "Rejected" ?
								<div className={classes.rejectReason}>
								<LoadComponent {...loadComponentProps}>
								<Grid className={classes.rejectGrid}>
									<GridColumn sm={5}>
									<DisplayValue
										label={REJECTION_REASON}
										value={getRejectionReason(item?.rejectReasonCategoryCode, masterDetails?.timekeeperRateRejectReason)}
									/>
									</GridColumn>
									<GridColumn sm={5}>
									<DisplayValue
										label={REJECTION_COMMENT}
										value={item?.rejectReasonDetails ? item?.rejectReasonDetails : "-"}
									/>
									</GridColumn>
								</Grid>
								</LoadComponent>
      						</div> : <Fragment></Fragment>}
							</>
						})}
					</div>
				</LoadComponent>
			</div>
		</div>
	)
}

export default PeoplePracticeAreaRatesList;
