import { PATTERNS } from "./validationPattern";
import {
  BUDGET_AMOUNT_MESSAGE,
  BUDGET_NAME_MESSAGE,
  CHARACTER_RESTRICTION,
  COMPENSATORY_DAMAGES_MESSAGE,
  COUNTER_CLAIMS_MESSAGE,
  CURRENT_AUTHORITY_MESSAGE,
  CURRENT_DEMAND_MESSAGE,
  CURRENT_OFFER_MESSAGE,
  DIGIT_RESTRICTION,
  EMAIL_ID_MESSAGE, FACILITY_NUMBER_MESSAGE,
  FAX_NUMBER_MSG,
  ITEM_NUMBER_MESSAGE, MANDATORY_FIELD, MARKET_NUMBER_MESSAGE,
  MIN_DIGIT_RESTRICTION,
  NEW_PASSWORD_MESSAGE,
  ONLY_ALPHA_NUMERIC_ALLOWED_DOCKET, ONLY_LETTERS_ALLOWED,
  PHONE_NUMBER_MSG,
  PUNITIVE_DAMAGES_MESSAGE,
  SETTLEMENT_AUTHORITY_MESSAGE,
  TKID_MESSAGE,
  ONLY_ALPHA_NUMERIC_ALLOWED_COURT_NAME,
  INVOICE_NUMBER_MESSAGE,
  ZIPCODE_MESSAGE,
} from "../Validation/validationMessages";

// Matter detail validation rules
export const matterDetailValidationRules = {
  description: {
    maxLength : {
      value: 2000,
      message: CHARACTER_RESTRICTION(2000)
    }
  },
  facilityNumber: {
    pattern: {
      value: PATTERNS.NUMERIC,
      message: FACILITY_NUMBER_MESSAGE
    },
    maxLength : {
      value: 6,
      message: DIGIT_RESTRICTION(6)
    }
  },
  facilityRegion: {
    pattern: {
      value: PATTERNS.NUMERIC,
      message: FACILITY_NUMBER_MESSAGE
    }
  },
  storeMarketNumber: {
    pattern: {
      value: PATTERNS.NUMERIC,
      message: MARKET_NUMBER_MESSAGE
    }
  },
  settlementAuthority: {
    pattern: {
      value: PATTERNS.NUMERIC,
      message: SETTLEMENT_AUTHORITY_MESSAGE
    }
  },
  facilityCity: {
    pattern: {
      value: PATTERNS.CHARACTERS,
      message: ONLY_LETTERS_ALLOWED
    }
  },
  storeMarketManager: {
    pattern: {
      value: PATTERNS.CHARACTERS,
      message: ONLY_LETTERS_ALLOWED
    }
  },
  facilityManager: {
    pattern: {
      value: PATTERNS.CHARACTERS,
      message: ONLY_LETTERS_ALLOWED
    }
  },
  facilityRegionalManager: {
    pattern: {
      value: PATTERNS.CHARACTERS,
      message: ONLY_LETTERS_ALLOWED
    }
  },
  courtName: {
    pattern: {
      value: PATTERNS.ALPHA_NUMERIC_WITH_SPECIAL_COURT_NAME,
      message: ONLY_ALPHA_NUMERIC_ALLOWED_COURT_NAME
    }
  },
  docketNumber: {
    pattern: {
      value: PATTERNS.ALPHA_NUMERIC_WITH_SPECIAL_DOCKET,
      message: ONLY_ALPHA_NUMERIC_ALLOWED_DOCKET
    }
  },
  itemNumber:{
    pattern: {
      value: PATTERNS.ALPHA_NUMERIC,
      message: ITEM_NUMBER_MESSAGE
    }
  },
  compensatoryDamages :{
    pattern: {
      value: PATTERNS.NUMERIC,
      message: COMPENSATORY_DAMAGES_MESSAGE
    }
  },
  counterClaimsDamages :{
    pattern: {
      value: PATTERNS.NUMERIC,
      message: COUNTER_CLAIMS_MESSAGE
    }
  },
  currentDemand :{
    pattern: {
      value: PATTERNS.NUMERIC,
      message: CURRENT_DEMAND_MESSAGE
    }
  },
  currentOffer :{
    pattern: {
      value: PATTERNS.NUMERIC,
      message: CURRENT_OFFER_MESSAGE
    }
  },
  currentAuthority :{
    pattern: {
      value: PATTERNS.NUMERIC,
      message: CURRENT_AUTHORITY_MESSAGE
    }
  },
  punitiveDamages :{
    pattern: {
      value: PATTERNS.NUMERIC,
      message: PUNITIVE_DAMAGES_MESSAGE
    }
  },
};

// Invoice validation rules
export const invoiceValidationRules = {
  invoiceNumber: {
    pattern: {
      value: PATTERNS.NUMERIC,
      message: INVOICE_NUMBER_MESSAGE
    },
    maxLength: {
      value: 15,
      message: DIGIT_RESTRICTION(15)
    },
    required:{
      value: true,
      message : MANDATORY_FIELD('Invoice number')
    }
  },
  matter: {
    required: MANDATORY_FIELD('Matter')
  },
}

//Login Page validation rules
export const loginValidationRules = {
  email: {
    required: MANDATORY_FIELD('Email id'),
    pattern: {
      value: PATTERNS.EMAIL,
      message: EMAIL_ID_MESSAGE
    },
  },
  password: {
    required: MANDATORY_FIELD('Password')
  },
}

//Change Password validation rules
export const ResetPasswordValidationRules = {
  token: {
    required:{
      value: true,
      message : MANDATORY_FIELD('Token')
    }
  },
  newPassword: {
    required:{
      value: true,
      message : MANDATORY_FIELD('Password')
    },
    minLength: {
      value: 4,
      message: MIN_DIGIT_RESTRICTION(4)
    },
    maxLength: {
      value: 8,
      message: DIGIT_RESTRICTION(8)
    },
    pattern: {
      value: PATTERNS.PASSWORD,
      message: NEW_PASSWORD_MESSAGE
    },
  },
  reEnterPassword: {
    required: MANDATORY_FIELD('Re-enter password')
  },
}

// Budget detail validation rules
export const budgetDetailValidationRules = {
  budgetName: {
    pattern: {
      value: PATTERNS.ALPHA_NUMERIC,
      message: BUDGET_NAME_MESSAGE
    },
    maxLength: {
      value: 50,
      message: CHARACTER_RESTRICTION(50)
    },
    required:{
      value: true,
      message : MANDATORY_FIELD('Budget Name')
    }
  },
  code:{
    required:{
      value: true,
      message : MANDATORY_FIELD('code')
    }
  },
  displayName:{
    required:{
      value: true,
      message : MANDATORY_FIELD('Description')
    }
  },
  budgetAmount: {
    pattern: {
      value: PATTERNS.NUMERIC_DECIMAL,
      message: BUDGET_AMOUNT_MESSAGE
    },
    required:{
      value: true,
      message : MANDATORY_FIELD('Budget Amount')
    }
  },
  matter: {
    required: MANDATORY_FIELD('Matter')
  },
};

// Organization detail validation rules
export const organizationValidationRules = {
  companyName: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Company name')
    },
  },
  email: {
    pattern: {
      value: PATTERNS.EMAIL,
      message: EMAIL_ID_MESSAGE
    },
  },
  phoneNo: {
    pattern: {
      value: PATTERNS.PHONE_NUMBER,
      message: PHONE_NUMBER_MSG
    },
  },
  faxNo: {
    pattern: {
      value: PATTERNS.NUMERIC,
      message:FAX_NUMBER_MSG
    },
  },
  zipCode: {
    pattern: {
      value: PATTERNS.ZIPCODE,
      message: ZIPCODE_MESSAGE
    },
    maxLength: {
      value: 10,
      message: DIGIT_RESTRICTION(10)
    }
  }
};


// Time keeper detail validation rules
export const timekeeperValidationRules = {
  firstName: {
    required: {
      value: true,
      message: MANDATORY_FIELD('First name')
    },
  },
  lastName: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Last name')
    },
  },
  email: {
    pattern: {
      value: PATTERNS.EMAIL,
      message: EMAIL_ID_MESSAGE
    },
    required: {
      value: true,
      message: MANDATORY_FIELD('Email Id')
    },
  },
  phoneNo: {
    pattern: {
      value: PATTERNS.PHONE_NUMBER,
      message:PHONE_NUMBER_MSG
    },
  },
  addressLine1: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Address Line 1')
    },
  },
  addressType: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Address Type')
    },
  },
  stateBarLicence: {
    required: {
      value: true,
      message: MANDATORY_FIELD('State bar license')
    },
  },
  subjectMatterExpertise: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Subject Matter Expertise')
    },
  },
  justificationForTimeKeeper: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Justification For TimeKeeper')
    },
  },
  walmartTimekeeperAttorney: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Walmart Approving Attorney')
    },
  },
  tkId: {
    required: {
      value: true,
      message: MANDATORY_FIELD('TkId')
    },
    pattern: {
      value: PATTERNS.ALPHA_NUMERIC,
      message: TKID_MESSAGE
    },
    maxLength : {
      value: 10,
      message: DIGIT_RESTRICTION(10)
    }
  },
  classification: {
    required: {
      value: true,
      message: MANDATORY_FIELD("Classification")
    },
  },
  practiceArea: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Practice area')
    },
  },
  practiceAreaCode: {
    required: {
      value: true,
      message: MANDATORY_FIELD('Practice area')
    },
  },
  matter: {
    required: MANDATORY_FIELD('Matter')
  },
};

//Notes validation rules
export const notesValidationRules = {
  title: {
    required:{
      value: true,
      message : MANDATORY_FIELD('Title')
    }
  },
  description: {
    required:{
      value: true,
      message : MANDATORY_FIELD('Description')
    },
    maxLength : {
      value: 250000,
      message: DIGIT_RESTRICTION(250000)
    }
  },
}


// Autocomplete validation rules
export const autocompleteValidationRules = {
  matterAutocomplete: {
    required:{
      value: true,
      message : MANDATORY_FIELD('Matter')
    }
  },
  peopleAutocomplete: {
    required:{
      value: true,
      message : MANDATORY_FIELD('This field')
    }
  }
}
