import MuiTextField from "@mui/material/TextField";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    Button,
    Grid,
    GridColumn,
    Heading,
    Select,
    TextField,
    useSnackbar,
    Divider,
    Caption,
    IconButton,
    StyledText
} from "@walmart-web/livingdesign-components";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getService, postService } from "../../../../axios/axios";
import MatterAutocomplete from "../../../../components/Autocomplete/MatterAutocomplete/MatterAutocomplete";
import {
    detailModes as modes,
    acceptedExcelFilesForDocuments,
    START_END_DATE_VALIDATION_MESSAGE,
    TOTAL_NET_AMOUNT,
    RECEIVED_DATE,
    CREATE_DATE,
    ORGANIZATION,
    MATTER,
    DESCRIPTION,
    CS_IS_ACTIVE_FILTER,
    CS_IS_EXTERNAL_USER_FILTER,
		CS_IS_TIMEKEEPER_ROLE_ENABLED_FILTER,
    CS_SCOPES,
    PEOPLE_AUTOCOMPLETE_DEFAULT_SORT_QUERY,
    CS_TYPEAHEAD_PAGINATION_QUERY,
    INVOICE_DATE,
    MAX_FILE_UPLOAD_SIZE_LINE_ITEMS,
    MAX_FILE_UPLOAD_SIZE_MSG_LINE_ITEMS,
    CS_TYPEAHEAD_LIMIT,
    INVOICE_LINE_ITEM_HEADERS,
    LINE_ITEM_TYPE_FEE_DISCOUNT,
    LINE_ITEM_TYPE_EXPENSE_DISCOUNT,
    INVOICE_LINE_ITEM_HEADERS_MANDATORY,
    LINE_ITEM_TYPE_FEE, LINE_ITEM_TYPE_EXPENSE, LEDES_COMPATIBLE_FILE_MESSAGE, LEDES_SAMPLE_FILE_LINK_MSG,
    LINE_ITEMS_INFO_MSG
 } from "../../../../constants/constants";
import { FIELD_REQUIRED } from "../../../../Validation/validationMessages";
import {
  INVALID_DATE_MESSAGE,
  INVALID_DATE_RANGE_MESSAGE,
  INVALID_LEDES_FORMAT_MSG,
  INVALID_LEDES_UPLOAD_DATA_FORMAT_MSG,
  INVOICE_SAVE_MESSAGE,
  LEDES_FILE_PROCESSED_SUCCESS_MSG} from "../../../../constants/messages";
import Detail from "../../../Detail/Detail";
import DocumentDropzone from "../../../../components/DocumentDropzone/DocumentDropzone";
import MuiDataTable from "../../../../components/Table/MuiDataTable/MuiDataTable";
import { TrashCan, Plus } from "@walmart-web/livingdesign-icons";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import Label from "../../../../components/TextField/Label/Label";
import { BUDGET_SERVICE, COMPOSITE_SEARCH_SERVICE, INVOICE_SERVICE } from "../../../../constants/baseURLs";
import InvoiceViewStyles from "./CreateEditInvoice.styles";
import { useForm } from "react-hook-form";
import { invoiceValidationRules } from "../../../../Validation/validationRules";
import MuiValidationMessage from "../../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { getErrorMessage, onInputNumberKeyDown, renderTernary } from "../../../../helpers/utils";
import { BACKEND_DATE_FORMAT, DATE_END_TIME, DATE_START_TIME, DATE_YYYY_MM_DD } from "../../../../constants/dateFormats";
import UtbmsActivityAutocomplete from "../../../../components/Autocomplete/UtbmsActivityAutocomplete/UtbmsActivityAutocomplete";
import { filterPayload } from "../../../../helpers/commonUtils";
import { DISCARD, SUBMIT } from "../../../../constants/actionButtons";
import InvoiceTimekeeperAutocomplete from "../../../../components/Autocomplete/InvoiceTimekeeperAutocomplete/InvoiceTimekeeperAutocomplete";
import { formCompositeSearchQuery, formCsFilterObject, formCsInvoiceTimekeeperAutocompleteQuery } from "../../../../helpers/csQueryUtils";
import { setExternalPeopleDropDownData } from "../../../../store/actions/masterDataAction";
import { convertToUTCDateTime } from "../../../../helpers/utcUtils";
import {
  ROWS_PER_PAGE_ADD_INVOICE_LINE_ITEM,
  ENABLE_SERVER_SIDE_ADD_INVOICE_LINE_ITEM,
  ROWS_PER_PAGE_OPTIONS_ADD_INVOICE_LINE_ITEM
} from "../../../../constants/tableConfigs";
import { isEmptyObj, isEmpty, isTkidMandatory, isValueValidList } from '../../../../helpers/validationUtils';
import { FEATURES } from "../../../../constants/features";

const Title = {
    edit: "Edit Invoice",
    create: "New Invoice",
};
const BOTTOM_START='bottom-start'

const InvoiceView = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState(null);
    const { matterDetail } = useSelector((state) => state?.matter);
    const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
    const { state: { invoiceId = "0", fromMatter, currentFinancialTab, currentMatterTab, practiceAreaCode } = {} } = useLocation();
    const [viewTable, setViewTable] = useState(false);
    const masterDetails = useSelector(state => state?.masterData?.masterDatas);
    const { register, formState: { errors }, setError, clearErrors } = useForm({mode: "all"});
    const { identifier, companyName, wIREFirm, hostId } = useSelector((state) => state?.organization?.organizationDetailValues);
    const { externalPeopleDropDownData } = useSelector((state) => state?.masterData);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_ADD_INVOICE_LINE_ITEM);
    const [page, setPage] = useState(0);
    const lineItemDiscountTypes = [LINE_ITEM_TYPE_FEE_DISCOUNT, LINE_ITEM_TYPE_EXPENSE_DISCOUNT];
    const lineItemTypeMandatory = [LINE_ITEM_TYPE_FEE, LINE_ITEM_TYPE_EXPENSE];

    const featureMap = useSelector((state) => state?.user?.featureMap);
    const lineItemTypeOptions = featureMap?.[FEATURES?.INVOICE_LINE_ITEM_DISCOUNT] ? masterDetails?.lineItemType?.masterData?.sort((a, b) => a.code - b.code) :
                                masterDetails?.lineItemType?.masterData?.filter(item =>  item?.code === 382 || item?.code === 383)
   const utbmsList = {
    fee: [...masterDetails?.feeCode?.masterData?.sort((a, b) => a?.value.localeCompare(b?.value)) || ''],
    expense: [...masterDetails?.expenseCode?.masterData?.sort((a, b) => a?.value.localeCompare(b?.value)) || ''],
    '': []
  }

    /**
    * Function to call associate people list Service
    */
    const getPeopleData = async (text) => {
      const filter = [
        CS_IS_ACTIVE_FILTER,
        CS_IS_EXTERNAL_USER_FILTER,
        formCsFilterObject("organizationId", "match", orgIdentifier)
      ]
      pureAdminRole && filter.push(CS_IS_TIMEKEEPER_ROLE_ENABLED_FILTER);
      const defaultQuery = {
        "filters": filter,
        "operation": "AND",
        "properties": null
      }

      const searchQuery = text ? formCsInvoiceTimekeeperAutocompleteQuery(text) : {};
      const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, PEOPLE_AUTOCOMPLETE_DEFAULT_SORT_QUERY, CS_TYPEAHEAD_PAGINATION_QUERY, {}, defaultQuery, searchQuery);
      const apiResponse = await postService(
        COMPOSITE_SEARCH_SERVICE,
        `/composite-search/v1?returnCsv=false`,
        query
      )
      .catch((error) => {
          handleError(error)
      });
      if (apiResponse && apiResponse?.status === 200) {
        const res = apiResponse;
        if(text) {
          const obj = Promise.resolve(res?.data?.people?.data[0] || {})
          return obj;
        } else {
        dispatch(setExternalPeopleDropDownData({
          nodeList: res?.data?.people?.data || [],
          count: res?.data?.people?.count
        }))
        }
      }else {
        dispatch(setExternalPeopleDropDownData({
          nodeList: [],
          count: 0
        }))
      }
    };

    useEffect(() => {
      getPeopleData();
    }, [identifier]);

    const renderSerailNo = (value, tableMeta) => {
      return (
          <Caption as="p" className={classes.serialNoItem}>
              {value}
          </Caption>
      );
  }

  const renderDescription = (value, tableMeta, updateValue) => {
    return (
      <div className={classes.descriptionItem}>
        <TextField
          onChange={(event) => itemNameChange(event, updateValue)}
          onKeyUp={(event) => onBlurForItemLeave(event, tableMeta)}
          onClick={(event) => itemNameClick(event)}
          type="text"
          value={value}
          textFieldProps={{
              name: "description",
              id: "description",
          }}
          data-testid="description-text-field"
        />
      </div>
    );
}

const renderType = (value, tableMeta, updateValue) => {
  return (
    <div className={classes.typeItemSelect}>
      <Select
        value={value?.toLowerCase() || ''}
        data-testid="type-text-field"
        onChange={(event) => itemNameChange(event, updateValue, tableMeta?.rowIndex)}
        onMouseLeave={(event) => onBlurForItemLeave(event, tableMeta)}
        onKeyUp={(event) => onBlurForItemLeave(event, tableMeta)}
        onClick={(event) => itemNameClick(event)}
        selectProps={{
            name: "type",
            id: "type",
        }}
        error={value === '' || value === null ? " " : ''}
      >
        <option value="">{"Select type"}</option>
        {lineItemTypeOptions?.map((item,idx) =>
          <option value={item?.description?.toLowerCase()} key={item?.code} id={`type-${idx}`}>
            {item?.description}
          </option>
        )}
      </Select>
    </div>
  );
}

const renderUTBMS=(value, tableMeta, updateValue) => {
  const selectedLineItemType = ref.current[tableMeta?.rowIndex]?.type?.toLowerCase();
  return lineItemDiscountTypes.includes(selectedLineItemType) ? <>-</> : (
  <div className={classes.paddingTop}>
    <UtbmsActivityAutocomplete
      name="utbms"
      value={value}
      setValue={updateValue}
      tableMeta={tableMeta}
      data={utbmsList[selectedLineItemType ?? '']}
      handleBlur={onBlurForItemLeaveAutocomplete}
      placeholder="Select UTBMS code"
      helperText={value !== null ? '' : FIELD_REQUIRED}
/>
  </div>
  );
}

const renderACT=(value, tableMeta, updateValue) => {
  const selectedLineItemType = ref.current[tableMeta?.rowIndex]?.type?.toLowerCase();
  return lineItemDiscountTypes.includes(selectedLineItemType) ? <>-</> : (
      <div className={classes.paddingTop}>
      <UtbmsActivityAutocomplete
        name="act"
        value={value}
        tableMeta={tableMeta}
        data={[...masterDetails?.activityCode?.masterData?.sort((a, b) => a?.value.localeCompare(b?.value)) || '']}
        setValue={updateValue}
        handleBlur={onBlurForItemLeaveAutocomplete}
        placeholder="Select Activity code"
      />
      </div>
    );
}

const renderTK=(value, tableMeta, updateValue) => {
  const lineItemType = tableMeta?.currentTableData[tableMeta?.rowIndex].data[2];
  const lineItemUtbms = tableMeta?.currentTableData[tableMeta?.rowIndex].data[3];
  const selectedLineItemType = ref.current[tableMeta?.rowIndex]?.type?.toLowerCase();
  return lineItemDiscountTypes.includes(selectedLineItemType) ? <>-</> :(
    <div className={classes.paddingTop}>
    <InvoiceTimekeeperAutocomplete
      name="tk"
      value={value}
      setValue={updateValue}
      tableMeta={tableMeta}
      handleBlur={onBlurForItemLeaveAutocomplete}
      placeholder="Select TK Id"
      helperText={
          ((value === null || value === '' || isEmptyObj(value))
          && isTkidMandatory(lineItemType, lineItemUtbms))
          ? FIELD_REQUIRED : ""}
    />
    </div>
  );
}

const renderUnits=(value, tableMeta, updateValue) => {
  const selectedLineItemType = ref.current[tableMeta?.rowIndex]?.type?.toLowerCase();
  return lineItemDiscountTypes.includes(selectedLineItemType) ? <>{value}</> : (
        <MuiTextField
            onChange={(event) => itemNameChange(event, updateValue)}
            onKeyUp={(event) => onBlurForItemLeave(event, tableMeta)}
            onKeyDown={(e) => onInputNumberKeyDown(e)}
            onClick={(event) => itemNameClick(event)}
            type="number"
            value={value}
            data-testid="units-text-field"
            className={classes.tableNumericCell}
            InputProps={{ classes: { input: classes.inputStyle }  }}
            name="units"
            id="units"
            error={Number(value) <= 0}
            // helperText={Number(value) <= 0 ? UNITS_VALIDATION_MESSAGE : ''}
        />
    );
}

const renderRate=(value, tableMeta, updateValue) => {
  const selectedLineItemType = ref.current[tableMeta?.rowIndex]?.type?.toLowerCase();
  return lineItemDiscountTypes.includes(selectedLineItemType) ? <>{value}</> :(
        <MuiTextField
            onChange={(event) => itemNameChange(event, updateValue)}
            onKeyUp={(event) => onBlurForItemLeave(event, tableMeta)}
            onClick={(event) => itemNameClick(event)}
            onKeyDown={(e) => onInputNumberKeyDown(e)}
            type="number"
            value={value}
            data-testid="rate-text-field"
            className={classes.tableNumericCell}
            name="rate"
            id="rate"
            InputProps={{ classes: { input: classes.inputStyle } }}
            error={Number(value) === 0}
            // helperText={Number(value) === 0  ? RATE_VALIDATION_MESSAGE : ''}
        />
    );
}

const renderDiscount=(value, tableMeta, updateValue) => {
  const selectedLineItemType = ref.current[tableMeta?.rowIndex]?.type?.toLowerCase();
  let errorFlag = false;

  // Discount value should not make total invoice net amount 0 or less
  // Line item net amount could be any value as long as total invoice net amount is greater than 0
  const tot = ref.current?.reduce((accumulator, item) => {
    return accumulator + item?.net;
  }, 0);
  errorFlag = Number(Number(value).toFixed(2)) >= Number((Number(tot) + Number(ref.current[tableMeta?.rowIndex]?.discount)).toFixed(2));

  errorFlag = errorFlag && Number(value) !== 0

  // Mark discount field as error if value is not entered in discount line item type
  if(lineItemDiscountTypes.includes(selectedLineItemType)) {
    errorFlag = errorFlag || Number(value) <= 0
  }
  return featureMap?.[FEATURES?.INVOICE_LINE_ITEM_DISCOUNT] ? (
    <MuiTextField
      onChange={(event) => itemNameChange(event, updateValue)}
      onKeyUp={(event) => onBlurForItemLeave(event, tableMeta)}
      onClick={(event) => itemNameClick(event)}
      onKeyDown={(e) => onInputNumberKeyDown(e)}
      type="number"
      value={value}
      data-testid="discount-text-field"
      className={classes.tableNumericCell}
      name="discount"
      id="discount"
      error={errorFlag || Number(value) < 0}
      InputProps={{ classes: { input: classes.inputStyle } }}
      onWheel={(e) => { e.target.blur() }}
    />
  ) : <div>{value}</div>
}

const renderAdjustment=(value, tableMeta, updateValue) => {
  return !tableMeta?.rowData[13] ? (
      <Caption as="p" className={classes.adjustmentItem}
      >
          {"0"}
      </Caption>
  ) : (
      <>0</>
  );
}

const renderNet = (value, tableMeta, updateValue) => {
  return (
      <Caption as="p" className={classes.netItem}>
          {parseFloat(value).toFixed(2)}
      </Caption>
  )
}

const renderActions=(rowData, value, tableMeta) => {
  return (
      <div className={classes.actionsItem}>
          <IconButton
              a11yLabel="Delete"
              size="small"
              data-testid="doc-delete"
              onClick={() => handleDeleteRow(value)}
          >
              <TrashCan size="small" />
          </IconButton>
      </div>
  );
}

const renderEdit=(value, tableMeta) => {
  return <div className={classes.wd120}>{value}</div>;
}
    const COLUMNS = [
        {
            name: "serialNo",
            label: "#",
            colKey: "serialNo",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "52px", minWidth: "52px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "52px", minWidth: "52px" } }),
                customBodyRender: renderSerailNo,
            },
        },
        {
            name: "description",
            label: DESCRIPTION,
            colKey: "description",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "150px", minWidth: "150px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "150px", minWidth: "150px" } }),
                customBodyRender: renderDescription,
            },
        },
        {
          name: "type",
          label: "Type",
          colKey: "type",
          options: {
              sort: true,
              setCellProps: () => ({ style: { maxWidth: "140px", minWidth: "140px" } }),
              setCellHeaderProps: () => ({ style: { maxWidth: "140px", minWidth: "140px" } }),
              customBodyRender: renderType,
            },
        },
        {
            name: "utbms",
            label: "UTBMS",
            colKey: "utbms",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "210px", minWidth: "210px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "210px", minWidth: "210px" } }),
                customBodyRender: renderUTBMS,
            },
        },
        {
            name: "act",
            label: "ACT",
            colKey: "act",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "210px", minWidth: "210px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "210px", minWidth: "210px" } }),
                customBodyRender: renderACT,
            },
        },
        {
            name: "tk",
            label: "TK",
            colKey: "tk",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "150px", minWidth: "150px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "150px", minWidth: "150px" } }),
                customBodyRender: renderTK,
            },
        },
        {
            name: "units",
            label: "Units",
            colKey: "units",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "110px", minWidth: "110px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "110px", minWidth: "110px" } }),
                customBodyRender: renderUnits,
            },
        },
        {
            name: "rate",
            label: "$ Rate",
            colKey: "rate",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "110px", minWidth: "110px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "110px", minWidth: "110px" } }),
                customBodyRender: renderRate,
            },
        },
        {
            name: "discount",
            label: "$ Discount",
            colKey: "discount",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "110px", minWidth: "110px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "110px", minWidth: "110px" } }),
                customBodyRender: renderDiscount,
            },
        },
        {
            name: "adjustment",
            label: "$ Adjustment",
            colKey: "adjustment",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "108px", minWidth: "108px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "108px", minWidth: "108px" } }),
                customBodyRender: renderAdjustment,
            },
        },
        {
            name: "net",
            label: "$ Net",
            colKey: "net",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "64px", minWidth: "64px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "64px", minWidth: "64px" } }),
                customBodyRender: renderNet,
            },
        },
        {
            name: "date",
            label: "Date",
            colKey: "date",
            options: {
                sort: true,
                setCellProps: () => ({ style: { maxWidth: "140px", minWidth: "140px" } }),
                setCellHeaderProps: () => ({ style: { maxWidth: "140px", minWidth: "140px" } }),
            },
        },
        {
            name: "actions",
            label: "Actions",
            colKey: "actions",
            options: {
                sort: true,
                customBodyRender: renderActions,
            },
        },
        {
            name: "edit",
            label: "edit",
            colKey: "edit",
            options: {
                display: false,
                customBodyRender: renderEdit,
            },
        },
    ];

    const [invoiceColumns, setInvoiceColumns] = useState(COLUMNS);

		useEffect(()=>{
			setInvoiceColumns(COLUMNS)
		},[masterDetails?.lineItemType])

    const initialData = {
        invoiceNumber: "",
        invoiceDate: moment(new Date())?.format(DATE_YYYY_MM_DD),
        receivedDate: moment(new Date())?.format(DATE_YYYY_MM_DD),
        startDate: moment(new Date())?.format(DATE_YYYY_MM_DD),
        endDate: moment(new Date())?.format(DATE_YYYY_MM_DD),
        matter: fromMatter
            ? {
                matterName: matterDetail?.matter?.matterName,
                referenceKey: matterDetail?.matter?.matterNumber,
            }
            : null,
        matterId: fromMatter ? matterDetail?.matter?.identifier : "matterId",
        ledes: ""
    };

    const [title] = useState({ ...Title });
    const [data, setData] = useState({ ...initialData });
    const classes = InvoiceViewStyles();
    const history = useHistory();
    const { addSnack } = useSnackbar();
    const [invoiceDataTableData, setInvoiceDataTableData] = useState([]);

    const ref = useRef();
    ref.current = invoiceDataTableData;

    useEffect(() => {
        if (invoiceId === "0") {
            setMode(modes.CREATE);
        } else {
            setMode(modes.EDIT);
        }
    }, [data, invoiceId]);

    useEffect(() => {
        if (mode === "edit") {
            getBudgetDetail();
        }
    }, [mode]);
    /**
   * This function is called when api call throws an error
   */
    const handleError = (error) => {
        setLoading(false);
        addSnack({
            message: getErrorMessage(error)
        });
    }
    /**
     * Function to call Budget Service
     */
    const getBudgetDetail = () => {
        const budgetTasksData = [];
        getService(BUDGET_SERVICE, `/budgets/v1/${invoiceId}`)
            .then((res) => {
                const detail = res?.data;
                const matter = {
                    matterName: detail?.matterName,
                    referenceKey: detail?.matterNumber,
                };
                const organization = {
                    organizationName: detail?.organizationName,
                    referenceKey: detail?.organizationId,
                };
                setData(
                    Object.assign(
                        {},
                        {
                            ...data,
                            ...detail,
                            matter,
                            organization,
                            budgetType: detail?.type,
                        },
                        ...budgetTasksData
                    )
                );
                setLoading(false);
            })
            .catch((error) => {
                handleError(error)
            });
    };

    const handleInputChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    useEffect(() => {
        if (data?.endDate || data?.startDate) {
            if ((new Date(data?.startDate) > new Date(data?.endDate))) {
                setData({ ...data, startDate: data?.startDate });
            }
        }
    }, [data?.startDate])

    useEffect(() => {
        if ((moment(data?.endDate)).diff(moment(data?.startDate),'days') < 0 ) {
            setError('endDate', { type: 'custom', message: START_END_DATE_VALIDATION_MESSAGE });
        }else if(moment(data?.endDate) >moment(new Date())){
            setError('endDate', { type: 'custom', message: INVALID_DATE_RANGE_MESSAGE });
        }
        else{
            clearErrors('endDate')
        }
    }, [data?.endDate, data?.startDate])

    useEffect(() => {
        if (viewTable) {
            const dateColumnIndex = invoiceColumns?.findIndex(x => x?.colKey === 'date');
            const invoiceColumnsClone = invoiceColumns?.map(a => ({ ...a }));
            invoiceColumnsClone[dateColumnIndex].options.customBodyRender = (value, tableMeta, updateValue) => {
                return (
              <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                value={renderTernary(value, moment(value), null)}
                onChange={(e) => {
                  itemNameChange({target : {value: e }}, updateValue)
                  onBlurForItemLeave({target : {value: e, name: "date" }}, tableMeta)
                }}
                onBlur={(event) => onBlurForItemLeave({target : {value: event, name: "date"  }}, tableMeta)}
                slotProps={{inputAdornment:{position:"end"},
                popper: {
                    sx: {
                      ".MuiPickersArrowSwitcher-root":
                      {
                        display: "-webkit-box",
                      },
                      ".MuiPickersDay-root":{
                        paddingLeft: "0px !important"
                      }
                    },
                  },
                textField:{helperText:errors?.[`data${tableMeta?.rowIndex}`]?.message,"data-testid":"date-text-field", id:"column-date",placeholder:"Select Date",name:"date",
                required: true,
                error:(isEmpty(value) || Object.keys(errors).length > 0 )
                }}}
                minDate={renderTernary(data?.startDate, moment(data?.startDate), moment(new Date()))}
                maxDate={renderTernary(data?.endDate, moment(data?.endDate), moment(new Date()))}
                onError={(err) => {
                    handleDateError(err,`data${tableMeta?.rowIndex}`)
                  }}
              />
            </LocalizationProvider>
            );
            }
            setInvoiceColumns(invoiceColumnsClone);
        }
    }, [viewTable, data?.startDate, data?.endDate])

    // Get line item type from description value from master data
    const getLineItemType = (typeDesc='') => {
      const typeValue = masterDetails?.lineItemType?.masterData?.find(item => item?.description.toLowerCase() === typeDesc)?.value.toLowerCase() || '';
      return typeValue;
    }

    const onCreateHandler = () => {
        setLoading(true);
        if (isValid) {
            const arr = invoiceDataTableData?.map((row) => {
                const obj = {};
                obj["lineItemType"] = getLineItemType(row?.type);
                obj["lineItemDate"] = convertToUTCDateTime({date: row?.date, time: DATE_START_TIME});
                obj["description"] = row?.description;
                obj["utbmsCode"] = row?.utbms ? JSON.parse(row?.utbms)?.value : null;
                obj["activityCode"] = row?.act ? JSON.parse(row?.act)?.value : null;
                obj["tkId"] = row?.tk?.tkId;
                obj["timekeeperUserId"] = row?.tk?.userId;
                obj["timekeeperEmail"] = row?.tk?.emailId;
                obj["timekeeperName"] = `${row?.tk?.firstName ?? ''} ${row?.tk?.lastName ?? ''}`.trim();
                obj["adjustmentType"] = "";
                obj["adjustmentValue"] = row?.adjustment ? row?.adjustment : 0;
                obj["discountType"] = "";
                obj["discountValue"] = row?.discount ? row?.discount * -1 : 0;
                obj["rate"] = row?.rate;
                obj["unit"] = row?.units;
                return obj;
            })
            const body = {
                organizationInvoiceNumber: data?.invoiceNumber,
                invoiceDate: convertToUTCDateTime({date: data.invoiceDate, time: DATE_START_TIME}),
                receivedDate: convertToUTCDateTime({date: data.receivedDate, time: DATE_START_TIME}),
                startDate: convertToUTCDateTime({date: data.startDate, time: DATE_START_TIME}),
                endDate: convertToUTCDateTime({date: data.endDate, time: DATE_END_TIME}),
                invoiceType: "Invoice", //to be read from invoice type dropdown field
                organizationId: identifier,
                organizationName: companyName,
                isWireFirm: wIREFirm === 'Yes',
                orgHostId: hostId,
                matterId: fromMatter
                    ? matterDetail?.matter?.identifier
                    : data?.matter?.matterId,
                matterNumber: fromMatter
                    ? matterDetail?.matter?.matterNumber
                    : data?.matter?.referenceKey,
                matterName: fromMatter
                    ? matterDetail?.matter?.matterName
                    : data?.matter?.matterName,
                practiceAreaCode: fromMatter
                    ? practiceAreaCode
                    : data?.matter?.practiceAreaCode,
                hasAttachment: false,
                lineItems: arr
            };
            const filteredBody=filterPayload(body)
            postService(INVOICE_SERVICE, `/invoices/v1`, filteredBody)
                .then((res) => {
                    if (fromMatter) {
                        history.push(`/invoice/${res.data?.invoiceId}`, {
                            fromMatter: true,
                            currentMatterTab: currentMatterTab,
                            matterNumber: matterDetail?.matter?.matterNumber,
                        })
                    } else {
                        history.push(`/invoice/${res.data?.invoiceId}`, { budgetType: data?.budgetType })
                    }
                    setLoading(false);
                    addSnack({
                        message: INVOICE_SAVE_MESSAGE,
                    });
                })
                .catch((error) => {
                    handleError(error)
                });
        }
    };

    /**
     * function to handle on blur of autocomplete fields - UTBMS & Activity code
     * @param {*} column
     * @param {*} tableMeta
     * @param {*} value
     */
    const onBlurForItemLeaveAutocomplete = (column, tableMeta, value=null) => {
      const { rowIndex } = tableMeta;
      const out = ref.current?.map((row, idx) => {
        return idx === rowIndex ? { ...row, [column]: value } : row;
      });
      setInvoiceDataTableData(out);
    };

    /**
    * Event to handle table cell data change
    */
    const itemNameChange = (event, updateValue, rowIndex=null) => {
        const name = event.target.name;
        updateValue(event.target.value);
        if(name==='type') {
          const out = ref.current;

          if(lineItemDiscountTypes.includes(event.target.value)) {
            out[rowIndex].units = "1";
          } else {
            out[rowIndex].units = "0";
          }
          out[rowIndex] = {
            ...out[rowIndex],
            rate: "0",
            utbms: null,
            act: null,
            tk: null
          }
          setInvoiceDataTableData(out);
        }
    };

    /**
     * Event to update the tables datasource on leave
     */
    const onBlurForItemLeave = (event, tableMeta) => {
        const { rowIndex } = tableMeta;
        const column = event.target.name;
        const val = column === 'date' ? moment(event.target.value)?.format(BACKEND_DATE_FORMAT) : event.target.value;
        const out = ref.current?.map((row, idx) => {
            return idx === rowIndex ? { ...row, [column]: val } : row;
        });
        if (column === 'rate' || column === 'units' || column === 'discount') {
          out[rowIndex].net = (out[rowIndex]?.rate * out[rowIndex]?.units) - (out[rowIndex]?.discount ? out[rowIndex]?.discount : 0);
          out[rowIndex].netWithoutDiscount = out[rowIndex]?.rate * out[rowIndex]?.units;
        }
        setInvoiceDataTableData(out);
    };

    /**
     * Event to handle table cell click
     */
    const itemNameClick = (event) => {
        event.stopPropagation();
    };


  /**
   * Callback to handle when file is dropped in dropzone
   * @param {array} files
   */
  const handleFileUpload = (files) => {
    files?.map(async (file) => {
      setViewTable(true);
      if (file?.name?.includes(".xlsx")) {
        const data = await file?.arrayBuffer();
        const workbook = XLSX.read(data, {
          type: 'binary',
        });
        workbook.SheetNames.forEach(function(sheetName) {
          const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], {defval:""});

          let excelColumnHeaders = Object.keys(XL_row_object[0]) || [];

          const mandatoryColumnHeaderArray = INVOICE_LINE_ITEM_HEADERS_MANDATORY.map(value => value.toLowerCase());
          excelColumnHeaders = excelColumnHeaders.map(value => value.toLowerCase());
          let allPresent = mandatoryColumnHeaderArray.every(value => excelColumnHeaders.includes(value));

          // Check to find all the mandatory columns are defined in the excel
          if(allPresent) {
            const excelData = [INVOICE_LINE_ITEM_HEADERS];

            XL_row_object.forEach((obj) => {
              excelData.push(Object.values(obj))
            });

            transformData(excelData);
          } else {
            addSnack({ message: INVALID_LEDES_UPLOAD_DATA_FORMAT_MSG })
          }
        })
      } else {
        Papa.parse(file, {
          skipEmptyLines: true,
          complete: (results) => transformData(results?.data),
        });
      }
    });
  };

  /**
   * Function to handle transform the csv/xlsx data to array of objects
   * @param {array} data
   */
  const transformData = async (data) => {
    setLoading(true);
    const newArray = [];
    for(let idx=0; idx < data?.length; idx++) {
      const row = data[idx];
      if (idx > 0) {
        try{
        let eachObject = {};
        eachObject = data[0]?.reduce((obj, header, i) => {
          if(header?.toLowerCase() === "act") {
            const actObj = [...masterDetails?.activityCode?.masterData || ''];
            const filteredACTCode = actObj?.filter(act => act?.value === row[i])?.[0] || {};
            obj["act"] = Object.keys(filteredACTCode)?.length > 0 ? JSON.stringify(filteredACTCode) : null;
         }
        else if( header?.toLowerCase() === "net"){
            obj['net'] = Math.round((obj?.['units']*obj?.['rate']) - (obj['disc - amount (non mandatory)'] ? obj['disc - amount (non mandatory)'] : 0)).toFixed(2);
            obj['netWithoutDiscount'] = obj?.['units']*obj?.['rate'];
        } else if(header?.toLowerCase() === "date"){
            const inputDate = typeof row[i] === "string" ? row[i] : JSON.stringify(row[i]);
            const localDate = inputDate.length === 8 ? inputDate.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3') : "";
            obj['date'] = moment(localDate).format(BACKEND_DATE_FORMAT)
        } else {
            obj[header?.toLowerCase()] = row[i];
         }
        obj['discount'] = obj['disc - amount (non mandatory)'];
          return obj;
        }, {});
        eachObject["serialNo"] = invoiceDataTableData?.length + idx;

        /** Check for UTBMS code based on selected type */
        let utbmsObj = [];
        const selectedLineItemType = eachObject['type'].toLowerCase();
        if(selectedLineItemType === LINE_ITEM_TYPE_FEE || selectedLineItemType === LINE_ITEM_TYPE_EXPENSE) {
          utbmsObj = utbmsList[selectedLineItemType];
        }
        const filteredUTBMSCode = utbmsObj?.filter(utbms => utbms?.value === eachObject["utbms"])?.[0] || {};
        eachObject["utbms"] = Object.keys(filteredUTBMSCode)?.length > 0 ? JSON.stringify(filteredUTBMSCode) : null;

        // Fetch tk detail object using getPeopleData if not present in initially loaded list
        if(eachObject["tk"] !== "") {
          const tkObj = [...externalPeopleDropDownData?.nodeList || ''];
          const eachObjectTk = typeof eachObject["tk"] === "string" ? eachObject["tk"] : JSON.stringify(eachObject["tk"]);
          const filteredTkCode = tkObj?.filter(tk => tk?.tkId === eachObjectTk)?.[0] || {};
          if(Object.keys(filteredTkCode).length === 0 && externalPeopleDropDownData?.count > CS_TYPEAHEAD_LIMIT) {
            const filteredTkCodeFromAPI = await getPeopleData(eachObjectTk)
            eachObject["tk"] = filteredTkCodeFromAPI;
          } else {
            eachObject["tk"] = filteredTkCode
          }
        }

        eachObject["edit"] = true;
        newArray.push(eachObject)
      }catch(error){
        addSnack({ message: INVALID_LEDES_FORMAT_MSG })
      }
      }
    }
    addSnack({
        message: LEDES_FILE_PROCESSED_SUCCESS_MSG
      })
    setInvoiceDataTableData([
      ...invoiceDataTableData,
      ...newArray?.filter((row) => row ),
    ]);
    setLoading(false);
  };
    /**
     * Event to handle LEDES-textfield on Enter key pressed
     */
    const handleLEDTextKeyDown = async (key) => {
        if (key === "Enter") {
          setLoading(true);
          const multipleArr = data?.ledes?.trim().split("||");
          for (const dataElement of multipleArr) {
            const arr = dataElement?.trim().split("|");
            if (arr?.length % 2 === 0) {
              setViewTable(true);
              const half = Math.ceil(arr?.length / 2);
              const firsthalf = arr?.splice(0, half);
              const secondhalf = arr?.splice(-half);

              const finalRow = firsthalf.reduce((obj, key, idx) => {
                switch (key?.toLowerCase() ) {
                  case 'net': obj[key?.toLowerCase()] = (obj['units']*obj['rate']) - (obj['discount'] ? obj['discount'] : 0);
                    obj['netWithoutDiscount'] = obj['units']*obj['rate'];
                    break;
                  case 'date':
                    const inputDate = typeof secondhalf[idx] === "string" ? secondhalf[idx] : JSON.stringify(secondhalf[idx]);
                    const localDate = inputDate.length === 8 ? inputDate.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3') : "";
                    obj['date'] = moment(localDate).format(BACKEND_DATE_FORMAT)
                    break;
                  case 'act':
                    const actObj = [...masterDetails?.activityCode?.masterData || ''];
                    const filteredACTCode = actObj?.filter(act => act?.value === secondhalf[idx]?.trim())?.[0] || {};
                    obj["act"] = Object.keys(filteredACTCode)?.length > 0 ? JSON.stringify(filteredACTCode) : null;
                    break;
                  default:
                    obj[key?.toLowerCase()] = secondhalf[idx];
                }
                return obj;
              }, {});

              finalRow["serialNo"] = invoiceDataTableData?.length + 1;
              finalRow["edit"] = true;

              /** Check for UTBMS code based on selected type */
              let utbmsObj = [];
              const selectedLineItemType = finalRow['type'].toLowerCase();
              if(selectedLineItemType === LINE_ITEM_TYPE_FEE || selectedLineItemType === LINE_ITEM_TYPE_EXPENSE) {
                utbmsObj = utbmsList[selectedLineItemType];
              }
              const filteredUTBMSCode = utbmsObj?.filter(utbms => utbms?.value === finalRow["utbms"])?.[0] || {};
              finalRow["utbms"] = Object.keys(filteredUTBMSCode)?.length > 0 ? JSON.stringify(filteredUTBMSCode) : null;
              //   If TK is not entered
              if( finalRow["tk"] && finalRow["tk"]?.trim() !== "" ) {
                  // Fetch tk detail object using getPeopleData if not present in initially loaded list
                  const tkObj = [...externalPeopleDropDownData?.nodeList || ''];
                  const filteredTkCode = tkObj?.filter(tk => tk?.tkId === finalRow["tk"]?.trim())?.[0] || {};
                  if (Object.keys(filteredTkCode)?.length === 0) {
                      const filteredTkCodeFromAPI = await getPeopleData(finalRow["tk"]?.trim())
                      finalRow["tk"] = filteredTkCodeFromAPI;
                  } else {
                      finalRow["tk"] = filteredTkCode
                  }
              } else {
                  finalRow["tk"] = null;
              }

              invoiceDataTableData?.push(finalRow)
            }
            else {
              addSnack({ message: INVALID_LEDES_FORMAT_MSG })
            }
          }
          setInvoiceDataTableData([...invoiceDataTableData]);
          setData({ ...data, 'ledes': '' });
          setLoading(false);
        }
      };

    const handleAddNewLine = () => {
        setViewTable(true);
        const newLine = {
            serialNo: invoiceDataTableData?.length + 1,
            description: "",
            utbms: null,
            act: null,
            tk: null,
            units: 0,
            rate: 0,
            discount: 0,
            adjustment: 0,
            net: 0,
            date: "",
            type: null,
            edit: true,
        };
        setInvoiceDataTableData([...invoiceDataTableData, newLine]);
				setPage(Math.floor(invoiceDataTableData.length/rowsPerPage));
    };

  /*
  *Function to calculate total net amount of all the line items
  */
  const totalNetAmount = invoiceDataTableData?.reduce((accumulator, item) => {
    return accumulator + item?.net;
  }, 0);

    const handleDeleteRow = (value) => {
        let obj = ref?.current?.filter((item, idx) => idx !== value?.rowIndex);
        if (value?.rowIndex + 1 !== ref.current?.length) {
            obj = obj?.map((item, idx) => ({ ...item, serialNo: idx + 1 }));
        }
        setInvoiceDataTableData(obj);
    };


    const [isValid, setIsValid] = useState(false);
    const { pureAdminRole } = useSelector((state) => state?.user?.featureMap);

    /**
     *
     * @returns isValid flag for line items
     */
    const checkLineItemsValidity = () => {
        let result;
        for (let i = 0; i < invoiceDataTableData?.length; i++) {
            const { utbms, type, tk, rate, units, date, discount } = invoiceDataTableData[i]

            let conditionCheck = (Number(rate) <= 0 || Number(discount) < 0);
            let mandatoryFields = [utbms, rate, units, date, type]

            // For discount line item types, update mandatory fields and no check required for rate field
            if(lineItemDiscountTypes.includes(type?.toLowerCase())) {
              mandatoryFields = [type, rate, units, discount, date];
              conditionCheck = (Number(discount) <= 0);
            }

            // Return false if utbms, rate, units, date, type are not present
            // or if TKID is empty if tkid is required [if type is fee and utbms not start with AFA ]
            // or if date field has any error
            if(!isValueValidList(mandatoryFields)
                || (isTkidMandatory(type, utbms) && (isEmpty(tk) || tk === "{}" || isEmptyObj(tk)))
                || conditionCheck
                || date === "Invalid date"
            ) {
                result = false;
                break;
            }
            if (i===invoiceDataTableData?.length-1) {
              return true;
            }
        }
        return result;
    }

    useEffect(() => {
        if (mode === modes.CREATE) {
            setIsValid(
                !isEmpty(identifier)
                && (fromMatter? !isEmpty(matterDetail?.matter?.identifier) : !isEmpty(data?.matter?.matterId))
                && !isEmpty(data?.invoiceNumber)
                && checkLineItemsValidity()
                && invoiceDataTableData.some((item) => lineItemTypeMandatory.includes(item?.type?.toLowerCase()))
                && parseFloat(totalNetAmount).toFixed(2) > 0 // Net invoice amount displayed in UI should be greater than 0 to enable create btn
            );
        }
    }, [data, invoiceDataTableData]);

    const handleDateError = (err, dateType) => {
        const message = err==="invalidDate" ? INVALID_DATE_MESSAGE : INVALID_DATE_RANGE_MESSAGE
        if(err)
        {
          setError(dateType, { type: 'custom', message: message })
        }
        else{
          clearErrors(dateType)
        }
      };

		/** Function to call api on pagination action
		* @param {number} rowsPerPage
		* @param {number} page */
		const handleClientSidePagination = ({ rowsPerPage, page }) => {
			setRowsPerPage(rowsPerPage);
			setPage(page);
		};

		const invoiceLineItemCustomOptions = {
			serverSide: ENABLE_SERVER_SIDE_ADD_INVOICE_LINE_ITEM,
			rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_ADD_INVOICE_LINE_ITEM,
			page: page,
			rowsPerPage: rowsPerPage,
			onClientSidePagination: handleClientSidePagination
		}
    return (
        <div className={classes.container}>
            <Detail
                mode={mode}
                setMode={setMode}
                breadcrumbValues={[]}
                subject={title.edit}
                newTitle={title.create}
                onCreate={onCreateHandler}
                onCancel={() =>
                    fromMatter ? history.push(`/matter/${matterDetail?.matter?.identifier}`, {
                        currentMatterTab, currentFinancialTab
                    })
                        : history.push(`/financial`, { currentFinancialTab: currentFinancialTab })}
                loading={loading}
                isDirty={renderTernary(Object.keys(errors)?.length > 0,false,true)}
                className={classes.container}
                hideDeleteBtn={true}
                editBtnTxt={SUBMIT}
                cancelText={DISCARD}
                isValid={renderTernary(Object.keys(errors)?.length > 0, false, isValid)}
            >
                <Grid>
                    <GridColumn sm={12}>
                        <div className={classes.leftContent}>
                            <div
                                className={[classes.columnSpacing, classes.divBorder].join(" ")}
                                data-testid="invoiceView"
                            >
                                <Heading as="h3" size="large" className={classes.subTitle}>
                                    Invoice Details
                                </Heading>
                                <Grid>
                                    <GridColumn sm={12}>
                                        <Label text={'Invoice Number'} mandatoryField />
                                        <div className = {classes.invoiceNumber}>
                                        <TextField
                                            value={data?.invoiceNumber}
                                            error={errors?.invoiceNumber && <MuiValidationMessage message={errors?.invoiceNumber?.message} mode="LD"/>}
                                            data-testid="invoice_view-invoice-number"
                                            textFieldProps={{
                                                name: "invoiceNumber",
                                                id: "invoiceNumber",
                                            }}
                                            type="text"
                                            {...register(
                                                'invoiceNumber',
                                                {...invoiceValidationRules.invoiceNumber, onChange: (e) => {handleInputChange(e.target.name, e.target.value)}}
                                            )}
                                        />
                                        </div>
                                        </GridColumn>
                                        <GridColumn sm={6}className={[classes.spacing16,classes.gridcolumnLeft].join(" ")} >
                                          <div >
                                            <Label text={INVOICE_DATE} mandatoryField/>
                                            <div className={classes.contentSpacing8} >
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                sx={{width:"100%"}}
                                                required={true}
                                                value={renderTernary(data?.invoiceDate ,moment(data?.invoiceDate), null)}
                                                onChange={(e) => {
                                                handleInputChange("invoiceDate", moment(e)?.format(BACKEND_DATE_FORMAT));
                                                }}
                                                maxDate={moment(new Date())}
                                                onError={(err) => {
                                                    handleDateError(err,'invoiceDate')
                                                }
                                                }
                                                slotProps={{inputAdornment:{position:"start"},popper:{placement:BOTTOM_START,popperOptions:BOTTOM_START},
                                                textField:{helperText:errors?.invoiceDate?.message,placeholder:"Select Date","data-testid":"invoice_view-invoice-date"}}}
                                            />
                                            </LocalizationProvider>
                                            </div>
                                          </div>
                                        </GridColumn>
                                        <GridColumn sm={6} className={[classes.spacing16,classes.gridcolumnRight].join(" ")}>
                                        <Grid>
                                          <GridColumn sm={6} className={classes.gridcolumnLeft}>
                                            <Label text={CREATE_DATE} mandatoryField/>
                                            <div className={classes.contentSpacing8} >
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                sx={{width:"100%"}}
                                                disabled={true}
                                                value={moment(new Date())}
                                                slotProps={{inputAdornment:{position:"start"},popper:{placement:BOTTOM_START,popperOptions:BOTTOM_START},
                                                textField:{helperText:errors?.invoiceDate?.message,placeholder:"Select Date","data-testid":"invoice_view-create-date"}}}
                                            />
                                            </LocalizationProvider>
                                            </div>
                                          </GridColumn>
                                          <GridColumn sm={6} className={classes.gridcolumnRight}>
                                            <Label text={RECEIVED_DATE} mandatoryField />
                                            <div className={classes.contentSpacing8} >
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                sx={{width:"100%"}}
                                                disabled={true}
                                                value={moment(new Date())}
                                                slotProps={{inputAdornment:{position:"start"},popper:{placement:BOTTOM_START,popperOptions:BOTTOM_START},
                                                textField:{helperText:errors?.invoiceDate?.message,placeholder:"Select Date","data-testid":"invoice_view-received-date",id:"invoice_view-received-date"}}}
                                            />
                                            </LocalizationProvider>
                                            </div>
                                          </GridColumn>
                                        </Grid>
                                        </GridColumn>
                                    </Grid>
                                    <div className={classes.contentSpacing16}>
                                        <Divider color="#D3D3D3" />
                                    </div>
                                <div
                                    className={classes.contentSpacing24}
                                    data-testid="timePeriod"
                                >
                                    <Grid>
                                        <GridColumn lg={6} className={classes.gridcolumnLeft}>
                                            <Label text={'Start Date'} mandatoryField/>
                                            <div className={classes.contentSpacing8} >
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    sx={{width:"100%"}}
                                                    required={true}
                                                    value={renderTernary(data?.startDate ,moment(data?.startDate), null)}
                                                    onChange={(e) => {
                                                    handleInputChange(
                                                        "startDate",
                                                        moment(e)?.format(BACKEND_DATE_FORMAT)
                                                    );
                                                    }}
                                                    maxDate={moment(new Date())}
                                                    onError={(err) => {
                                                    handleDateError(err,'startDate')
                                                    }}
                                                    slotProps={{inputAdornment:{position:"start"},
                                                    textField:{helperText:errors?.startDate?.message,placeholder:"Select Date","data-testid":"invoice_view-start-date",
                                                    id:"invoice_view-start-date"}}}
                                                />
                                                </LocalizationProvider>
                                            </div>
                                        </GridColumn>
                                        <GridColumn lg={6} className={classes.gridcolumnRight}>
                                            <Label text={'End Date'} mandatoryField/>
                                            <div className={classes.contentSpacing8} >
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                sx={{width:"100%"}}
                                                value={renderTernary(data?.endDate ,moment(data?.endDate), null)}
                                                onChange={(e) => {
                                                handleInputChange(
                                                    "endDate",
                                                    moment(e)?.format(BACKEND_DATE_FORMAT)
                                                );
                                                }}
                                                minDate={renderTernary(data?.startDate, moment(data?.startDate), moment(new Date()))}
                                                maxDate={moment(new Date())}
                                                slotProps={{inputAdornment:{position:"start"},
                                                textField:{helperText:errors?.endDate?.message,
                                                placeholder:"Select Date","data-testid":"invoice_view-end-date",
                                                id:"invoice_view-end-date"}}}
                                            />
                                            </LocalizationProvider>
                                        </div>
                                        </GridColumn>
                                    </Grid>
                                </div>
                                <div className={classes.contentSpacing16}>
                                    <Divider color="#D3D3D3" />
                                </div>
                                <Grid className={classes.contentSpacing24}>
                                    <GridColumn lg={6} className={classes.gridcolumnLeft}>
                                        <div >
                                        <Label text={ORGANIZATION} mandatoryField/>
                                        <div className={classes.contentSpacing24}>
                                            {companyName}
                                        </div>
                                        </div>
                                    </GridColumn>
                                    <GridColumn lg={6} className={classes.gridcolumnRight}>
                                        <Label text={MATTER} mandatoryField/>
                                        <MatterAutocomplete
                                            placeholder={"Select a Matter"}
                                            value={data?.matter}
                                            setValue={(value) => handleInputChange("matter", value)}
                                            data-testid="invoice-matter"
                                            id="matter-autocomplete"
                                            disabled={fromMatter || mode === "edit"}
                                            register={register}
                                            errors={errors}
                                            name={"matter"}
                                            validationRules={invoiceValidationRules}
                                            isRestricted = {false}
                                            fetchAllSensitiveMatters = {true}
                                        />
                                    </GridColumn>
                                </Grid>
                            </div>
                            <div
                                className={[classes.columnSpacing, classes.divBorder].join(" ")}
                                data-testid="invoiceView"
                            >
                                <Heading
                                    as="h3"
                                    size="large"
                                    className={classes.subTitleLineItems}
                                >
                                  Line Items <StyledText
                                    color="gray"
                                    size="large"
                                  >
                                    {LINE_ITEMS_INFO_MSG}
                                  </StyledText>
                                </Heading>
                                <div className={!featureMap?.bulkJob? classes.divider: classes.divideLine}>
                                    {!viewTable && (<Divider color="#D3D3D3" />)}
                                </div>
                                {!featureMap?.bulkJob && <>
																	<GridColumn data-testid="document-dropzone">
																			<DocumentDropzone
																					key={0}
																					showFilterIcon={true}
																					dropZoneLabel={"Upload Line Items"}
																					acceptedFilesLabel={ LEDES_COMPATIBLE_FILE_MESSAGE }
																					acceptedFileTypes={acceptedExcelFilesForDocuments}
																					onFileUpload={handleFileUpload}
																					fileDropStatus={true}
																					dropzoneText=""
																					maxFileUploadSize={MAX_FILE_UPLOAD_SIZE_LINE_ITEMS}
																					fileUploadInfoMsg={MAX_FILE_UPLOAD_SIZE_MSG_LINE_ITEMS}
																					downloadLink = {LEDES_SAMPLE_FILE_LINK_MSG}
																			/>
																	</GridColumn>
																	<GridColumn className={classes.invoiceNoTxt}>
																			<Label text={'or Paste your compatible invoice file formatted line items'} />
																			<TextField
																					value={data?.ledes}
																					data-testid="invoice_view-LEDEs-98B"
																					onChange={(e) =>
																							handleInputChange(e.target.name, e.target.value)
																					}
																					onKeyDown={(e) => handleLEDTextKeyDown(e.key)}
																					textFieldProps={{
																							name: "ledes",
																					}}
																					type="text"
																			/>
																	</GridColumn>
																</> }
                                <div className={classes.lineItemsTableStyle}>
                                    {viewTable && (
                                        <MuiDataTable
                                            data={invoiceDataTableData}
                                            columns={invoiceColumns}
                                            loading={loading}
                                            showRowsCounter={false}
                                            disableSearch={true}
                                            disableExport={true}
                                            disableFilter={true}
                                            showCustomAddNewButton={true}
                                            customOptions={invoiceLineItemCustomOptions}
                                            customAddNewButton={<></>}
                                            customClassName={"createLineItemTable"}
                                        />
                                    )}
                                    <Button
                                        className={classes.lineItemBtn}
                                        variant="tertiary"
                                        onClick={handleAddNewLine}
                                        data-testid="invoice_view-addLine-item"
                                        id="invoice_view-addLine-item"
                                        leading={<Plus />}
                                    >
                                      Add Line Item
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </GridColumn>
                </Grid>
					<div className={classes.totalNetAmountStyle}>
						<Grid>
							<GridColumn lg="11">
								<Heading as="h2" size="large" className={classes.subTitle}>
									{TOTAL_NET_AMOUNT}
								</Heading>
							</GridColumn>
							<GridColumn lg="1">
								<Heading as="h2" size="large" className={classes.subTitle}>
									{`$ ${parseFloat(totalNetAmount).toFixed(2)}`}
								</Heading>
							</GridColumn>
						</Grid>
					</div>
            </Detail>
        </div>
    );
};

export default InvoiceView;
