import React, { useEffect, useState } from "react";
import {useHistory,useParams,useLocation } from "react-router";
import {
  TabNavigation,
  TabNavigationItem,
  Grid,
  GridColumn,
  Heading,
  Body,
  useSnackbar,
  Breadcrumb,
  BreadcrumbItem,
  Skeleton,
  Spinner,
} from "@walmart-web/livingdesign-components";
import { createUseStyles } from "react-jss";
import { useSelector, useDispatch  } from "react-redux";
import Listing from "../Listing/Listing";
import NoteList from "../Note/NoteList";
import MatterDetails from "./Details/MatterDetails";
import MatterOverview from "./Overview/MatterOverview";
import MatterFinancials from "./Financial/MatterFinancials";
import MatterFiles from "./Files/MatterFiles";
import { setMatterDetail } from "../../store/actions/matterAction";
import { setDocumentBreadcrumbIndex, setDocumentsMattersDetails } from "../../store/actions/documentActions";
import { getErrorMessage, getMatterData, getSensitiveMatterPayload, getTabList, renderTernary, useUserAssociatedRelation } from '../../helpers/utils';
import { NO, SENSITIVE, YES } from "../../constants/constants";
import { displayLocalDate } from "../../helpers/utcUtils";
import { UNACCESSIBLE_MATTER_MSG } from "../../constants/messages";


const useStyles = createUseStyles({
  detailWrapper: {
    padding: "15px",
  },
  detailItem: {
    display: "inline-flex",
    flexDirection: "column",
    paddingRight: "62px",
  },
  detailItemStatus: {
    display: "inline-flex",
    flexDirection: "column",
    paddingRight: "62px",
    gap: "4px",
  },
  detail: {
    "& h5": {
      fontSize: "18px",
    },
  },
  matterTabNavigation: {
    "& nav > ul > li > a": {
      background: "none",
    },
  },
  star: {
    marginRight: "24px",
    height: "24px",
    width: "24px",
  },
  starFilled: {
    marginRight: "14px",
    height: "44px",
    width: "44px",
  },
  icon: {
    cursor: "pointer",
  },
  matterActionButtons: {
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  matterSubtitle: {
    paddingLeft: 15,
  },
  breadcrumb: {
    cursor: "pointer",
    padding: "24px 0 0 32px"
  },
  openStatusButton: {
    border: "1px solid #1d5f02",
    color: "#1d5f02",
    background: "#fff",
    fontSize: "11px",
    padding: "4px 10px 3px",
    borderRadius: "2px",
  },
  closedStatusButton: {
    border: "1px solid #8E2421",
    color: "#8E2421",
    background: "#fff",
    fontSize: "11px",
    padding: "4px 10px 3px",
    borderRadius: "2px",
  },
  matterContent: {
    position: 'relative',
    minHeight: '300px',
      "& .MuiAccordionDetails-root": {
        padding: "20px !important"
      },
    "& p": {
      fontSize: '14px !important'
    }
  },
  center: {
    position: "absolute",
    top: "30%",
    left: "50%",
  },
  disableTabs: {
    opacity: 0.5,
    pointerEvents: "none"
  }
});

const MatterSummary = (props) => {
  const {matterDetail, loading, sensitive} = props
  const classes = useStyles();
  return (
    <div className={classes.detailWrapper}>
      <Grid style={{ flex: "1" }}>
        <GridColumn sm={12} md={12} lg={10}>
          <div className={classes.detailItem}>
            <Body as="p" size="small" weight={400}>
              Matter Status
            </Body>
            <div className={classes.detail}>
              { loading ?
                <Skeleton height="12px" variant="rectangle" width="100px"/> :
                <span id="matter-detail-status">
                  {
                    renderTernary(
                      (matterDetail?.matter?.status?.toLowerCase() === "open"),
                      (<span className={classes.openStatusButton}> {(matterDetail?.matter?.status  || "-")} </span>),
                      (<span className={classes.closedStatusButton}> {(matterDetail?.matter?.status  || "-")} </span>)
                    )
                  }
                </span>
              }
            </div>
          </div>
          <div className={classes.detailItem}>
            <Body as="p" size="small" weight={400}>
              Matter Type
            </Body>
            <div className={classes.detail}>
              <Heading as="h1" size="small" weight={700} id="matter-detail-type">
                { loading ?
                  <Skeleton height="12px" variant="rectangle" width="100px"/> :
                  (matterDetail?.matter?.matterType || "-")
                }
              </Heading>
            </div>
          </div>
          <div className={classes.detailItem}>
            <Body as="p" size="small" weight={400}>
              Create Date
            </Body>
            <div className={classes.detail}>
              <Heading as="h1" size="small" weight={700} id="matter-detail-created-date">
                { loading ?
                  <Skeleton height="12px" variant="rectangle" width="100px"/> :
                  (displayLocalDate(matterDetail?.matter?.createdTs) || "-")
                }
              </Heading>
            </div>
          </div>
          <div className={classes.detailItem}>
            <Body as="p" size="small" weight={400}>
              {SENSITIVE}
            </Body>
            <div className={classes.detail}>
              <Heading as="h5" size="small" id={"matter-sensitive"}>
              {loading ?
                <Skeleton height="12px" variant="rectangle" width="100px"/> :
                (sensitive)
              }
              </Heading>
            </div>
          </div>
        </GridColumn>
      </Grid>
    </div>
  );
};

const MatterDetail = () => {
  const classes = useStyles();
  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { state: { currentMatterTab: currentTab} = {} } = useLocation();
  const breadCrumbFolders = useSelector((state) => state?.document?.breadCrumbFolders);
  const { matterDetail } = useSelector((state) => state?.matter);
  const { matterId } = useParams();
  const sensitive = matterDetail?.matter?.isSensitive ? YES : NO;
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters?.details?.tabs);
  const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const {listOfTabs, tabIndexMap} = getTabList(accessibility);
  const [isCurrent, setIsCurrent] = useState(currentTab ? currentTab : tabIndexMap[listOfTabs[0]]);
  const matterAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters);
  const relations = useUserAssociatedRelation(matterAccessibility);
  const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
  
  const tabContent = () => {
    switch (isCurrent) {
      case 0:
        return <MatterOverview />;
      case 1:
        return <MatterDetails />;
      case 2:
        return <MatterFiles />
      case 3:
        return <MatterFinancials currentMatterTab={isCurrent} />;
      case 4:
        return <NoteList currentMatterTab={isCurrent}/>;
      default:
        return null;
    }
  };
  useEffect(() => {
    matterId && loadMatterData(matterId);
	},[]);

  const loadMatterData  = (id) => {
    setLoading(true);
    const userRelation = {
      "filters": relations,
      "operation": "AND",
      "properties": null
    }
    let sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
    getMatterData(id,orgIdentifier, userRelation, sensitiveMatterQuery)
      .then((res)=>{
        if(res?.data?.result?.count > 0)
        {
          dispatch(setDocumentsMattersDetails(res.data.result?.matter));
          dispatch(setMatterDetail(res.data.result));
        }
        else{
          history.push("/")
          addSnack({
            message: UNACCESSIBLE_MATTER_MSG,
          });
        }
        setLoading(false);
      })
      .catch((error)=>{
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        })
      })
  }

  const handleFolderBreadCrumbs = (index) => {
    dispatch(setDocumentBreadcrumbIndex(index));
  }

  const handleMatterBreadcrumb = () => {
    if(isCurrent === 2) {
      dispatch(setDocumentBreadcrumbIndex("RESET"));
    }
    history.push(`/matter/${matterDetail?.matter?.identifier}`)
  }

  return (
    <div >
         <div className={classes.breadcrumb}>
        <Breadcrumb>
          <BreadcrumbItem
            data-testid="matter-breadcrumb"
             onClick={()=>history.push("/")}>
            Matter
          </BreadcrumbItem>
          <BreadcrumbItem
            onClick={()=> handleMatterBreadcrumb()} isCurrent data-testid={`matterbreadCrumb`}>
            {matterDetail?.matter?.matterNumber}
          </BreadcrumbItem>
          {breadCrumbFolders?.length !== 0 &&
            <Breadcrumb >
              {isCurrent === 2 && breadCrumbFolders?.map((item, idx) => {
                return <BreadcrumbItem key={`${item?.label}`} onClick={() => handleFolderBreadCrumbs(idx)} data-testid={`breadCrumbFolders-${idx}`}>{item?.label}</BreadcrumbItem>;
              })}
            </Breadcrumb>
          }
        </Breadcrumb>
      </div>
    <Listing
      id="matterPageTitle"
      title={loading ?
        <Skeleton height="12px" variant="rectangle" width="100px"/> :
        (matterDetail?.matter?.matterName || "-")
      }
      subtitleId={"matter-detail-name"}
      tag={false}
      matterDetail = {true}
    >
      {/** No relavant detail in API */}
      {/* <div className={classes.matterSubtitle}>
        <Heading as="h1" size="small" weight={700}>
          Walmart v. Jones, 565 U.S. 400, 132 S.Ct. 945
        </Heading>
      </div> */}
      <MatterSummary matterDetail={matterDetail} loading={loading} sensitive={sensitive}/>
      <div className={classes.matterTabNavigation}>
        <TabNavigation>
          {listOfTabs?.map((item, index) => (
            <TabNavigationItem
              id={`matter-detail-tab-${index}`}
              data-testid={`matter-detail-tab-${tabIndexMap[item]}`}
              onClick={() => setIsCurrent(tabIndexMap[item])}
              isCurrent={isCurrent === tabIndexMap[item]}
              key={tabIndexMap[item]}
              UNSAFE_className={loading ? classes.disableTabs: ""}
            >
              {item}
            </TabNavigationItem>
          ))}
        </TabNavigation>
      </div>
      <div data-testid="matter-detail-content" className={classes.matterContent}>
        { loading ?
          <Spinner size='small' className={classes.center} /> :
          tabContent()
        }
        </div>
    </Listing>
    </div>
  );
};

export default MatterDetail;
