import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import FilterButton from "./FilterButton/FilterButton";
import FilterPanel from "./FilterPanel/FilterPanel";
import { isEmptyObj, formSearchQuery, documentFilterConfig } from "../../../helpers/utils";
import { Operations } from "../../../axios/operations";
import { formCsFilterQuery, formPeopleCsFilterQuery, formBudgetFilterQuery } from "../../../helpers/csQueryUtils";
import { useSelector } from "react-redux";

const useStyles = createUseStyles(() => ({
  buttonComponentWrap: {
    "@media (min-width: 740px)": {
      borderRight: "1px solid #f1f1f2",
      paddingRight: "0.8rem",
      marginRight: "1rem"
    },
  }
}))

/**
 * Component to add Filter button and its panel
 *
 * @visibleName Filter
 */
const Filter = ({columns, setTableColumns, filterConfigs, onServerSideFilter, serverSide, groupFilter, isMatter, isDocument, isPeople,isInvoice, isBudget, onlyFilter}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterInput, setFilterInput] = useState({});
  const classes = useStyles();
  const practiceAreaNameToCode = useSelector(state => state?.matter?.practiceAreaNameToCode);

  /**
   * Function to set filter criterias to the call back function passed from parent
   *
   * @param {*} value Holds the value of filtered items
   */
  const onFilter = (value) => {
    const filteredCols = columns.map(item => {
      if (value === 'All') {
        item.options.filterList = [];
      } else {
        const colKeyValue = value[item?.colKey];
        if (colKeyValue === undefined) {
          item.options.filterList = [];
        } else {
          if (item?.colKey === 'createdTs' && value['startDate'] && value['endDate']) {
            item.options.filterList.splice(0, 0, value['startDate'], value['endDate']);
          } else {
            item.options.filterList = colKeyValue;
            const objKey = `${item.colKey}-obj`;
            value[objKey] && (item.options.obj = value[objKey]);
          }
        }
      }
      return item;
    });

    setTableColumns(filteredCols);
  };

  /**
   * Function to call Filter Api
   *
   * @param {*} value Holds the value of filtered items
   */
   const onFilterServerSide = (value) => {
    if(isDocument){
      const {documentQuery} = documentFilterConfig(value);
      onServerSideFilter(documentQuery);
    }
    else if(isMatter){
      const filterQuery = formCsFilterQuery(practiceAreaNameToCode, value);
      onServerSideFilter(filterQuery);
    }
    else if(isPeople || isInvoice){
      const filterQuery = formPeopleCsFilterQuery(value);
      onServerSideFilter(filterQuery);
    }
    else if(isBudget) {
      const filterQuery = formBudgetFilterQuery(value);
      onServerSideFilter(filterQuery);
    }
    else {
    let query = '';
    Object.keys(value).forEach(function(key) {
      if(value[key]?.length > 0) {
        if(key === 'taskAssignees') {
          value[key]?.map( (name, index) => {
             if(query !== '') {
              if(index > 0){
                query += ' or '
               }else{
                query += ' and '
               }
            }
            query += formSearchQuery(key, Operations.EQ, name, 'name');
          })
        } else if(key === 'tags') {
          query += formSearchQuery(key, Operations.LIKEIC, value[key], 'tag');
        }else if(key === 'eventType') {
          query += formSearchQuery('event.'+key, Operations.IN, value[key], 'array');
        } else if (key === 'startDate') {
          if(query !== '') {
            query += ' and '
          }
          const startTime = `dt'${  value?.[key]?.[0]} T00:00:00Z'`;
          const endTime = `dt'${  value?.[key]?.[1]} T00:00:00.000Z'`;
          query=`startDate:${Operations.GTE}:${startTime}\+endDate:${Operations.LTE}:${endTime}`
        } else if (key === 'createdTs') {
          if(query !== '') {
            query += ' and '
          }
          const startTime = `dt'${value?.[key]?.[0]} T00:00:00.000Z'`;
          const endTime = `dt'${value?.[key]?.[1]  } T00:00:00.000Z'`;
          query=`createdTs:${Operations.GTE}:${startTime}\+ and createdTs:${Operations.LTE}:${endTime}`
        } else if(value[key]?.length > 1) {
          query += formSearchQuery(key, Operations.IN, value[key], 'array');
        } else if (key === 'requestedRole') {
          let filterKey = key;
          let filterValue = value[key][0];
          let operation;
          // let val = value[key][0]
          if (filterValue === "Timekeeper") {
            filterKey = "isTimeKeeperRoleEnabled"
            filterValue = true
            operation = Operations.EQ
          } else if (filterValue === "Admin") {
            filterKey = "requestedRole"
            filterValue = "%2525Admin%2525"
            operation = Operations.LIKEIC
          }
          query += formSearchQuery(filterKey, operation, filterValue)
        } else {
          if(query !== ''){
          query += ' and '
        }
          query += formSearchQuery(key, Operations.EQ, value[key]);
        }
      }
    });
    onServerSideFilter(query);
  }
  };

  useEffect(() => {
    onFilter(appliedFilters);
  }, [appliedFilters])

  useEffect(() => {
    const result = {};
    [...columns].forEach(item => {
      result[item?.colKey] = item?.options?.filterList;
      if(item.options.obj){
        result[`${item.colKey}-obj`] = item?.options?.obj;
      }
    })
    setFilterInput(result);
    if(serverSide && !isEmptyObj(appliedFilters)) {
      onFilterServerSide(result);
    }
  }, [columns])

  return (
    <div
      className={!onlyFilter && classes.buttonComponentWrap}
      data-testid="filter-container"
    >
      <FilterButton togglePanel={setIsPanelOpen}/>
      <FilterPanel
        isOpen={isPanelOpen}
        togglePanel={setIsPanelOpen}
        applyFilterOnDataTable={setAppliedFilters}
        filterConfigs={filterConfigs}
        filterInput={filterInput}
        groupFilter={groupFilter}
      />
    </div>
  )
}

export default Filter;

Filter.propTypes = {
  /**
   * columns list of columns with applied filter criteria
   **/
  columns: PropTypes.array,
  /**
   * setTableColumns callback function to set filter criteria
   **/
  setTableColumns: PropTypes.func,
  /**
   * filterConfigs list of filters required
   **/
  filterConfigs: PropTypes.array,
  /**
   * Flag to enable serverside filter featur
   **/
  serverSide: PropTypes.bool,
  /**
   * Function to set filter query on filter action
  * */
   onServerSideFilter: PropTypes.func,
  /**
   * Flag to check if filters are from matter listing
   **/
  isMatter: PropTypes.bool
};

Filter.defaultProps = {
  setTableColumns:  (event) => {
    // eslint-disable-next-line no-console
    console.log('filters applied');
  },
  filterConfigs: [{
    colKey: 'priority',
    type: 'checkbox',
    label: 'Priority',
    options: ['Important', 'High', 'Low', 'None'],
    defaultOpen: true,
  }],
  columns: [{
    name: "Priority",
    colKey: 'priority',
    options: {
      filterList: []
    }
  }]
};
