import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { DISCLAIMER, MASTER_PARAMS, NO_CACHE_HEADER, SIDE_NAVIGATION_WIDTH } from "./constants/constants";
import { SnackbarProvider, Spinner, useSnackbar } from '@walmart-web/livingdesign-components';
import { getService, getServiceWithHeaders, postService } from './axios/axios';
import { useDispatch, useSelector } from "react-redux";
import { setMasterData } from "./store/actions/masterDataAction";
import { setOrgDetailValues } from "./store/actions/organizationAction";
import { setMatterDefinitions, setPracticeAreas, setPracticeAreasCodeToName, setPracticeAreasNameToCode } from "./store/actions/matterAction";
import { setResponsibilityCodes, setUserLoginDetails, setLogout, setStaticTk, setUserLoginTenantId, setAuthSign, setAuthTkn, setTknDetails,setFeatureFlags, setReleaseNotesData } from "./store/actions/userActions";
import { getErrorMessage, getMatterDefinitions, decryptUserDetail, encryptUserDetail, sortByString } from './helpers/utils';
import { ACCESS_CONTROL_SERVICE, MASTER_DATA, NODE_JS_URL, PRACTICE_AREA_SERVICE, USER_SERVICE, ORGANIZATION_SERVICE } from "./constants/baseURLs";
import { RouterOutlet } from './routes/RouterOutlet';
import { setAccessibilityControls } from "./store/actions/accessibilityActions";
import { mapAccesibilityControls } from "./access/AccessControls";
import LoginPage from "./components/LoginPage/LoginPage";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ThemisTopNav from "./components/common/ThemisTopNav/ThemisTopNav";
import ThemisFooter from "./components/common/ThemisFooter/ThemisFooter";
import LogoutPage from "./components/LogoutPage/LogoutPage";
import { ROLE_CODE_EMPTY_MESSAGE, UNAUTHORISED_ACCESS_MSG} from "./constants/messages";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import moment from 'moment';
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { IdleTimer } from "./components/IdleTimer/IdleTimer";
import ReleaseNotesModal from "./components/Modal/ReleaseNotesModal/ReleaseNotesModal";
export let interval;
export let tknInterval;
const useStyles = createUseStyles({
  root: {
    height: "100vh",
  },
  contentWrap: {
    marginLeft: SIDE_NAVIGATION_WIDTH,
    width: `calc(100% - ${SIDE_NAVIGATION_WIDTH}px)`,
  },
  content: {
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#F5F5F5",
    justifyContent: "center",
  },
  spinner: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionsWrap: {
    height: "calc(100vh - 200px)",
    overflow:"scroll",
    paddingBottom: "100px"
  },
  disclaimer: {
    width: '80%',
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '12px',
    color: '#626262',
    position: 'fixed',
    bottom: '100px',
    left: '10%',
    zIndex: '11',
    background: 'white'
  }
});

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();
  const [userContextLoaded, setUserContextLoaded] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [inactivityMsg, setUserInactivityMsg] = useState(false);
  const userDetail = useSelector((state) => state?.user?.userDetail);
  const tknDetail = useSelector((state) => state?.user?.tknDetail);
  const displayReleaseNotes = useSelector((state) => state?.user?.releaseNotes?.displayReleaseNotes);

  const history = useHistory();
  const entityId = localStorage.getItem('entityId');
  
  // fetch app2app details every 2 mins
  useEffect(()=>{
    getA2ADetails();
    interval = setInterval(() => {
      getA2ADetails();
    }, 120000);
  },[])

  /** If User session is active
   *      - fetch node configs
   *      - fetch responsibilty codes
   *      - load application
   *   else show login screen
   * */
  // new session
  useEffect(() => {
    if (tknDetail?.privatePass && localStorage.getItem("userDetails")) {
      onLoginSuccess(userDetail);
      setTimeout(() => {
        getRefreshToken();
      }, 2000);
    }else if(!tknDetail?.privatePass && !localStorage.getItem("userDetails")){
      setShowLoginPage(true);
    }
  }, [userDetail]);

  // existing session
  useEffect(() => {
    if (localStorage.getItem("userDetails") && !tknDetail?.privatePass) {
      const userDetails = JSON.parse(decryptUserDetail(localStorage.getItem("userDetails"), entityId));
      const localMetaData = JSON.parse(decryptUserDetail(localStorage.getItem("metadata"), entityId));
      dispatch(setAuthTkn(localMetaData?.privatePass))
      dispatch(setTknDetails(localMetaData));
      dispatch(setUserLoginDetails(userDetails));
      setTimeout(() => {
        getOrganizationDetail(userDetails?.attributes?.organizationId);
      }, 3000);
    } else {
      setShowLoginPage(true);
    }
  }, []);

  /**
   * This function will be called once Login is successful
   * for both active and new session
   * @param {*} userDetails
   */
  const onLoginSuccess = (userDetails) => {
    getConfig();
    getTK();
    setTimeout(() => {
      getResponsibilityCodes(userDetails?.roleCode);
    }, 3000);
  }

  const getOrganizationDetail = (orgId) => {
    getService(ORGANIZATION_SERVICE, `/organizations/v1/${orgId}`)
      .then((res) => {
        const orgDetail = res.data?.result?.organization;
        dispatch(setOrgDetailValues(orgDetail));
      }).catch((err) => {
        addSnack({message: UNAUTHORISED_ACCESS_MSG})
        onLogout();
      });
  }

  /**
  *  API call to fetch responsibility codes
  *  Input - roleCode - which is coming from user context API
  *  Output - list of responsibility codes from API response
  *         - It will be mapped to UI tree structure
  *         - Same structure will be used in all components for Access Control
  */
  const getResponsibilityCodes = (roleCode) => {
    if(roleCode){
      getService(ACCESS_CONTROL_SERVICE, `/access/v1/roles/${roleCode}`).then((res) => {
        dispatch(setResponsibilityCodes(res?.data?.responsibilities))
        // map responsibility codes to UI level access control tree structure
        if (res?.data?.responsibilities?.length > 0) {
          const accessMap = mapAccesibilityControls(res?.data?.responsibilities);
          dispatch(setAccessibilityControls({ ...accessMap }));
        }
        callMasterData();
        setMatterDefinitionsToRedux();
        getPracticeArea();
      }).catch(err => {
        dispatch(setResponsibilityCodes([]))
        onLogout();
        addSnack({message: getErrorMessage(err)})
      }).finally(() => {
        localStorage.setItem('loginSuccess', true);
      });
    }else{
      dispatch(setResponsibilityCodes([]))
      onLogout();
      addSnack({message: ROLE_CODE_EMPTY_MESSAGE})
    }
  }

  /**
  *  API call to Node js for fetching configurations
  */
  const getConfig = () => {
    getService(NODE_JS_URL, "/getConfiguration").then((res) => {
      const data = res?.data?.configResolution?.resolved;
      dispatch(setUserLoginTenantId(data?.tenantId));
      dispatch(setFeatureFlags(data?.featureMap ?  JSON.parse(data?.featureMap): {}))
      dispatch(setReleaseNotesData(data?.releaseNotes ? JSON.parse(data?.releaseNotes): {}))
    }).catch(err => {
      // Letting API fail silently
    })
  }

  /**
 *  API call to fetch static tk
 */
  const getTK = () => {
    getService(NODE_JS_URL, "/getTk").then((res) => {
      dispatch(setStaticTk(res?.data));
    }).catch(err => {
      // Letting API fail silently
    })
  }

  /**
* getA2ADetails - This method used to fetch App to App Auth Details
*/
  const getA2ADetails = () => {
    getServiceWithHeaders(NODE_JS_URL, "/getA2ATk?"+new Date().getTime(), NO_CACHE_HEADER).then((res) => {
      dispatch(setAuthSign(res?.data));
    })
      .catch(err => {
        console.log("Err from node app", err)
        dispatch(setAuthSign({}));
      })
  }

  /**
  * Fourth API call to fetch master data
  * It'll fetch all static data mainly for dropdown options across application
  */
  const callMasterData = () => {
    getService(
      MASTER_DATA,
      `/master-data/v1?types=${MASTER_PARAMS}`
    )
      .then((response) => {
        dispatch(setMasterData(response.data));
      })
      .catch((error) => {
        addSnack({
          message: getErrorMessage(error),
        });
      });
  }

  /**
  * Fifth API call to fetch matter definitions into redux
  */
  const setMatterDefinitionsToRedux = () => {
    getMatterDefinitions()
      .then((matterDefinitions) => {
        dispatch(setMatterDefinitions(matterDefinitions));
      }).catch((error) => {
        // Let the API fail quietly on app load. If definitions needed at some other point in application, API failure message will be shown there.
      })
  }

  /** API call to fetch all Practice Areas*/
  const getPracticeArea = () => {
    getService(PRACTICE_AREA_SERVICE,
      `/practice-areas/v1/`
    ).then((response) => {
      const sortedData = response?.data?.practiceAreas ? sortByString(response?.data?.practiceAreas, "name") : [];
      let practiceAreaNameToCode = {};
      let practiceAreaCodeToName = {};
      sortedData.forEach((pracArea) => {
        practiceAreaCodeToName[pracArea.code] = pracArea.name;
        practiceAreaNameToCode[pracArea.name] = pracArea.code;
      })
      dispatch(setPracticeAreasNameToCode(practiceAreaNameToCode));
      dispatch(setPracticeAreasCodeToName(practiceAreaCodeToName));
      dispatch(setPracticeAreas(sortedData));
      setUserContextLoaded(true);
      setShowLoginPage(false);
    }).catch((error) => {
      dispatch(setPracticeAreas([]));
    });
  }

  const logoutOnInactivity = () => {
    clearInterval(interval);
    clearInterval(tknInterval);
    tknInterval = null;
    setShowLoginPage(true);
    setUserInactivityMsg(true);
    history.push("/",{ inactivityMsg: true })
  }

  /**
   * onLogout - method to clear intervals and localstorage
   */
  const onLogout = () => {
    clearInterval(tknInterval);
    tknInterval = null;
    dispatch(setLogout());
    localStorage.clear();
  }

  const checkExpireToken = () => {
    if(tknDetail?.privatePass){
      const hours = moment().diff(moment(tknDetail?.refreshTokenCreationTime), 'hours');
      if(hours < 24){
          getRefreshToken()
      }else{
        onLogout();
        history.push('/')
      }
    }
  }

  const getRefreshToken = () => {
    const currentDate = new Date();
    const body = { "payload": tknDetail?.refreshToken };
    postService( USER_SERVICE, `/users/v1/refreshToken`,
			body
		).then((response) => {
      const newPrivatePass = response?.data?.payload?.authToken;
      dispatch(setAuthTkn(newPrivatePass));
      dispatch(setTknDetails({...tknDetail,
        privatePass: newPrivatePass,
        privatePassCreationTime: currentDate
      }));
      const localMetaData = JSON.parse(decryptUserDetail(localStorage.getItem("metadata"), entityId));
      localMetaData.privatePass = newPrivatePass;
      localMetaData.privatePassCreationTime = currentDate;
      localStorage.setItem("metadata", encryptUserDetail(JSON.stringify(localMetaData), entityId))
      if(!tknInterval) {
        // dynamic refresh token validity
        // added 30 seconds for buffer 
        const refreshTknTime = (tknDetail?.validity - 30 ) * 1000 ;
        tknInterval = setInterval(checkExpireToken, refreshTknTime);
      }
    }).catch((error) => {
      // logout if refresh token api fails
      onLogout();
      history.push('/')
    });
  }
  
  const AppContent = (userContextLoaded,displayReleaseNotes) => {
    return(
      !userContextLoaded
      ?
      <div className={classes.spinner}>
        <Spinner size="large" />
      </div>
      :
      <ErrorBoundary>
        <Router>
          <IdleTimer logoutOnInactivity={()=>{logoutOnInactivity()}}/>
          <RouterOutlet />
        </Router>
        {displayReleaseNotes && <ReleaseNotesModal />}
      </ErrorBoundary>
    )
  }

  return (
    <div className={classes.root}>
      {
      showLoginPage ?
          <React.Fragment>
            <ThemisTopNav />
            <div className={classes.actionsWrap}>
              <SnackbarProvider>
                <Router>
                  <Switch>
                    <Route path="/" exact render={(props) => <LoginPage inactivityMsg={inactivityMsg} {...props} />}/>
                    <Route path="/logout" component={LogoutPage} />
                    <Route path="/forgotPassword" component={ForgotPassword} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route
                      path="*"
                      component={LoginPage}
                    />
                  </Switch>
                </Router>
              </SnackbarProvider>
            </div>
            <div className={classes.disclaimer}>{DISCLAIMER}</div>
            <ThemisFooter />
          </React.Fragment>
        :
          AppContent(userContextLoaded,displayReleaseNotes)
      }
    </div>
  );
}

export default App;
