import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import CreateTimekeeperAdmin from "./CreateTimekeeperAdmin";
import TimeKeeperAdminCreatePracticeArea from "./TimeKeeperAdminCreatePracticeArea";
import TimeKeeperAdminSummary from "./TimeKeeperAdminSummary";
import {
  useSnackbar,
  Button,
  ProgressTracker,
  ProgressTrackerItem,
} from "@walmart-web/livingdesign-components";
import Detail from "../../Detail/Detail";
import {
  ADD_ANOTHER_RATE,
  ADMINPRACTICEAREA,
  CREATE_ADMIN,
  CREATE_NEW_TIMEKEEPER,
  detailModes as modes,
  OC_ADMIN,
  OC_TIMEKEEPER,
  PROFILE_INFO,
  RATE_S,
  REQUEST_NEW_TIMEKEEPER,
  ROLE_ADMIN,
  ROLE_TIMEKEEPER,
  SUMMARY,
  TIMEKEEPERPRACTICEAREA,
  TIMEKEEPER_ADMIN_SUMMARY,
} from "../../../constants/constants";
import { USER_SAVE_MESSAGE } from "../../../constants/messages";
import { DATE_END_TIME, DATE_START_TIME } from "../../../constants/dateFormats";
import { SPACING } from "../../../constants/styleGuide";
import { setPracticeAreaNew , resetTimeKeeperAdminValue, setInputValue } from "../../../store/actions/timeKeeperAdminActions";
import { Plus } from "@walmart-web/livingdesign-icons";
import { useHistory, useLocation } from "react-router-dom";
import { postService } from "../../../axios/axios";
import { resetAdminInvoiceValue } from "../../../store/actions/invoiceCoordinatorActions";
import { USER_REQUEST_SERVICE } from "../../../constants/baseURLs";
import { convertPANameToCode, getErrorMessage, renderTernary } from "../../../helpers/utils";
import { useForm } from "react-hook-form";
import { filterPayload, phoneNumberForBackend } from "../../../helpers/commonUtils";
import { CANCEL, NEXT, SUBMIT } from "../../../constants/actionButtons";
import { convertToUTCDateTime } from "../../../helpers/utcUtils";
import { isValueValidList } from "../../../helpers/validationUtils";

const useStyles = createUseStyles({
  leftContent: {
    paddingRight: SPACING.s72,
  },
  rightContent: {
    paddingTop: SPACING.s32,
    paddingLeft: SPACING.s24,
    paddingRight: SPACING.s24,
  },
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  contentSpacing48: {
    paddingTop: SPACING.s48,
  },
  tag: {
    padding: 4,
    display: "inline-block",
  },
  info: {
    marginLeft: SPACING.s10,
  },
  unAssigned: {
    fontStyle: "italic",
  },
  approvalDataRightSection: {
    marginTop: "103px",
  },
  wrapper: {
    flex: 1,
    alignItems: "center",
    background: "#fff",
    paddingTop: "30px",
  },
  progressTracker: {
    background: "#fff",
    margin: "0px 30px",
    width: 'calc(100% - 60px)',
  },
  addPracticeAreaButton: {
    "& button": {
      padding: 0,
    },
    paddingTop: SPACING.s30,
  },
});

const TimekeeperAdminApi = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();
  const {state : {selectedTab, fromTimeKeeper} = {}} = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const deleteLoading = false;
  const [mode, setMode] = useState(modes.CREATE);
  const [tabIndex, setTabIndex] = useState(0);
  let isDirty = true;
  const [pageTitle, setPageTitle] = useState(REQUEST_NEW_TIMEKEEPER);
  const { register, formState: { errors }, setError, clearErrors  } = useForm({ mode: "all" });
  const userDetails = useSelector(state => state?.user?.userDetail?.attributes);
  const practiceAreaNameToCode = useSelector(state => state?.matter?.practiceAreaNameToCode);

  const practiceArea = useSelector(
    (state) => state?.timeKeeperAdmin?.timeKeeperAdminValues?.practiceAreaRate
  );

  const practiceAreaInititalValues = useSelector(
    (state) => state?.timeKeeperAdmin?.timeKeeperAdminInitialValues?.practiceAreaRate[0]
  );

  const {
    companyName,
    identifier
  } = useSelector((state) => state?.organization?.organizationDetailValues);

  const {
    firstName,
    lastName,
    email,
    stateBarLicence,
    subjectMatterExpertise,
    justificationForTimeKeeper,
    tkid,
    classification,
    billingContact,
    phoneNo,
    addressLine1,
    addressLine2,
    addressType,
    city,
    jdYear,
    walmartApprovingAttorneyUserId,
    walmartApprovingAttorneyFirstName,
    walmartApprovingAttorneyLastName,
    walmartApprovingAttorneyEmailId,
    walmartApprovingAttorneyFullName,
    startDate,
    endDate,
    state,
    country,
    isTimeKeeperRoleEnabled
  } = useSelector((state) => state?.timeKeeperAdmin?.timeKeeperAdminValues);
  const {pureAdminRole} = useSelector((state) => state?.user?.featureMap);

  useEffect(() => {
      if (tabIndex === 1) {
        setPageTitle(fromTimeKeeper? TIMEKEEPERPRACTICEAREA : ADMINPRACTICEAREA)
      }
      else if (tabIndex === 2) {
       setPageTitle(TIMEKEEPER_ADMIN_SUMMARY)
      }
      else {
       setPageTitle(fromTimeKeeper? CREATE_NEW_TIMEKEEPER : CREATE_ADMIN);
      }
  }, [tabIndex]);

  useEffect(()=>{
    return ()=>{
      dispatch(resetTimeKeeperAdminValue())
      dispatch(resetAdminInvoiceValue())
    }
  },[])

  useEffect(()=>{
    if(!pureAdminRole){
      dispatch(setInputValue('isTimeKeeperRoleEnabled', !isTimeKeeperRoleEnabled));
    }
  },[])

  {//block to validate mandatory fields
    isDirty = isValueValidList([firstName, lastName, email, stateBarLicence, walmartApprovingAttorneyFirstName, tkid, classification, justificationForTimeKeeper, subjectMatterExpertise, startDate])
    if(!isTimeKeeperRoleEnabled){
      isDirty = isValueValidList([firstName, lastName, email, walmartApprovingAttorneyFirstName, justificationForTimeKeeper, startDate])
    }
    if(tabIndex ===1){
      isDirty = practiceArea?.filter((row)=> isValueValidList([row?.practiceArea, row?.rateStartDate, row?.walmartApprovingAttorneyFirstName, row?.proposedWalmartRate, row?.currentStandardRate])).length === practiceArea?.length
    }
  }

  /**
   * Gets called when clicked on Add Another Rate button.
   */
  const handleSetPracticeArea = () => {
    const newPracticeAreaFields = {
      ...practiceAreaInititalValues,
      id: practiceArea?.length + 1,
    };
    dispatch(setPracticeAreaNew(newPracticeAreaFields));
  };

  /**
   * Gets called when clicked on create or next button.
   */
  const handleCreate = () => {
    tabIndex < 2 && (isTimeKeeperRoleEnabled || fromTimeKeeper) && setTabIndex(tabIndex + 1);
    const practiceAreaData = practiceArea?.map((row)=>{
      return(
        {
          "internalUserId"                          : "",
          "userId"                                  : email,
          "firstName"                               : firstName,
          "lastName"                                : lastName,
          "emailId"                                 : email,
          "contactNumber"                           : phoneNumberForBackend(phoneNo),
          "startDate"                               : convertToUTCDateTime({date: row?.rateStartDate, time: DATE_START_TIME}),
          "endDate"                                 : row?.rateEndDate ? convertToUTCDateTime({date: row?.rateEndDate, time: DATE_END_TIME}) : null,
          "proposedWalmartRate"                     : row?.proposedWalmartRate,
          "currentWalmartRate"                      : row?.currentStandardRate,
          "currentStandardRate"                     : row?.currentStandardRate,
          "outsideOrgJustification"                 : row?.justificationForRate,
          "walmartApprovingAttorneyUserId"          : row?.walmartApprovingAttorneyUserId,
          "walmartApprovingAttorneyFirstName"       : row?.walmartApprovingAttorneyFirstName,
          "walmartApprovingAttorneyLastName"        : row?.walmartApprovingAttorneyLastName,
          "walmartApprovingAttorneyEmailId"         : row?.walmartApprovingAttorneyEmailId,
          "walmartApprovingAttorneyFullName"        : row?.walmartApprovingAttorneyFullName,
          "practiceAreaCode"                        : convertPANameToCode(row?.practiceArea, practiceAreaNameToCode)
        }
      )
    })
    if ((tabIndex === 2) || (!isTimeKeeperRoleEnabled && !fromTimeKeeper)){
      setLoading(true);
      const roleCodes = [ renderTernary(fromTimeKeeper, OC_TIMEKEEPER , OC_ADMIN) ];
      const primaryOfcLocation = {
        'addressLine1'                              : addressLine1,
        'addressType'                               : addressType,
        'addressLine2'                              : addressLine2 || '',
        'city'                                      : city || '',
        'state'                                     : state || '',
        'country'                                   : country || ''
      }
      const body = {
        "firstName"                                 : firstName,
        "lastName"                                  : lastName,
        "emailId"                                   : email,
        "requestedRole"                             : fromTimeKeeper? ROLE_TIMEKEEPER : ROLE_ADMIN,
        "roleCodes"                                 : roleCodes,
        "primaryOfficeLocation"                     : JSON.stringify(primaryOfcLocation),
        "organizationId"                            : identifier,
        "organizationName"                          : companyName,
        "phoneNumber"                               : phoneNumberForBackend(phoneNo),        
        "walmartApprovingAttorneyEmailId"           : walmartApprovingAttorneyEmailId,
        "walmartApprovingAttorneyFirstName"         : walmartApprovingAttorneyFirstName,
        "walmartApprovingAttorneyFullName"          : walmartApprovingAttorneyFullName,
        "walmartApprovingAttorneyLastName"          : walmartApprovingAttorneyLastName,
        "walmartApprovingAttorneyUserId"            : walmartApprovingAttorneyUserId,
        "justificationForRole"                      : justificationForTimeKeeper,
        "requesterEmailId"                          : userDetails?.emailId,
        "requesterUserId"                           : userDetails?.userId,
        "requesterFirstName"                        : userDetails?.firstName,
        "requesterLastName"                         : userDetails?.lastName,
        "requesterFullName"                         : `${userDetails?.firstName} ${userDetails?.lastName}`,
        "isBillingContact"                          : billingContact,
        "startDate"                                 : convertToUTCDateTime({date:startDate, time: DATE_START_TIME}),
        "endDate"                                   : endDate ? convertToUTCDateTime({date:endDate, time: DATE_END_TIME}) : null,
        "isTimeKeeperRoleEnabled"                   : fromTimeKeeper? true : isTimeKeeperRoleEnabled
      };
      if(isTimeKeeperRoleEnabled || fromTimeKeeper){
        body["practiceAreaSpecificRates" ] = practiceAreaData;
        body["stateBarLicence"]= stateBarLicence;
        body["jdYear"]= Number(jdYear);
        body["subjectMatterExpertise"]= subjectMatterExpertise;
        body["tkId"]= tkid;
        body["classification"]= classification;
      }
      const filteredBody=filterPayload(body)
      postService(USER_REQUEST_SERVICE, "/user-requests/v1", filteredBody)
        .then((res) => {
          setLoading(false)
          addSnack({
            message: USER_SAVE_MESSAGE,
          });
          history.push("/organization", { selectedTab })
        })
        .catch((error) => {
          setLoading(false);
          addSnack({
            message: getErrorMessage(error)
          });
        });
    }

  };

  /**
   * Gets called when clicked on goback button.
   */
  const handleGoBack = () => {
    tabIndex > 0 && setTabIndex(tabIndex - 1);
  };

  /**
   * Gets called when clicked on cancel button.
   */
  const handleCancel = () => {
    history.push(`/organization`,{selectedTab})
  };

  /*
  * Functional Component to Handle Tab Change from Create Timekeeper/Admin to Pratice Area and Summary
  */
  const handleTabChange = () => {
    if (tabIndex === 0) {
      return <CreateTimekeeperAdmin register={register} errors={errors} clearErrors={clearErrors} setError={setError}/>
    } else if (tabIndex === 1) {
      return <>
        {practiceArea?.map((row, idx) => (
          <TimeKeeperAdminCreatePracticeArea
            order={row?.id}
            selectedRow={idx}
            key={idx?.toString()}
            register={register} errors={errors} clearErrors={clearErrors} setError={setError}
          />
        ))}
        <div className={classes.addPracticeAreaButton}>
          <Button
            variant="tertiary"
            onClick={handleSetPracticeArea}
            data-testid={`practice-area-add-btn`}
            leading={<Plus size="small" />}
          >
            {ADD_ANOTHER_RATE}
          </Button>
        </div>
      </>
    } else {
      return <TimeKeeperAdminSummary />
    }
  }
  return (
    <div className={classes.wrapper}>
      {
        isTimeKeeperRoleEnabled || fromTimeKeeper?
        <ProgressTracker
          activeIndex={tabIndex}
          variant="info"
          className={classes.progressTracker}
        >
          <ProgressTrackerItem>{PROFILE_INFO}</ProgressTrackerItem>
          <ProgressTrackerItem>{RATE_S}</ProgressTrackerItem>
          <ProgressTrackerItem>{SUMMARY}</ProgressTrackerItem>
        </ProgressTracker>:
        <></>        
      }      
      <Detail
        mode={mode}
        setMode={setMode}
        breadcrumbValues={[]}
        subject={""}
        newSubtitle={""}
        newTitle={pageTitle}
        onCreate={handleCreate}
        onUpdate={() => {}}
        onDelete={() => {}}
        onCancel={handleGoBack}
        loading={loading}
        isDirty={renderTernary(Object.keys(errors)?.length > 0, false, isDirty)}
        isValid={Object.keys(errors).length > 0? false: true}
        deleteLoading={deleteLoading}
        cancelText={"Go back"}
        disableCancel={tabIndex === 0}
        editBtnTxt={(tabIndex === 2 || (!fromTimeKeeper && !isTimeKeeperRoleEnabled)) ? SUBMIT : NEXT}
        footerActions={
          <Button
            variant="tertiary"
            onClick={handleCancel}
            data-testid={`footer-cancel-button`}
          >
            {CANCEL}
          </Button>
        }
      >
        {handleTabChange()}
      </Detail>
    </div>
  );
};

export default TimekeeperAdminApi;
