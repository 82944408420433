import { CS_RESERVED_CHARACTERS } from "../constants/constants";
import { convertPANameToCode } from "./utils";

/**
 * Function to form generic search query
 * @param {*} searchText
 * @returns generic search query
 */
export const formRawSearchQuery = (searchText) => {
  if (searchText) {
    const refinedSearchText = getRefinedSearchText(searchText);
    if(refinedSearchText) {
      return { "query": `*${refinedSearchText}*` }
    }
    else {
      return {}
    }
  } else {
    return {}
  }
}

export const getRefinedSearchText = (searchText) => {
  let refinedSearchText = searchText;
  [...CS_RESERVED_CHARACTERS].forEach((item) => {
      refinedSearchText = refinedSearchText.split(item).join(`\\${item}`);
  })
  refinedSearchText = refinedSearchText.replace("<","");
  refinedSearchText = refinedSearchText.replace(">","");
  return refinedSearchText;
}
/**
 * Function  to form component specific filter objects
 * @param {*} key
 * @param {*} operation
 * @param {*} value
 * @returns
 */
export const formCsFilterObject = (key, operation, value) => {
  return {
    "key": key,
    "operation": operation,
    "value": value
  }
}

/**
 * Function to form filter sub-query
 * @param {*} filterValues
 * @returns filter query
 */
export const formCsFilterQuery = (practiceAreaNameToCode, filterValues) => {
  const filterArray = [];
  Object.keys(filterValues).forEach(function (key) {
    if (filterValues?.[key].length !== 0) {
      // Special condition for leadAttorney
      if(key === 'leadAttorney')
      {
        let filter = {
          "key": "people.isLeadAttorney",
          "operation": "match",
          "value": true
        }
        filterArray.push(filter);
        const keyObj = `${key}-obj`
        filter = {
          "key": "people.id",
          "operation": "match",
          "value": `${filterValues?.[keyObj]?.referenceKey}`
        }
        filterArray.push(filter);
      }
      // Special condition for practiceArea
      else if(key === "practiceArea")
      {
        const pAFilterArray = [];
        filterValues?.[key]?.forEach(function (item, index) {
          const paCode = convertPANameToCode(item, practiceAreaNameToCode)
          pAFilterArray.push(paCode)
        })
        const filter = {
          "key": "practiceAreaCode",
          "operation": "in",
          "value": pAFilterArray
        }
        filterArray.push(filter)
      }
      // Default condition
      else if(!key?.includes('-obj'))
      {
        let filter = {};
        // Check if multiple values need to be matched
        if(filterValues?.[key].length === 1)
        {
          filter = {
            "key": `${key}`,
            "value": filterValues?.[key][0],
            "operation": "match"
          }
        }
        // Check if a single value needs to be matched
        else
        {
          filter = {
            "key": `${key}`,
            "value": filterValues?.[key],
            "operation": "in"
          }
        }
      filterArray.push(filter);
      }
    }
  });
  return {
      "filters": filterArray,
      "operation": "AND",
      "properties": null
    }
}

/**
 * Function to form sort sub-query
 * @param {*} name
 * @param {*} direction
 * @returns sort query
 */
export const formCsSortQuery = (name, direction) => {
  return {
    "sortBy": [{
      "key": `${name}`,
      "type": `${direction}`
    }]
  }
}

/**
 * Function to form search sub-query
 * @param {*} searchText
 * @returns search query
 */
export const formCsSearchQuery = (searchText, COLUMNS=[]) => {
  let searchFilter;
  if(COLUMNS.length === 0) {
    searchFilter = [{
      "key": "*",
      "operation": "raw",
      "value": `*${searchText}*`
    }]
  } else {
    searchFilter = COLUMNS?.map((item) => {
      if (item?.colKey && item?.options?.display !== false) {
        return {
          "key": `${item?.colKey}`,
          "operation": "raw",
          "value": `*${searchText}*`
        }
      }
    })?.filter(function (item) {
      return item !== undefined;
    });
  }

    return {
      "filters": searchFilter,
      "operation": "OR",
      "properties":[{
         "filters":[{
            "key": "people.*",
            "operation": "raw",
            "value": `*${searchText}*`
          }],
         "operation": "AND"
        }]
     }
}

/**
 * Function to form pagination sub-query
 * @param {*} rowsPerPage
 * @param {*} page
 * @returns pagination query
 */
export const formCsPaginationQuery = (rowsPerPage, page) => {
  return {
    "offset": page,
    "limit": rowsPerPage,
  }
}

/**
 * Function to assemble various sub-queries and form the final composite search query
 * @param {*} scope
 * @param {*} filterQuery
 * @param {*} searchQuery
 * @param {*} sortQuery
 * @param {*} paginationQuery
 * @param {*} additionalQuery
 * @param  {...any} additionalProperties
 * @returns CS query object
 */
export const formCompositeSearchQuery = (scope, filterQuery, searchQuery, sortQuery, paginationQuery, additionalQuery, ...additionalProperties) => {
  const propertiesArray = [];
  // Check if the filterQuery is empty
  if((Object.keys(filterQuery).length !== 0) && (filterQuery.filters.length !== 0))
  {
  propertiesArray.push(filterQuery);
  }
  // Check if the additionalProperties is empty
  additionalProperties?.forEach((item) => {
  if((Object.keys(item).length !== 0) && (item?.filters?.length !== 0))
  {
  propertiesArray.push(item);
  }
  })
  // Check if the searchQuery is empty
  // if((Object.keys(searchQuery).length !== 0) && (searchQuery?.filters?.length !== 0))
  // {
  // propertiesArray.push(searchQuery);
  // }
  const finalFilterQuery = {
    "operation": "AND",
    "properties": propertiesArray,
    ...(searchQuery)
  }
  const query = {
    ...finalFilterQuery,
    ...sortQuery,
    ...paginationQuery,
    ...additionalQuery
  }
  // Map the query to all scopes
  let finalQuery = {};
  scope?.forEach((item) => {
    finalQuery = {
      [item]: query,
      ...finalQuery
    }
    })
  finalQuery = {
    "searchScopes": finalQuery
  }
  return finalQuery;
}


/**
 * Function to form search sub-query
 * @param {*} searchText
 * @param {optional} userType
 * @returns search query
 */
export const formPeopleCsSearchQuery = (searchText, COLUMNS=[]) => {
  let searchFilter;
  if(COLUMNS.length === 0) {
    searchFilter = [{
      "key": "*",
      "operation": "raw",
      "value": `*${searchText}*`
    }]
  } else {
    searchFilter = COLUMNS?.map((item) => {
      if (item?.colKey && item?.options?.display !== false) {
        return {
          "key": `${item?.colKey}`,
          "operation": "raw",
          "value": `*${searchText}*`
        }
      }
    })?.filter(function (item) {
      return item !== undefined;
    });
  }

  return {
    "filters": searchFilter,
    "operation": "OR",
  }
}

/**
 * Function to form filter sub-query
 * @param {*} filterValues
 * @returns filter query
 */
export const formPeopleCsFilterQuery = (filterValues) => {
  const filterArray = [];
  Object?.keys(filterValues)?.forEach(function (key) {
    if (filterValues?.[key].length !== 0) {
      // Special condition for practiceArea
      if(key === "startDate") {
        const startDateTimeFilter = {
          "key": "createdTs",
          "operation": "gte",
          "value": filterValues?.[key]?.[0] + " 00:00:00"
        }
        filterArray.push(startDateTimeFilter);
        const endDateTimeFilter = {
          "key": "createdTs",
          "operation": "lte",
          "value": filterValues?.[key]?.[0] + " 23:59:59"
        }
        filterArray.push(endDateTimeFilter);
      } else if(key=== 'requestedRole') {
        let roleKey;
        let val = filterValues[key][0]
        if (val === "Admin") {
          roleKey = "roleCodes"
          val="OC_ADMIN"
        } else {
          roleKey = "isTimeKeeperRoleEnabled"
          val = true
        }
        const filter = {
          "key": `${roleKey}`,
          "value": val,
          "operation": "match"
        }
        filterArray.push(filter);
      }
      // Default condition
      else if(!key?.includes('-obj'))
      {
        let filter = {};
        // Check if a single value needs to be matched
        if(filterValues?.[key]?.length === 1)
        {
          filter = {
            "key": `${key}`,
            "value": filterValues?.[key]?.[0],
            "operation": "match"
          }
        }
        // Check if multiple values need to be matched
        else
        {
          filter = {
            "key": `${key}`,
            "value": filterValues?.[key],
            "operation": "in"
          }
        }
      filterArray.push(filter);
      }
    }
  });
  return {
    "filters": filterArray,
    "operation": "AND",
    "properties": null
  };
}

/**
 * Function to form search sub-query
 * @param {*} searchText
 * @returns search query
 */
export const formOrgSearchQuery = (searchText, COLUMNS=[]) => {
  let searchFilter;
  if(COLUMNS.length === 0) {
    searchFilter = [{
      "key": "*",
      "operation": "raw",
      "value": `*${searchText}*`
    }]
  } else {
    searchFilter = COLUMNS?.map((item) => {
      if (item?.colKey && item?.options?.display !== false) {
        return {
          "key": `${item?.colKey}`,
          "operation": "raw",
          "value": `*${searchText}*`
        }
      }
    })?.filter(function (item) {
      return item !== undefined;
    });
  }
  return {
    "filters": searchFilter,
    "operation": "OR",
  }
}

/**
   * Function to form search sub-query
   * @param {*} searchText
   * @param {optional} userType
   * @returns search query
   */
export const formBudgetCsSearchQuery = (searchText, COLUMNS) => {
  let searchColumns = COLUMNS?.map((item) => {
    if (item?.colKey && item?.options?.display !== false && item?.colKey !== "paidPercent") {
      if(item?.colKey === "status")
      {
        return {
          "key": `${item?.colKey}`,
          "operation": "raw",
          "value": `*${searchText.toUpperCase()}*`
        }
      }
      else{
      return {
        "key": `${item?.colKey}`,
        "operation": "raw",
        "value": `*${searchText}*`
      }
    }
    }
  })?.filter(function (item) {
    return item !== undefined;
  });
  if(searchColumns === undefined)
  {
    searchColumns = null;
  }
  return {
    "filters": searchColumns,
    "operation": "OR",
  }
}

/**
   * Function to form filter sub-query
   * @param {*} filterValues
   * @returns filter query
   */
export const formBudgetFilterQuery = (filterValues) => {
  const filterArray = [];
    Object.keys(filterValues).forEach(function (key) {
      if (filterValues?.[key].length !== 0 && !key.includes("-obj")) {
        let filter = {};
        // Check if a single value needs to be matched
        if(filterValues?.[key].length === 1)
        {
          let value = filterValues?.[key][0]
          if(key === "status"){
              value = filterValues?.[key][0].toUpperCase()
          }
          if(key === "isActive") {
            value = (filterValues?.[key]?.[0] === "Yes" || filterValues?.[key]?.[0] === true) ? true : false
          }
          filter = {
            "key": `${key}`,
            "value": value,
            "operation": "match"
          }
        }
        // Check if multiple values need to be matched
        else
        {
          const values = filterValues?.[key]
          if(key === "status"){
              values.map((item,idx)=>{
                values[idx]=item?.toUpperCase()
              })
          }
          filter = {
            "key": `${key}`,
            "value": values,
            "operation": "in"
          }
        }
        filterArray.push(filter);
      }
    });
    return {
      "filters": filterArray,
      "operation": "AND",
      "properties": null
    }
}

 /**
   * Function to form search sub-query for people auto-complete
   * @param {*} searchText
   * @returns search query
   */
 export const formCsPeopleAutocompleteQuery = (searchText) => {
  if(searchText) {
    const refinedSearchText = getRefinedSearchText(searchText);
    return {
      "filters": [formCsFilterObject("firstName_raw", "raw", `${refinedSearchText}*`),
      formCsFilterObject("lastName_raw", "raw", `${refinedSearchText}*`),
      formCsFilterObject("userId_raw", "raw", `${refinedSearchText}*`)],
      "operation": "OR",
      "properties": null
    }
  } else {
    return {};
  }
}

/**
 * Function to form search sub-query for matter auto-complete
 * @param {*} searchText
 * @returns search query
 */
export const formCsMatterAutocompleteQuery = (searchText) => {
  if(searchText) {
    const refinedSearchText = getRefinedSearchText(searchText);
    return {
      "filters": [formCsFilterObject("matterName_raw", "raw", `*${refinedSearchText}*`),
      formCsFilterObject("matterNumber", "raw", `*${refinedSearchText}*`)],
      "operation": "OR",
      "properties": null
    }
  } else {
    return {};
  }
}

/**
 * Function to form search sub-query for org auto-complete
 * @param {*} searchText
 * @returns search query
 */
export const formCsOrgAutocompleteQuery = (searchText) => {
  if(searchText) {
    const refinedSearchText = getRefinedSearchText(searchText);
    return {
      "filters": [formCsFilterObject("companyName_raw", "raw", `*${refinedSearchText}*`)],
      "operation": "OR",
      "properties": null
    }
  } else {
    return {};
  }
}

/**
   * Function to form search sub-query for Invoice Line item TK auto-complete
   * @param {*} searchText
   * @returns search query
   */
export const formCsInvoiceTimekeeperAutocompleteQuery = (searchText) => {
  if(searchText)
  {
  return {
    "filters": [formCsFilterObject("firstName_raw", "raw", `${searchText}*`),
    formCsFilterObject("lastName_raw", "raw", `${searchText}*`),
    formCsFilterObject("tkId", "raw", `${searchText}*`)],
    "operation": "OR",
    "properties": null
    }
  }
  else {
    return {};
  }
}

export const getUpdatedFilterQuery = (filterValues) => {
  filterValues?.filters?.forEach((filterType) => {
    if (filterType?.key === 'status') {
      if(Array.isArray(filterType?.value) && filterType?.value?.includes("Ready For Payment") && !filterType?.value?.includes("Hold")) {
        filterType.value = [...filterType?.value, 'Hold']
      }
      if(!Array.isArray(filterType?.value) && filterType?.value === "Ready For Payment") {
        filterType.value = ["Ready For Payment", "Hold"]
        filterType.operation = "in";
      }
    }
  });
  return filterValues;
}
