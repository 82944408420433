import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Caption, useSnackbar } from "@walmart-web/livingdesign-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import {
  postService
} from "../../../../axios/axios";
import { Operations } from "../../../../axios/operations";
import MuiDataTable from "../../../../components/Table/MuiDataTable/MuiDataTable";
import { CS_DEFAULT_SORT_QUERY, CS_IS_ACTIVE_FILTER, CS_SCOPES, CS_SCORE_SORT_OBJ, EXPORT_MAX_LIMIT_CONF, INVOICE } from "../../../../constants/constants";
import {
  ENABLE_SERVER_SIDE_FEATURES_INVOICE,
  ROWS_PER_PAGE_OPTIONS_INVOICE
} from "../../../../constants/tableConfigs";
import {  getErrorMessage, jsonToCsv } from "../../../../helpers/utils";
import { setInvoiceData } from "../../../../store/actions/invoiceActions";
import { COMPOSITE_SEARCH_SERVICE } from "../../../../constants/baseURLs";
import { formCompositeSearchQuery, formCsFilterObject, formCsSortQuery, formRawSearchQuery, getUpdatedFilterQuery } from "../../../../helpers/csQueryUtils";
import { getInvoiceStatus } from "../../../../helpers/tableUtils";
import { displayLocalDate } from "../../../../helpers/utcUtils";
import { TableCellDisplay } from "../../../../helpers/commonUtils";
import { BLACK } from "../../../../constants/colors";
import ExportModal from "../../../../components/Modal/ExportModal/ExportModal";

const useStyles = createUseStyles({
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
  exportName: {
    marginBottom: 20
  },
  exportNameAs: {
    paddingTop: 20,
    marginBottom: 20
  },
  stickyStyle: {
    position: "sticky",
    left: 0,
    zIndex: 9999,
    minWidth: "200px !important",
  },
  dataTableWrap: {
    width: '100%',
    '& table > thead > tr >th': {
      borderTop: "1px solid rgba(224, 224, 224, 1)"
    },
    '& table > tbody > tr >td:nth-child(1)': {
      position: "sticky",
      left: "0",
      zIndex: 102
    },
    '& table > tbody > tr:hover >td:nth-child(1)': {
      backgroundColor: "#ffffff !important"
    },
    "& div>table>tbody>tr>td": {
      borderBottom: "none",
      fontFamily: "Bogle",
      height: "24px",
      fontWeight: "normal",
      lineHeight: "1.33",
    },
    "& div>table": {
      borderCollapse: "initial"
    },
  }
});


  /**
   * Gets called when user download the export file
   * server side
   * @public
   */
  export const getExportedData = (payload, contentType) => {
    const url = window.URL.createObjectURL(
      new Blob([payload], { type: contentType })
    );
    let ext = ".csv";
    if (contentType === "application/octet-stream") {
      ext = ".zip";
    }
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `invoice-data${ext}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  /**
   * Gets called when user download the export file
   * client side
   * @public
   */
  export const exportClientData = (invoices, exportSelection, rowsData) => {
    let csvData = jsonToCsv(invoices);
    if (exportSelection === 'currentSelection') {
      csvData = jsonToCsv(rowsData);
    }
    //Download the file as CSV
    const url = window.URL.createObjectURL(
      new Blob([csvData]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `invoice-data.csv`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

const BudgetInvoiceList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false)
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const { state: { fromMatter, matterNumber } = {} } = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const { addSnack } = useSnackbar();
  const [rowsData, setRowsData] = useState([]);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [sortQuery, setSortQuery] = useState(CS_DEFAULT_SORT_QUERY);
  const { budgetId } = useParams();
  const dispatch = useDispatch();
  const { invoiceData } = useSelector((state) => state?.invoice);
  const showExportAllOption = invoiceData?.count < EXPORT_MAX_LIMIT_CONF.BUDGET;
  const defaultExportOption = showExportAllOption ? 'allBudgets' : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);
  const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);

  const defaultQuery = fromMatter ? {
    "filters": [
      CS_IS_ACTIVE_FILTER,
      formCsFilterObject("budgetId","match",budgetId),
      formCsFilterObject("matterNumber","match",matterNumber),
      formCsFilterObject("organizationId","match",orgIdentifier),
      formCsFilterObject("invoiceType", "match", "Invoice")
    ],
    "operation": "AND",
    "properties": null
    } : {
      "filters": [
        CS_IS_ACTIVE_FILTER,
        formCsFilterObject("budgetId","match",budgetId),
        formCsFilterObject("organizationId","match",orgIdentifier),
        formCsFilterObject("invoiceType", "match", "Invoice")
      ],
      "operation": "AND",
      "properties": null
    }

    const renderMatterName=(value, tableMeta) => {
      return (
        <div className={classes.stickyStyle}>
          <Caption as="p">{value}</Caption>
        </div>
      )
    }

    const renderInvoiceReceivedDate=(value, tableMeta) => {
      return (
        tableMeta.rowData[2] !== true ? (
          <Caption as="p">
            {value ? displayLocalDate(value) : "-"}
          </Caption>
        ) : null
      )
    }

    const renderStatus=(value, tableMeta) => {
      return <Caption as="p">{getInvoiceStatus(value)}</Caption>
    }

  const COLUMNS = [
    {
      name: "matterName",
      label: "Matter",
      colKey: "matterName",
      options: {
        sort: true,
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 46,
            background: "white",
            // background: "rgba(224, 224, 224, 1)",
            zIndex: 100,
            boxShadow: "8px 0px 5px 0px rgba(0,0,0,0.04)"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 46,
            background: "white",
            // background: "rgba(224, 224, 224, 1)",
            zIndex: 101,
            boxShadow: "8px 0px 5px 0px rgba(0,0,0,0.04)"
          },
        }),
        customBodyRender: renderMatterName,
        customFilterListOptions: { render: (v) => `Matter: ${v}` },
      },
      operation: Operations.LIKEIC,
      type: "tag"
    },
    {
      name: "matterNumber",
      label: "Matter No",
      colKey: "matterNumber",
      options: {
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

          }
        }),
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-matter-no-${tableMeta.rowIndex}`,value),
        customFilterListOptions: { render: (v) => `Matter No: ${v}` },
      },
      operation: Operations.LIKEIC,
      type: "tag"
    },
    {
      name: "organizationInvoiceNumber",
      label: "Invoice No.",
      colKey: "organizationInvoiceNumber",
      options: {
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-org-inv-no-${tableMeta.rowIndex}`,value),
        customFilterListOptions: { render: (v) => `Invoice No.: ${v}` },
      },
      operation: Operations.LIKEIC,
      type: "tag"
    },
    {
      name: "invoiceDate",
      label: "Invoice Date",
      colKey: "invoiceDate",
      options: {
        sort: true,
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: renderInvoiceReceivedDate,
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "receivedDate",
      label: "Received Date",
      colKey: "receivedDate",
      options: {
        sort: true,
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: renderInvoiceReceivedDate,
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "createdTs",
      label: "Created Date Range",
      colKey: "createdTs",
      options: {
        sort: false,
        display: false,
        customBodyRender: renderInvoiceReceivedDate,
        customFilterListOptions: {
          render: (v) => {
            return `Created Date Range: ${v[0]} - ${v[1]}`;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === -1) {
              filterList = [];
            }
            return filterList;
          },
        },
        filter: true,
        filterType: 'custom',
        filterList: [],
        filterOptions: {
          names: [],
          logic(date, filters) {
            if (filters[0] && filters[1]) {
              return date < filters[0] || date > filters[1];
            } else if (filters[0]) {
              return date < filters[0];
            } else if (filters[1]) {
              return date > filters[1];
            }
            return false;
          }
        }
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: renderStatus,
        customFilterListOptions: { render: (v) => `Status: ${v}` },
      },
      operation: Operations.EQ,
      type: "id"
    },
    {
      name: "totalNetAmount",
      label: "$ Total",
      colKey: "totalNetAmount",
      options: {
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customFilterListOptions: { render: (v) => `Net Amount: ${v}` },
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      colKey: "assignedTo",
      options: {
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
      },
      operation: Operations.LIKE,
      type: "tag"
    }
  ]

  const MATTER_INVOICE_COLUMNS = [
    {
      name: "organizationInvoiceNumber",
      label: "Invoice No.",
      colKey: "organizationInvoiceNumber",
      options: {
        sort: true,
        display: true,
        setCellHeaderProps: () => ({ style: { minWidth: "105px", maxWidth: "105px" }}),
        customFilterListOptions: { render: (v) => `Invoice No.: ${v}` },
      },
      operation: Operations.LIKEIC,
      type: "tag"
    },
    {
      name: "startDate",
      label: "Invoice Date",
      colKey: "startDate",
      options: {
        sort: true,
        display: true,
        setCellHeaderProps: () => ({ style: { minWidth: "112px", maxWidth: "112px" }}),
        customBodyRender: renderInvoiceReceivedDate,
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "receivedDate",
      label: "Received Date",
      colKey: "receivedDate",
      options: {
        sort: true,
        display: true,
        customBodyRender: renderInvoiceReceivedDate,
      },
      operation: Operations.GTE,
      type:"date"
    },
    {
      name: "daysLeft",
      label: "Days Left",
      colKey: "daysLeft",
      options: {
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap"
          }
        }),
        customBodyRender: (value, tableMeta) => TableCellDisplay(`table-row-days-left-${tableMeta.rowIndex}`,value),
        customFilterListOptions: { render: (v) => `Days Left: ${v}` },
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        display: true,
        customFilterListOptions: { render: (v) => `Status: ${v}` },
      },
      operation: Operations.EQ,
      type: "id"
    },
    {
      name: "totalNetAmount",
      label: "$ Total",
      colKey: "totalNetAmount",
      options: {
        display: true,
        setCellHeaderProps: () => ({ style: { minWidth: "80px", maxWidth: "80px" }}),
        customFilterListOptions: { render: (v) => `Total: ${v}` },
      },
      operation: Operations.EQ,
      type: "number"
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      colKey: "assignedTo",
      options: {
        sort: false,
        display: true,
        setCellHeaderProps: () => ({ style: { minWidth: "74px", fontWeight: 700, color: BLACK } }),
      },
      operation: Operations.LIKE,
      type: "tag"
    }
  ];

  const [invoiceColumns, setInvoiceColumns] = useState(fromMatter ? MATTER_INVOICE_COLUMNS : COLUMNS);

  const MATTER_FILTER_CONFIGS = [
    {
      colKey: "organizationInvoiceNumber",
      type: "textField",
      label: "Find an Invoice No.",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Invoice Number',
      fullWidth: "100"
    },
    {
      colKey: "daysLeft",
      type: "textField",
      label: "Number of Days",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Days Left',
      fullWidth: "100"
    },
    {
      colKey: "totalNetAmount",
      type: "textField",
      label: "$ Total",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Net Amount',
      fullWidth: "100",
      isPositiveDecimalNumber: true
    },
    {
      colKey: "createdTs",
      type: "rangePicker",
      label: "Start Date",
      defaultOpen: true,
      section: "right",
      displayLabel: true,
      accordian: 'Created Date Range'
    },
    {
      colKey: "status",
      type: "checkbox",
      options: [
        "Appealed",
        "Paid",
        "Payment Failed",
        "Pending",
        "Ready For Payment",
        "Rejected",
        "Sent For Payment",
        "Void",
        "Workflow",
      ],
      defaultOpen: true,
      accordian: 'Status'
    }
  ]

  const FILTER_CONFIGS = [
    {
      colKey: "matterNumber",
      type: "matterDropdownAutocomplete",
      label: "Matter Number",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Matter',
      id : "filter-matterNumber-dropdown",
      isRestricted: false
    },
    {
      colKey: "matterName",
      type: "matterDropdownAutocomplete",
      label: "Matter Name",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Matter',
      id : "filter-matterName-dropdown",
      isRestricted: false
    },
    {
      colKey: "organizationInvoiceNumber",
      type: "textField",
      label: "Find an Invoice No.",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Invoice Number',
      fullWidth: "100"
    },
    {
      colKey: "totalNetAmount",
      type: "textField",
      label: "Total",
      defaultOpen: true,
      displayLabel: true,
      accordian: 'Net Amount',
      fullWidth: "100"
    },
    {
      colKey: "createdTs",
      type: "rangePicker",
      label: "Start Date",
      defaultOpen: true,
      section: "right",
      displayLabel: true,
      accordian: 'Created Date Range'
    },
    {
      colKey: "status",
      type: "checkbox",
      options: [
        "Appealed",
        "Paid",
        "Payment Failed",
        "Pending",
        "Rejected",
        "Ready For Payment",
        "Sent For Payment",
        "Void",
        "Workflow",
      ],
      defaultOpen: true,
      accordian: 'Status'
    }
  ]

  const [filterConfigs, ] = useState(FILTER_CONFIGS);
  const [matterFilterConfigs, ] = useState(MATTER_FILTER_CONFIGS);

  /**
 * Function to call Budget Invoice Search Service
 * @param {string} query
 */
  const getInvoiceData = (paginationQuery) => {
    setLoading(true);
    const sortQueryBudget = {...sortQuery};
		if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryBudget.sortBy = [CS_SCORE_SORT_OBJ, ...sortQueryBudget?.sortBy];
    }
    const updatedfilterQuery = getUpdatedFilterQuery({...filterQuery});
    const query = formCompositeSearchQuery([CS_SCOPES.INVOICE], updatedfilterQuery, searchQuery, sortQueryBudget, paginationQuery, {}, defaultQuery);
    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}`,
      query
    )
    .then((response) => {
      dispatch(setInvoiceData({
        invoices:response?.data?.invoice?.data,
        count:response?.data?.invoice?.count
      }));
        setLoading(false);
    })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };
  /**
* Function to validate search query and set query or show no results UI
* @param {string} searchText
*/
  const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
   * Function to set filter query on filter action
   * @param {string} query
   */
  const handleServerSideFilter = (query) => {
    setFilterQuery(query);
  };

  /**
   * Function to call api on sort action
   * @param {number} rowsPerPage
   * @param {number} page
   */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name,direction);
    setSortQuery(sortQuery);
  };

  const invoiceListTableCustomOptions = {
    downloadOptions: {
      filename: "invoice",
    },
    responsive: "standard",
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_INVOICE,
    filterConfigs: fromMatter ? matterFilterConfigs : filterConfigs,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_INVOICE,
    isInvoice: true,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSideSort: handleServerSideSort,
    totalCount: invoiceData?.count
  };

  const handleExportBtnClk = () => {
    setExportSelection(defaultExportOption)
    setModalOpen(true);
    setExportLoading(true);
    setCurrentPageExport(true);
  }

  /**
   * function will use when row is select/deselect
   */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const bData = invoiceData?.invoices;
    const result = allSelected?.map(item => { return bData && bData[item?.index] });
    setSelectRowsData(selectRowsData);
    setRowsData(result);
  }
  /**
 * Cancel Export Modal
 */
  const onCancelBtnClk = () => {
    setModalOpen(false)
    setExportLoading(false)
  }

  /**
     * After click on create button download Actions in Export Modal
     */
  const onClickCreate = () => {
    setCreateExportLoading(true);
    if ( !ENABLE_SERVER_SIDE_FEATURES_INVOICE ) {
      exportClientData(invoiceData?.invoices, exportSelection, rowsData);
      setModalOpen(false);
      setExportLoading(false);
      setCreateExportLoading(false);
    } else {
      let exportQuery = formCompositeSearchQuery([CS_SCOPES.BUDGET], filterQuery, searchQuery, sortQuery, {}, {getAll: true}, {}, defaultQuery);
      if (exportSelection === 'currentSelection') {
        const result = rowsData?.map(a => a?.id);
        const selectionQuery =
        {
          "filters": [formCsFilterObject("id","in",result)],
          "operation": "AND",
          "properties": null
        }
        exportQuery = formCompositeSearchQuery([CS_SCOPES.BUDGET], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
      }
      postService(
        COMPOSITE_SEARCH_SERVICE,
        `/composite-search/v1?returnCsv=true&organizationId=${orgIdentifier}`,
        exportQuery
      )
        .then((response) => {
          const data = response?.data;
          const contentType = response?.headers["content-type"];
          getExportedData(data, contentType);
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
        })
        .catch((error) => {
          setModalOpen(false);
          setExportLoading(false);
          setCreateExportLoading(false);
          addSnack({
            message: getErrorMessage(error),
          });
        });
    }
  }



  const handleRowClick = (rowData, rowMeta) => {
    const invoice = invoiceData?.invoices[rowMeta?.rowIndex];
    history.push(`/invoice/${invoice?.id}`, { invoiceData: invoiceData?.invoices[rowMeta?.rowIndex] })
  }

  /**
 * To load initial data and handle search/filter/sort query change on Invoice datatable
 */
  useEffect(() => {
    getInvoiceData()
  }, [searchQuery, filterQuery, sortQuery]);

  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  return (
    <>
      <div data-testid={"budgetInvoiceContent"} className={classes.dataTableWrap}>
        <MuiDataTable
          data={invoiceData?.invoices}
          columns={invoiceColumns}
          setTableColumns={setInvoiceColumns}
          customOptions={invoiceListTableCustomOptions}
          selectableRowsType={"multiple"}
          onExportClick={handleExportBtnClk}
          groupFilter={true}
          hideAddNewButton={true}
          disablePagination={true}
          onRowClick={handleRowClick}
          selectedRows={selectRowsData}
          loading={loading}
          onRowSelection={handleRowSelectionChange}
          resetSelectedRows={() => resetSelectedRows()}
          exportLoading={exportLoading}
        />
      </div>
      <ExportModal
        title={INVOICE}
        mode={modalOpen}
        onCancel={onCancelBtnClk}
        onCreate={onClickCreate}
        rowsData={rowsData}
        showExportAllOption={showExportAllOption}
        exportSelection={exportSelection}
        setExportSelection={setExportSelection}
        currentPageExport={currentPageExport}
        setCurrentPageExport={setCurrentPageExport}
        exportLoading={createExportLoading}
      />
    </>
  )
}

export default BudgetInvoiceList
