import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import {
  Divider,
  Grid,
  GridColumn,
  TextArea,
  Skeleton,
  Switch,
  StyledText,
} from "@walmart-web/livingdesign-components";
import moment from "moment";
import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  ORGANIZATION,
  PHONE_NUMBER,
  ROLE,
  STATE_BAR_LICENCE,
  SUBJECT_MATTER_EXPERTISE,
  JUSTIFICATION_FOR_TIMEKEEPER,
  JD_YEAR,
  WALMART_APPOVING_ATTORNEY,
  TKID,
  CLASSIFICATION,
  BILLING_CONTACT,
  CREATED_DATE,
  END_DATE,
  START_DATE,
  ROLE_VALUES,
  REQUESTER_EMAIL,
  CONTACT_INFO,
  DETAILS,
  APPROVAL_DATA,
  ADDRESS_TYPE,
  AddressTypes,
  SELECT_TYPE,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CITY,
  STATE,
  COUNTRY,
  START_END_DATE_VALIDATION_MESSAGE,
  SELECT_SUBJECT_MATTER_EXPERTISE,
  SELECT_STATE,
  SELECT_COUNTRY,
  SELECT_STATE_BAR_LICENSE,
  SELECT_CLASSIFICATION,
  MIN_CALENDAR_DAYS,
  JUSTIFICATION_FOR_ADMIN,
  JUSTIFICATION_FOR_ADMIN_TIMEKEEPER,
  CS_IS_OC_INTERNAL_FILTER,
  BACKDATING_PROHIBITED,
} from "../../../constants/constants";
import { INVALID_DATE_RANGE_MESSAGE } from "../../../constants/messages";
import { SPACING } from "../../../constants/styleGuide";
import { setInputValue } from "../../../store/actions/timeKeeperAdminActions";
import Label from "../../../components/TextField/Label/Label";
import { useLocation } from "react-router-dom";
import { timekeeperValidationRules } from "../../../Validation/validationRules";
import MuiValidationMessage from "../../../Validation/MuiValidationMessage/MuiValidationMessage";
import { BACKEND_DATE_FORMAT, DATE_DD_MMM_YYYY, MOMENT_DATE_FORMAT } from "../../../constants/dateFormats";
import { SUBJECT_MATTER_EXPERTISE_MSG } from "../../../Validation/validationMessages";
import { formatPhoneNumber } from "../../../helpers/commonUtils";
import MasterDataAutocomplete from "../../../components/Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import MasterDataMultiAutocomplete from "../../../components/Autocomplete/MasterDataMultiAutocomplete/MasterDataMultiAutocomplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { renderTernary } from "../../../helpers/utils";
import { FeatureValueToggle } from "../../../components/FeatureFlags/FeatureFlags";
import { FEATURES } from "../../../constants/features";
import {TextField, Select, MenuItem} from "@mui/material";
import PeopleAutocompleteWithOptions from "../../../components/Autocomplete/PeopleAutocompleteWithOptions/PeopleAutocompleteWithOptions";

const CHARACTER_LIMIT = 100;

const useStyles = createUseStyles({
  leftContent: {
    paddingRight: SPACING.s72,
  },
  rightContent: {
    paddingTop: SPACING.s32,
    paddingLeft: SPACING.s24,
    paddingRight: SPACING.s24,
  },
  contentSpacing8: {
    paddingTop: SPACING.s8,
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  contentSpacing24: {
    paddingTop: SPACING.s24,
  },
  contentSpacing48: {
    paddingTop: SPACING.s48,
  },
  tag: {
    padding: 4,
    display: "inline-block",
  },
  info: {
    marginLeft: SPACING.s10,
  },
  unAssigned: {
    fontStyle: "italic",
  },
  approvalDataRightSection: {
    marginTop: "32px",
  },
  grid: {
    justifyContent: "space-between",
  },
  gridColumn: {
    marginTop: "70px",
  },
  divider: {
    marginTop: "80px",
  },
  divider1: {
    marginTop: "118px",
  },
  divider2: {
    marginTop: "43px",
  },
  createdDate: {
    marginTop: "25px",
  },
  companyNameStyle:{
    display:"flex",marginTop:"30px"
  },
  errorMsg:{
    marginLeft:"12px",
  },
  dutyText:{
    fontSize: '20px',
    marginRight: '10px',
    display: 'inline-block',
    position:'relative',
    bottom: '6px'
  },
  dutySwitch: {
    display: 'inline-block'
  },
  errorWrap:{
    paddingLeft: '14px'
  },
  divStartDate:{
    display: 'flex',
  },
  pStartDate:{
   fontWeight: "bold",
   fontSize: "14px",
   whiteSpace: "pre",
  },
  styledTextStartDate:{
    fontSize: "11px"
  }
});

const LoadField = ({ loader, skeletonHeight, skeletonVariant, skeletonLines = 1, node }) => {
  if (loader) {
    return <Skeleton height={skeletonHeight} variant={skeletonVariant} lines={skeletonLines} />;
  } else {
    return node;
  }
};


const CreateTimekeeperAdmin = (props) => {
  const {register, errors, clearErrors, setError} = props
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = false;
  const {
    firstName,
    lastName,
    email,
    stateBarLicence,
    subjectMatterExpertiseArray,
    justificationForTimeKeeper,
    walmartApprovingAttorneyObject,
    tkid,
    classification,
    billingContact,
    phoneNo,
    jdYear,
    startDate,
    endDate,
    addressType,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    isTimeKeeperRoleEnabled
  } = useSelector((state) => state?.timeKeeperAdmin?.timeKeeperAdminValues);
  const { companyName } = useSelector((state) => state?.organization?.organizationDetailValues);
  // Initialize with walmartApprovingAttorneyObject, to pre populate autocomplete dropdown on revisiting the screen with go back action
  const [walmartTimekeeperAttorney, setWalmartTimekeeperAttorney] = useState(walmartApprovingAttorneyObject && Object.keys(walmartApprovingAttorneyObject).length > 0 ? walmartApprovingAttorneyObject :'');
  const userDetails = useSelector(state => state?.user?.userDetail?.attributes);
  const {pureAdminRole} = useSelector((state) => state?.user?.featureMap);
  const { pathname } = useLocation();
  const [selectedRole] = useState(
    pathname?.includes("timekeeper") ? "Timekeeper" : "Admin"
  )

  /**
   * Gets called when data changes in input fields.
   * @param {object} e - input event
   */
  const onInputChange = (name, value) => {
    dispatch(setInputValue(name, value));
  };

  /* Loading Skeleton */
  const loadFieldProps = {
    loader: loading,
    skeletonHeight: '56px',
    skeletonVariant: 'rectangle',
    skeletonLines: 1,
    node: <></>
  };

  /** Function will use for handle walmart attorney dropdown */
  const handleWalmartAttorney = (value) =>{
    setWalmartTimekeeperAttorney(value);
  }

  /**
   * This function will use to handle the multiple subject matter expertise
  */
   const multiSelectHandler = (e, value) => {
    const selectedOptions = (typeof value === 'string' ? value.split(',') : value);
    dispatch(setInputValue('subjectMatterExpertise', selectedOptions.toString()));
    dispatch(setInputValue('subjectMatterExpertiseArray', selectedOptions));
  }

  useEffect(()=>{
    dispatch(setInputValue('walmartApprovingAttorneyEmailId', (walmartTimekeeperAttorney?.email?walmartTimekeeperAttorney?.email:'')));
    dispatch(setInputValue('walmartApprovingAttorneyFirstName',walmartTimekeeperAttorney?.firstName));
    dispatch(setInputValue('walmartApprovingAttroney',`${walmartTimekeeperAttorney?.firstName} ${walmartTimekeeperAttorney?.lastName}`));
    dispatch(setInputValue('walmartApprovingAttorneyFullName',`${walmartTimekeeperAttorney?.firstName} ${walmartTimekeeperAttorney?.lastName}`));
    dispatch(setInputValue('walmartApprovingAttorneyLastName',walmartTimekeeperAttorney?.lastName));
    dispatch(setInputValue('walmartApprovingAttorneyUserId',walmartTimekeeperAttorney?.userId));
    // set the object in store, as input to pre populate autocomplete dropdown
    if(walmartTimekeeperAttorney && Object.keys(walmartTimekeeperAttorney)?.length > 0) {
      dispatch(setInputValue('walmartApprovingAttorneyObject', walmartTimekeeperAttorney))
    }
  },[walmartTimekeeperAttorney])

  useEffect(() => {
    // Compare only date, without timestamp
    if (moment(endDate).format(DATE_DD_MMM_YYYY) < moment(startDate).format(DATE_DD_MMM_YYYY)) {
      setError('endDate', { type: 'custom', message: START_END_DATE_VALIDATION_MESSAGE });
    } else {
      clearErrors('endDate')
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (isTimeKeeperRoleEnabled && subjectMatterExpertiseArray?.length === 0) {
      setError('subjectMatterExpertise', { type: 'custom', message: SUBJECT_MATTER_EXPERTISE_MSG });
    }else {
      clearErrors('subjectMatterExpertise')
    }
  }, [subjectMatterExpertiseArray])

  useEffect(() => {
    clearErrors()
  }, [isTimeKeeperRoleEnabled])

  /**
   * Function to handle date picker error
   * @param {*} err
   * @param {*} dateType
   */
  const handleDateError = (err, dateType) => {
    if(err) {
      setError(dateType, { type: 'custom', message: INVALID_DATE_RANGE_MESSAGE })
    } else {
      clearErrors(dateType)
    }
  };

  const TimeKeeperForm = <>
    <GridColumn sm={12}>
      <div className={classes.divider2}>
        <Divider />
      </div>
    </GridColumn>
    <GridColumn sm={9}>
      <div className={classes.leftContent}>
        <div className={classes.contentSpacing16}>
          <h2>{APPROVAL_DATA}</h2>
        </div>
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={STATE_BAR_LICENCE} mandatoryField={true} />
              <MasterDataAutocomplete
              value={stateBarLicence}
              setValue={(value)=>{onInputChange("stateBarLicence", value?.value)}}
              dataType={"stateBarLicense"}
              testid="create-timekeeper-starBarLicense"
              id="create-timekeeper-starBarLicense"
              required = {true}
              register={register}
              errors={errors}
              name={"stateBarLicence"}
              validationRules = {timekeeperValidationRules}
              placeholderValue={SELECT_STATE_BAR_LICENSE}
              />
            </React.Fragment>
          })}
        </div>
        <div className={classes.contentSpacing16}>
        {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <div className={classes.labelStyle}><Label text={SUBJECT_MATTER_EXPERTISE} mandatoryField={true} /></div>
              <MasterDataMultiAutocomplete
                dataType={"subjectMatterExpertise"}
                value={subjectMatterExpertiseArray}
                placeholderValue={SELECT_SUBJECT_MATTER_EXPERTISE}
                setValue={multiSelectHandler}
                testid="create-timekeeper-subject-matter-expertise"
                id="create-timekeeper-subject-matter-expertise"
              />
              <div className={classes.errorWrap}>{errors?.subjectMatterExpertise && <MuiValidationMessage message={errors?.subjectMatterExpertise.message} />}</div>
            </React.Fragment>
          })}
        </div>
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, skeletonHeight: "128px", node: <React.Fragment>
              <Label text={isTimeKeeperRoleEnabled ? JUSTIFICATION_FOR_ADMIN_TIMEKEEPER : JUSTIFICATION_FOR_TIMEKEEPER} mandatoryField={true}/>
              <TextArea
                error={errors?.justificationForTimeKeeper && <MuiValidationMessage message={errors?.justificationForTimeKeeper?.message} mode="LD" />}
                value={justificationForTimeKeeper}
                maxLength={CHARACTER_LIMIT}
                textAreaProps={{
                  name: "justificationForTimeKeeper",
                }}
                data-testid="create-timekeeper-justificationfortimekeeper"
                id="create-timekeeper-justificationfortimekeeper"
                {...register(
                  'justificationForTimeKeeper',
                  { ...timekeeperValidationRules?.justificationForTimeKeeper, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                )}
              />
            </React.Fragment>
          })}
        </div>
      </div>
    </GridColumn>
    <GridColumn sm={3} className={classes.gridColumn}>
      <div className={classes.approvalDataRightSection}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={JD_YEAR} />
            <TextField
              onChange={(e) => onInputChange(e.target.name, e.target.value)}
              value={jdYear}
              name= "jdYear"
              type="text"
              data-testid="create-timekeeper-jdyear"
              id="create-timekeeper-jdyear"
              fullWidth={true}
            />
          </React.Fragment>
        })}
      </div>
      <div className={classes.contentSpacing16}>
      {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={WALMART_APPOVING_ATTORNEY} mandatoryField={true} />
            <PeopleAutocompleteWithOptions
              value={walmartTimekeeperAttorney}
              required={true}
              setValue={(value) => {
                handleWalmartAttorney(value);
              }}
              placeholderValue={'Select Walmart Approving Attorney'}
              filterCheck={false}
              testid="create-timekeeper-walmart-approving-attroney"
              id="create-timekeeper-walmart-approving-attroney"
              register={register}
              errors={errors}
              name={"walmartTimekeeperAttorney"}
              validationRules={timekeeperValidationRules}
              additionalQuery={CS_IS_OC_INTERNAL_FILTER}
            />
        </React.Fragment>
      })}
      </div>
      <div className={classes.contentSpacing24}><Label text={REQUESTER_EMAIL}/></div>
      <div className={classes.contentSpacing16}>
        {userDetails?.emailId}
      </div>
    </GridColumn>
    <GridColumn sm={12}>
      <div className={classes.divider2}>
        <Divider />
      </div>
    </GridColumn>
    <GridColumn sm={9}>
      <div className={classes.leftContent}>
        <div className={classes.contentSpacing16}>
          <h2>{DETAILS}</h2>
        </div>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={TKID} mandatoryField={true} />
            <TextField
              error={errors?.tkid}
              helperText={errors?.tkid && <MuiValidationMessage message={errors?.tkid?.message}/>}
              value={tkid}
              name= "tkid"
              type="text"
              data-testid="create-timekeeper-tkid"
              id="create-timekeeper-tkid"
              {...register(
                'tkid',
                { ...timekeeperValidationRules?.tkId, onChange: (e) => onInputChange(e.target.name, e.target.value) }
              )}
              fullWidth={true}
            />
          </React.Fragment>
        })}
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={CLASSIFICATION} mandatoryField={true} />
              <MasterDataAutocomplete
              value={classification}
              setValue={(value)=>{onInputChange("classification", value?.value)}}
              dataType={"classification"}
              testid="create-timekeeper-classification"
              id="create-timekeeper-classification"
              placeholderValue={SELECT_CLASSIFICATION}
              required={true}
              register={register}
              errors={errors}
              validationRules = {timekeeperValidationRules}
              name={"classification"}
              />
            </React.Fragment>
          })}
        </div>
        <div className={classes.contentSpacing16}>
          <Switch
            isOn={billingContact}
            label={<span>{BILLING_CONTACT}</span>}
            onClick={() => {
              onInputChange("billingContact", !billingContact);
            }}
            data-testid="create-timekeeper-billingContact"
            id="create-timekeeper-billingContact"
            name="billingContact"
          />
        </div>
      </div>
    </GridColumn>
    <GridColumn sm={3} className={classes.gridColumn}>
      <div className={classes.createdDate}>
        <Label text={CREATED_DATE} />
      </div>
      <div className={classes.contentSpacing16}>{moment().format(MOMENT_DATE_FORMAT)}</div>
      <div className={classes.contentSpacing48}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <div className={classes.divStartDate}>
              <p className={classes.pStartDate}>{`${START_DATE}* `}</p>
              <StyledText
                color="gray"
                size="large"
                className={classes.styledTextStartDate}
              >
                {BACKDATING_PROHIBITED}
              </StyledText>
            </div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                required={true}
                value={renderTernary(startDate, moment(startDate), null)}
                onChange={(e) => {
                  onInputChange(
                    'startDate',
                    e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                  );
                }}
                onError={(err) => {
                  handleDateError(err,'startDate')
                }}
                slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"startDate","data-testid":"create-timekeeper-startdate", "id":"create-timekeeper-startdate"}}}
                // FEATURE - TKBackdating - value toggle
                minDate={FeatureValueToggle(FEATURES.TK_BACKDATING, moment().subtract(MIN_CALENDAR_DAYS, 'days'))}
                maxDate={endDate ? moment(endDate) : null}
              />
            </LocalizationProvider>
            {errors?.startDate && <MuiValidationMessage message={errors?.startDate.message} />}
          </React.Fragment>
        })}
      </div>
      <div className={classes.contentSpacing24}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={END_DATE} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                value={renderTernary(endDate, moment(endDate), null)}
                onChange={(e) => {
                  onInputChange(
                    'endDate',
                    e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                  );
                }}
                onError={(err) => {
                  handleDateError(err,'endDate')
                }}
                slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"endDate","data-testid":"create-timekeeper-enddate","id":"create-timekeeper-enddate"}}}
                minDate={moment(startDate)}
              />
            </LocalizationProvider>
            {errors?.endDate && <MuiValidationMessage message={errors?.endDate.message} />}
          </React.Fragment>
        })}
      </div>
    </GridColumn>
  </>;

  const AdminForm = <>
    <GridColumn sm={12}>
      <div className={classes.divider2}>
        <Divider />
      </div>
    </GridColumn>
    <GridColumn sm={9}>
      <div className={classes.leftContent}>
        <div className={classes.contentSpacing16}>
          <h2>{APPROVAL_DATA}</h2>
        </div>
        <div className={classes.contentSpacing16}>
          {LoadField({
            ...loadFieldProps, skeletonHeight: "128px", node: <React.Fragment>
              <Label text={isTimeKeeperRoleEnabled ? JUSTIFICATION_FOR_ADMIN_TIMEKEEPER : JUSTIFICATION_FOR_ADMIN} mandatoryField={true}/>
              <TextArea
                error={errors?.justificationForTimeKeeper && <MuiValidationMessage message={errors?.justificationForTimeKeeper?.message} mode="LD" />}
                value={justificationForTimeKeeper}
                maxLength={CHARACTER_LIMIT}
                textAreaProps={{
                  name: "justificationForTimeKeeper",
                }}
                data-testid="create-timekeeper-justificationfortimekeeper"
                id="create-timekeeper-justificationfortimekeeper"
                {...register(
                  'justificationForTimeKeeper',
                  { ...timekeeperValidationRules?.justificationForTimeKeeper, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                )}
              />
            </React.Fragment>
          })}
        </div>
      </div>
    </GridColumn>
    <GridColumn sm={3} className={classes.gridColumn}>
    <div className={classes.contentSpacing16}>
      {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={WALMART_APPOVING_ATTORNEY} mandatoryField={true} />
        <PeopleAutocompleteWithOptions
          value={walmartTimekeeperAttorney}
          required={true}
          setValue={(value) => {
            handleWalmartAttorney(value);
          }}
          placeholderValue={'Select Walmart Approving Attorney'}
          filterCheck={false}
          testid="create-timekeeper-walmart-approving-attroney"
          id="create-timekeeper-walmart-approving-attroney"
          register={register}
          errors={errors}
          name={"walmartTimekeeperAttorney"}
          validationRules={timekeeperValidationRules}
          additionalQuery={CS_IS_OC_INTERNAL_FILTER}
        />
        </React.Fragment>
      })}
      </div>
      <div className={classes.contentSpacing24}><Label text={REQUESTER_EMAIL}/></div>
      <div className={classes.contentSpacing16}>
        {userDetails?.emailId}
      </div>
    </GridColumn>
    <GridColumn sm={12}>
      <div className={classes.divider2}>
        <Divider />
      </div>
    </GridColumn>
    <GridColumn sm={9}>
      <div className={classes.leftContent}>
        <div className={classes.contentSpacing16}>
          <h2>{DETAILS}</h2>
        </div>
        <div className={classes.contentSpacing16}>
          <Switch
            isOn={billingContact}
            label={<span>{BILLING_CONTACT}</span>}
            onClick={() => {
              onInputChange("billingContact", !billingContact);
            }}
            data-testid="create-timekeeper-billingContact"
            id="create-timekeeper-billingContact"
            name="billingContact"
          />
        </div>
        <div className={classes.createdDate}>
          <Label text={CREATED_DATE} />
        </div>
        <div className={classes.contentSpacing16}>{moment().format(MOMENT_DATE_FORMAT)}</div>
      </div>
    </GridColumn>
    <GridColumn sm={3} className={classes.gridColumn}>
      <div className={classes.contentSpacing48}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={START_DATE} mandatoryField/>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                required={true}
                value={renderTernary(startDate, moment(startDate), null)}
                onChange={(e) => {
                  onInputChange(
                    'startDate',
                    e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                  );
                }}
                onError={(err) => {
                  handleDateError(err,'startDate')
                }}
                slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"startDate","data-testid":"create-timekeeper-startdate", "id":"create-timekeeper-startdate"}}}
                // FEATURE - TKBackdating - value toggle
                minDate={FeatureValueToggle(FEATURES.TK_BACKDATING, moment().subtract(MIN_CALENDAR_DAYS, 'days'))}
                maxDate={endDate ? moment(endDate) : null}
              />
            </LocalizationProvider>
            {errors?.startDate && <MuiValidationMessage message={errors?.startDate.message} />}
          </React.Fragment>
        })}
      </div>
      <div className={classes.contentSpacing24}>
        {LoadField({
          ...loadFieldProps, node: <React.Fragment>
            <Label text={END_DATE} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{width:"100%"}}
                value={renderTernary(endDate, moment(endDate), null)}
                onChange={(e) => {
                  onInputChange(
                    'endDate',
                    e ? moment(e).format(BACKEND_DATE_FORMAT) : null
                  );
                }}
                onError={(err) => {
                  handleDateError(err,'endDate')
                }}
                slotProps={{inputAdornment:{position:"end"},textField:{InputProps:{style: { color: '#2E2F32' }},name:"endDate","data-testid":"create-timekeeper-enddate","id":"create-timekeeper-enddate"}}}
                minDate={moment(startDate)}
              />
            </LocalizationProvider>
            {errors?.endDate && <MuiValidationMessage message={errors?.endDate.message} />}
          </React.Fragment>
        })}
      </div>
    </GridColumn>
  </>

  return (
    <React.Fragment>
      <Grid>
        <GridColumn sm={9}>
          <div className={classes.leftContent}>
            { selectedRole === 'Admin' && pureAdminRole && <Grid className={classes.grid}>
              <GridColumn lg={12}>
                <span className={classes.dutyText}>This user is also a Timekeeper</span>
                <span className={classes.dutySwitch}>
                  <Switch
                    isOn={isTimeKeeperRoleEnabled}
                    label={<span></span>}
                    onClick={() => {
                      onInputChange("isTimeKeeperRoleEnabled", !isTimeKeeperRoleEnabled);
                    }}
                    data-testid="create-admin-isTimeKeeperRoleEnabled"
                    id="create-admin-isTimeKeeperRoleEnabled"
                    name="isTimeKeeperRoleEnabled"
                  />
                </span>
              </GridColumn>
            </Grid> }
            <h2>{CONTACT_INFO}</h2>
            <Grid className={classes.grid}>
              <GridColumn lg={6}>
                {LoadField({
                  ...loadFieldProps, node: <React.Fragment>
                    <Label text={FIRST_NAME} mandatoryField={true} />
                    <TextField
                      value={firstName}
                      error={errors?.firstName}
                      helperText={errors?.firstName && <MuiValidationMessage message={errors?.firstName?.message}/>}
                      name= "firstName"
                      type="text"
                      data-testid="create-timekeeper-firstname"
                      id="create-timekeeper-firstname"
                      {...register(
												'firstName',
												{ ...timekeeperValidationRules?.firstName, onChange: (e) => onInputChange(e.target.name, e.target.value) }
											)}
                      fullWidth={true}
                    />
                  </React.Fragment>
                })}
              </GridColumn>
              <GridColumn lg={5}>
                {LoadField({
                  ...loadFieldProps, node: <React.Fragment>
                    <Label text={LAST_NAME} mandatoryField={true} />
                    <TextField
                      error={errors?.lastName}
                      helperText={errors?.lastName && <MuiValidationMessage message={errors?.lastName?.message}/>}
                      value={lastName}
                      name= "lastName"
                      type="text"
                      data-testid="create-timekeeper-lastname"
                      id="create-timekeeper-lastname"
                      {...register(
												'lastName',
												{ ...timekeeperValidationRules?.lastName, onChange: (e) => onInputChange(e.target.name, e.target.value) }
											)}
                      fullWidth={true}
                    />
                  </React.Fragment>
                })}
              </GridColumn>
            </Grid>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <React.Fragment>
                  <Label text={EMAIL} mandatoryField={true} />
                  <TextField
                    error={errors?.email}
                    helperText={errors?.email && <MuiValidationMessage message={errors?.email?.message}/>}
                    value={email}
                    name= "email"
                    type="text"
                    data-testid="create-timekeeper-email"
                    id="create-timekeeper-email"
                    {...register(
                      'email',
                      { ...timekeeperValidationRules?.email, onChange: (e) => onInputChange(e.target.name, e.target.value) }
                    )}
                    fullWidth={true}
                  />
                </React.Fragment>
              })}
            </div>
            <div className={classes.contentSpacing16}>
              {LoadField({
                ...loadFieldProps, node: <>
                <Grid className={classes.grid}>
                <GridColumn sm={6}>
                  <Label text={ROLE} mandatoryField={true}/>
                  <Select
                  value={selectedRole}
                  disabled
                  selectProps={{
                  }}
                  name= "role"
                  data-testid="create-timekeeper-role"
                  id="create-timekeeper-role"
                  fullWidth={true}
                >
                  <MenuItem value="">{"Select Role"}</MenuItem>
                  {ROLE_VALUES?.map((value, index) => {
                    return (
                      <MenuItem
                        value={value}
                        key={`${value}`}
                        data-testid="create-timekeeper-role-option"
                        id="create-timekeeper-role-option"
                      >
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
                </GridColumn>
                <GridColumn sm={5}>
                  <Label text={ORGANIZATION} mandatoryField={true}/>
                  <text className={classes.companyNameStyle}>{companyName}</text>
                </GridColumn>
                </Grid>
                </>
              })}
            </div>
            <div className={classes.contentSpacing16}>
            {LoadField({
                ...loadFieldProps, node: <React.Fragment>
                <Label text={PHONE_NUMBER} />
                <TextField
                  error={errors?.phoneNo}
                  helperText={errors?.phoneNo && <MuiValidationMessage message={errors?.phoneNo?.message}/>}
                  value={formatPhoneNumber(phoneNo)}
                  name= "phoneNo"
                  type="tel"
                  data-testid="create-timekeeper-phoneno"
                  id="create-timekeeper-phoneno"
                  {...register(
                    'phoneNo',
                    { ...timekeeperValidationRules?.phoneNo, onChange: (e) => onInputChange(e.target.name, formatPhoneNumber(e.target.value)) }
                  )}
                  fullWidth={true}
                />
              </React.Fragment>
            })}
          </div>
          </div>
        </GridColumn>
        <GridColumn sm={3} className={classes.gridColumn}>
          {LoadField({
            ...loadFieldProps, node: <React.Fragment>
              <Label text={ADDRESS_TYPE} />
              <Select
                value={addressType}
                name= "addressType"
                data-testid="create-timekeeper-addressType"
                id="create-timekeeper-addressType"
                fullWidth={true}
                onChange={(e) => onInputChange(e.target.name, e.target.value)}
              >
                <MenuItem value="">{SELECT_TYPE}</MenuItem>
                {AddressTypes?.map((value, index) => {
                  return (
                    <MenuItem
                      value={value}
                      key={`${value}`}
                      id={`create-timekeeper-addressType-option-${index}`}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </React.Fragment>
          })}
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <React.Fragment>
                <Label text={ADDRESS_LINE_1}/>
                <TextField
                value={addressLine1}
                name= "addressLine1"
                type="text"
                data-testid="create-timekeeper-streetName"
                id="create-timekeeper-streetName"
                  onChange={(e) => onInputChange(e.target.name, e.target.value)}
                fullWidth={true}
              />
              </React.Fragment>
            })}
          </div>
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <React.Fragment>
                <Label text={ADDRESS_LINE_2} />
                <TextField
                  onChange={(e) => {
                    onInputChange(e.target.name, e.target.value);
                  }}
                  value={addressLine2}
                  name= "addressLine2"
                  type="text"
                  data-testid="create-timekeeper-addressLine2"
                  id="create-timekeeper-addressLine2"
                  fullWidth={true}
              />
              </React.Fragment>
            })}
          </div>
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <Grid className={classes.grid}>
              <GridColumn sm={6}>
                <Label text={CITY} />
                <TextField
                  onChange={(e) => {
                    onInputChange(e.target.name, e.target.value);
                  }}
                  value={city}
                  name= "city"
                  type="text"
                  data-testid="create-timekeeper-city"
                  id="create-timekeeper-city"
                  fullWidth={true}
              />
              </GridColumn>
              <GridColumn sm={5}>
                <Label text={STATE} />
                <MasterDataAutocomplete
                  value={state}
                  setValue={(value)=>{onInputChange("state", value?.value)}}
                  dataType={"state"}
                  name={"state"}
                  testid="create-timekeeper-state"
                  id="create-timekeeper-state"
                  placeholderValue={SELECT_STATE}
                  />
                </GridColumn>
              </Grid>
            })}
          </div>
          <div className={classes.contentSpacing16}>
            {LoadField({
              ...loadFieldProps, node: <React.Fragment>
                <Label text={COUNTRY} />
                <MasterDataAutocomplete
                  value={country}
                  setValue={(value)=>{onInputChange("country", value?.value)}}
                  dataType={"country"}
                  name={"country"}
                  testid="create-timekeeper-country"
                  id="create-timekeeper-country"
                  placeholderValue={SELECT_COUNTRY}
                  />
              </React.Fragment>
            })}
          </div>
        </GridColumn>
        {(isTimeKeeperRoleEnabled ||  selectedRole !== 'Admin')? TimeKeeperForm: AdminForm}
      </Grid>
    </React.Fragment>
  );
};

export default CreateTimekeeperAdmin;
